import React from "react"; //importamos react
import styled from "styled-components"; //permite crear estilos para este archivo

import HighPerformanceEcommerceContent from "./sections/high-converting-ecommerce/Content";
import DigitalMarketingContent from "./sections/digital-campaign/Content";
import MarketPlacePerformanceContent from "./sections/marketplace-performance/Content";
import B2BMarketingContent from "./sections/b2b-marketing/Content";
import SEOWebContent from "./sections/seo-web/Content";
import ServicesSection2Slider from "./sections/high-converting-ecommerce/ServicesSection2Slider";
import ServicesSection3Images from "./sections/digital-campaign/ServicesSection3Images";
import ServicesSection4Images from "./sections/marketplace-performance/ServicesSection4Images";
import ServicesSection5Images from "./sections/b2b-marketing/ServicesSection5Images";
import ServicesSection6Images from "./sections/seo-web/ServicesSection6Images";
import VideoFrames from "./sections/performance-consulting/VideoFrames";
import PerformanceConsultingContent from "./sections/performance-consulting/Content";
import FadeIn from "../../../components/animations/FadeIn";
import { useTranslation } from "react-i18next";

export default function ServicesResponsive({ id = null }) {
  const { t } = useTranslation(["common"]);
  return (
    <StyledSection id={id} className="bg-primary md:hidden">
      <div className="container pb-10 pt-16">
        <FadeIn direction="top">
          <h2 className="text-dark-head h2 lg:pl-32 text-2xl sm:text-xl md:text-4xl text-left leading-none whitespace-pre-line mb-3">{t("services.title")}</h2>
          <p data-aos="fade-up" data-aos-offset="120" className="text-md text-left md:text-lg lg:pl-32 text-dark-head font-light">
            {t("services.subtitle")}
          </p>
        </FadeIn>
      </div>
      {/**
       * Panel 1 Start
       */}
      <div id="responsive-service-section-1">
        <div id="responsive-services-section-1-images-container">
          <VideoFrames id="responsive-services-section-1-animation" count={77} />
        </div>
        <PerformanceConsultingContent id="responsive-section-1-content" />
      </div>
      {/**
       * Panel 2 Content
       */}
      <div id="responsive-service-section-2">
        <div id="responsive-services-section-2-images-container">
          <ServicesSection2Slider />
        </div>
        <HighPerformanceEcommerceContent id="responsive-section-2-content" />
      </div>
      {/**
       * Panel 3 Content
       */}
      <div id="responsive-service-section-3">
        <div id="responsive-services-section-3-images-container">
          <ServicesSection3Images />
        </div>
        <DigitalMarketingContent id="responsive-section-3-content" />
      </div>
      {/**
       * Panel 4 Content
       */}
      <div id="responsive-service-section-4">
        <div id="responsive-services-section-4-images-container">
          <ServicesSection4Images />
        </div>
        <MarketPlacePerformanceContent id="responsive-section-4-content" />
      </div>
      {/**
       * Panel 5 Content
       */}
      <div id="responsive-service-section-5">
        <div id="responsive-services-section-5-images-container">
          <ServicesSection5Images />
        </div>
        <B2BMarketingContent id="responsive-section-5-content" />
      </div>
      {/**
       * Panel 6 Content
       */}
      <div id="responsive-service-section-6">
        <div id="responsive-services-section-6-images-container">
          <ServicesSection6Images />
        </div>
        <SEOWebContent id="responsive-section-6-content" />
      </div>
    </StyledSection>
  );
}

const StyledSection = styled.div`
  //Section container
  & {
    --section-height: 670px;
    --section-background: rgba(191, 191, 191, 1);
    --section-background-transparent: rgba(191, 191, 191, 0);
    @media screen and (max-width: 1023px) {
      --panel-images-wrapper-left: 0;
    }
    @media screen and (min-width: 1024px) {
      --panel-images-wrapper-left: calc(100% / 12 * 5);
    }
    @media screen and (min-width: 1600px) {
      --panel-images-wrapper-left: calc(100% / 12 * 6);
    }
    //background: var(--section-background);
    @media screen and (max-width: 1023px) {
      //height: 100vh;
    }
    @media screen and (min-width: 1023px) {
      min-height: var(--section-height);
    }
  }
  #services-content-container {
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    z-index: 51;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      top: 0;
    }
    @media screen and (max-width: 1023px) {
      min-width: 100vw;
      left: 50%;
      transform: translatex(-50%);
      bottom: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      top: 40%;
      background: linear-gradient(180deg, var(--section-background-transparent) 0%, var(--section-background) 16%);
    }
    //overflow-hidden container relative md:-mt-16 lg:mt-0 mx-0 lg:mx-auto min-h-screen   lg:min-h-670 pointer-events-none md:flex md:flex-row justify-center lg:justify-start items-stretch z-50
    @media screen and (min-width: 1024px) {
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: stretch;
      justify-content: start;
      left: 50%;
      transform: translateX(-50%);
    }
    #services-content-wrapper {
      //service-content-container-inner md:mx-0 w-full min-h-screen md:min-h-unset md:h-auto md:w-3/4 lg:w-5/12 flex justify-end items-stretch relative z-50
      position: relative;
      height: 100%;
      display: flex;
      @media screen and (min-width: 1024px) {
        width: calc(100% / 12 * 5);
      }
    }
    .responsive-panel-content-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      bottom: 0;
      @media screen and (max-width: 767px) {
        padding: 0 1.5rem;
        background: #171717;
        border-radius: 1.5rem 1.5rem 0 0;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding: 8rem 0 0;
        & .panel-content-title * {
          display: inline !important;
          white-space: nowrap;
        }
      }
      @media screen and (min-width: 1024px) {
        padding: 2.5rem 0;
      }
    }
    .panel-content-wrapper:first-child {
      display: flex;
    }
  }
  #isotipo-container {
    //Isotipo that bleeds between sections
    //hidden lg:block isotipo-container xl:w-screen lg:right-0 top-0 bottom-0 lg:left-5/12 3xl:left-6/12 lg:absolute
    position: absolute;
    left: var(--panel-images-wrapper-left);
    & img {
      object-fit: cover;
      max-width: none;
    }
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  #panel-images-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      height: 60%;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      height: 50%;
    }
    @media screen and (min-width: 1024px) {
      height: 100%;
    }
    //md:w-full top-0 left-0 right-0 min-h-60vh lg:min-h-unset lg:inset-0 xl:overflow-hidden
    #panel-images-wrapper {
      position: absolute;
      top: 0;
      left: var(--panel-images-wrapper-left);
      width: 100%;
      height: 100%;
      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        overflow: hidden;
      }
      //xl:w-screen inset-0 lg:left-5/12 3xl:left-6/12 lg:absolute
    }
    #panel-backgrounds {
      //panel background
      //panel-images min-h-60vh lg:min-h-full w-full absolute
      position: absolute;
      height: 100%;
      width: 100%;
      & img,
      & video {
        object-fit: cover;
        max-width: none;
        width: 100%;
        height: 100%;
        object-position: left;
        @media screen and (min-width: 1440px) {
          width: auto;
        }
      }
      & > *:not(:first-child) {
        opacity: 0;
        visibility: hidden;
      }
    }

    #panel-mask {
      //Overlay mask over panel background
      //object:cover lg:object-contained h-full max-w-none z-50
      position: absolute;
      inset: 0;
      @media screen and (max-width: 767px) {
        display: none;
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
          max-width: none;
          width: auto;
        }
      }
      @media screen and (min-width: 1024px) {
        img {
          object-fit: contain;
          z-index: 10;
          max-width: none;
          width: auto;
          height: 100%;
        }
      }
    }

    .panel-images-wrapper-inner {
      //top-0 right-0 bottom-0 left-0  lg:h-min-unset lg:bottom-0  lg:left-5/12 3xl:left-6/12 absolute
      inset: 0;
      position: absolute;
      left: var(--panel-images-wrapper-left);
    }
    #panel-2-over-the-mask {
      .panel-2-desktop {
        position: relative;
      }
      //revisar
      .panel-2-app-wrapper {
        pointer-events: none;
        position: absolute;
        @media screen and (max-width: 767px) {
          width: 73%;
          left: 18%;
          top: 30%;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          width: 70%;
          left: 21%;
          top: 14%;
        }
      }
      //Terminar Revisar
      @media screen and (min-width: 768px) and (max-width: 1279px) {
        width: 60%;
        right: unset;
        left: 23%;
        top: 25%;
      }

      #panel-2-desktop-frame {
        box-shadow: 0px 2.8px 7.3px rgba(0, 0, 0, 0.048), 0px 6.7px 17.4px rgba(0, 0, 0, 0.069), 0px 12.5px 32.8px rgba(0, 0, 0, 0.085), 0px 22.3px 58.5px rgba(0, 0, 0, 0.101), 0px 41.8px 109.5px rgba(0, 0, 0, 0.122), 0px 100px 262px rgba(0, 0, 0, 0.17);
      }
      #panel-2-paginator {
        position: absolute;
        @media screen and (max-width: 767px) {
          left: 10%;
          width: 67%;
          top: -26%;
          height: 0;
          overflow: visible;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          left: 105%;
          width: 67%;
          top: 20%;
          height: 0;
          overflow: visible;
        }
      }
      .paginator-slide-name {
        position: absolute;
        white-space: nowrap;
        @media screen and (max-width: 767px) {
          position: absolute;
          left: calc(100% + 2em);
          top: 50%;
          transform: translateY(-50%);
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          top: calc(100% + 1rem);
          transform: translatex(-50%);
          left: 50%;
        }
      }
      .panel-2-paginator-content {
        width: 40%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        .panel-2-paginator-controls {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          .panel-paginator-display {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(191, 221, 228, 1);
            border-radius: 500rem;
          }
          .panel-paginator-arrow {
          }
          .panel-paginator-arrow.right {
            transform: rotate(180deg);
          }
        }
      }
    }
    #panel-3-over-the-mask {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        left: 46%;
        top: 0;
        bottom: 0;
        right: 8%;
        padding-left: 0;
        & .panel-3-dashboard-wrapper {
          transform: translateX(-20%);
        }
      }

      & .panel-3-woman-wrapper {
        position: absolute;
        bottom: 0;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          right: -8%;
          width: 67%;
        }
      }
      & .panel-3-woman {
        position: absolute;
        bottom: 0;
        //padding-top: 200%;
      }
      & .panel-3-woman-image {
        object-fit: contain;
        object-position: bottom;
        height: 100%;
      }

      & .panel-3-circles-wrapper {
        @media screen and (max-width: 767px) {
          bottom: 89%;
          left: 29%;
          width: 54%;
          height: 0;
          transform: translateX(-50%);
        }
        @media screen and (min-width: 768px) {
          bottom: 89%;
          left: 29%;
          width: 54%;
          height: 0;
          transform: translateX(-50%);
        }
      }
      .panel-3-dashboard {
        border-radius: 0.25rem;
        box-shadow: 0px 2.8px 7.3px rgba(0, 0, 0, 0.048), 0px 6.7px 17.4px rgba(0, 0, 0, 0.069), 0px 12.5px 32.8px rgba(0, 0, 0, 0.085), 0px 22.3px 58.5px rgba(0, 0, 0, 0.101), 0px 41.8px 109.5px rgba(0, 0, 0, 0.122), 0px 100px 262px rgba(0, 0, 0, 0.17);
        .panel-3-google-pointer-wrapper,
        .panel-3-facebook-pointer-wrapper {
          //box-shadow: 0px 2.8px 7.3px rgba(0, 0, 0, 0.048), 0px 6.7px 17.4px rgba(0, 0, 0, 0.069), 0px 12.5px 32.8px rgba(0, 0, 0, 0.085), 0px 22.3px 58.5px rgba(0, 0, 0, 0.101), 0px 41.8px 109.5px rgba(0, 0, 0, 0.122), 0px 100px 262px rgba(0, 0, 0, 0.17);
        }
      }
    }
    #panel-4-background {
    }
    #panel-4-over-the-mask {
    }
    #panel-5-background {
    }
    #panel-5-over-the-mask {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 50%;
        left: 45%;
        transform: translatex(-50%);
        .panel-5-persona {
          display: none;
        }
      }
    }
    #panel-6-background {
    }
    #panel-6-over-the-mask {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 50%;
        left: 45%;
        transform: translatex(-50%);
        .panel-5-persona {
          display: none;
        }
      }
    }
  }

  .service-background {
    z-index: 50;
  }
  .pagination {
    height: 7rem;
    overflow: hidden;
    top: 3rem;

    & .pagination-item-number {
      color: #757575;
      &.active {
        color: #2e2e2e;
      }
    }
    & .pagination-spacer {
      margin: 0.5rem 0;
      height: 3rem;
      width: 1px;
      background: #757575;
    }
    .pagination-item:last-child .pagination-spacer {
      background: linear-gradient(180deg, rgba(117, 117, 117, 1) 0%, rgba(117, 117, 117, 0) 100%);
    }
  }
  .isotipo-container {
    z-index: 5;
  }
  .isotipo-wrapper {
    height: 196.5%;
    transform: translate(0.25%, -12.3%);
  }
  .background-before-section,
  .background-after-section {
    height: 50vh;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .background-before-section {
    bottom: 100%;
  }
  .background-after-section {
    top: 100%;
  }
  &:before {
    content: "";
  }
  &:after {
    content: "";
  }
  .panel-3-dashboard-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;
  }
  .panel-3-dashboard {
    position: absolute;
    @media screen and (max-width: 1023px) {
      width: 80%;
      transform: translatex(-60%);
    }
  }
  .panel-3-google-logo,
  .panel-3-facebook-logo {
    position: absolute;
  }
  .panel-3-google-logo {
    width: 64%;
    top: -16%;
    right: -19px;
  }
  .panel-3-facebook-logo {
    width: 58%;
    right: -9%;
    top: -10%;
  }
  .panel-3-google-pointer-wrapper,
  .panel-3-facebook-pointer-wrapper {
    position: absolute;
    left: 100%;
    width: 30%;
  }
  .panel-3-pildora-turismo {
    position: absolute;
    top: 47%;
    width: 42%;
    right: -18%;
  }
  .panel-3-pildora-25-35 {
    position: absolute;
    top: 64%;
    width: 34%;
    right: -39%;
  }
  .panel-3-pildora-remarketing {
    position: absolute;
    top: 38%;
    width: 41%;
    right: -17%;
  }
  .panel-3-google-pointer-wrapper {
    bottom: 52%;
  }
  .panel-3-facebook-pointer-wrapper {
    top: 52%;
  }
  .panel-3-google-pointer {
    top: -12%;
  }

  .panel-4-marketplace,
  .panel-4-tira1 {
    width: calc(100% / 4);
    padding-right: 1em;
  }
  .panel-4-tira-con-carrito {
    @media screen and (max-width: 1023px) {
      width: 75%;
    }
  }
  .panel-4-flecha {
    width: 1rem;
    height: auto;
    padding-right: 1rem;
  }
  .panel-4-flecha {
    width: 2rem;
  }
  .panel-4-images-wrapper {
    position: absolute;
    top: 50%;
    left: calc(100% / 12);
    transform: translatey(-50%);
    overflow: visible;
    height: 100%;
  }
  .panel-5-images-wrapper {
    position: absolute;
    @media screen and (max-width: 1023px) {
      inset: 0;
    }
  }
  .panel-5-database {
    width: calc(100% / 3.5);
    padding-right: 1em;
  }
  .panel-5-profile {
    width: calc(100% / 4);
    padding-right: 1em;
  }
  .panel-5-landing {
    @media screen and (max-width: 1023px) {
      left: 35%;
      transform: translatex(-50%);
      bottom: 50%;
      width: 20%;
    }
  }
  .panel-5-flecha {
    width: 2rem;
  }

  .animated-green-pointer-dot {
    position: absolute;
    width: 31%;
    left: -17px;
    top: 73%;
    transform: translate(-50%, -50%);
  }
  .animated-blue-pointer-dot {
    position: absolute;
    width: 31%;
    left: -17px;
    top: 0%;
    transform: translate(-50%, -50%);
  }
`;
