import styled from "styled-components";
import React from "react";
import panel5Database from "assets/b2b marketing/Base de datos@1x.webp";
import panel5Profile from "assets/b2b marketing/LinkedIn@1x.webp";
import panel5Landing from "assets/b2b marketing/landing@1x.webp";
import panel5Background from "assets/b2b marketing/b2b-marketing-background-responsive.webp";
import panel5Person from "assets/b2b marketing/persona-b2b-marketing.webp";

const ServicesSection5Images = () => {
  return (
    <StyledDiv>
      <div className="pointer-events-none">
        <div id="responsive-services-section-5-images-container">
          <div className="responsive-section-5-wrapper-outter">
            <div className="responsive-section-5-wrapper-inner">
              <div className="responsive-section-5-background-wrapper-outter">
                <div className="responsive-section-5-background-wrapper-inner">
                  <img src={panel5Background} alt="" className="responsive-section-5-background-image" />
                </div>
              </div>
              <div className="responsive-section-5-inner-images-container-outter">
                <div className="responsive-section-5-inner-images-container-inner">
                  <div className={`responsive-section-5-person-wrapper-outter`}>
                    <div className={`responsive-section-5-person-wrapper-inner`}>
                      <img src={panel5Person} alt="" className="responsive-section-5-person-image" />
                    </div>
                  </div>
                  <div className={`responsive-section-5-database-wrapper-outter`}>
                    <div className={`responsive-section-5-database-wrapper-inner`}>
                      <img src={panel5Database} alt="" className="responsive-section-5-database-image" />
                    </div>
                  </div>
                  <div className={`responsive-section-5-profile-wrapper-outter`}>
                    <div className={`responsive-section-5-profile-wrapper-inner`}>
                      <img src={panel5Profile} alt="" className="responsive-section-5-profile-image" />
                    </div>
                  </div>
                  <div className={`responsive-section-5-landing-wrapper-outter`}>
                    <div className={`responsive-section-5-landing-wrapper-inner`}>
                      <img src={panel5Landing} alt="" className="responsive-section-5-landing-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  #responsive-service-section-5-images-container {
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translatex(-50%);
    overflow: visible;
  }
  .responsive-section-5-wrapper-outter {
    position: relative;
    width: 100%;
    //margin-left: 10%;
    box-shadow: 0.3px 0.3px 2.2px rgba(0, 0, 0, 0.02), 0.8px 0.8px 5.3px rgba(0, 0, 0, 0.028), 1.5px 1.5px 10px rgba(0, 0, 0, 0.035), 2.7px 2.7px 17.9px rgba(0, 0, 0, 0.042), 5px 5px 33.4px rgba(0, 0, 0, 0.05), 12px 12px 80px rgba(0, 0, 0, 0.07);
  }
  .responsive-section-5-wrapper-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 125%;
  }
  .responsive-section-5-background-wrapper-outter {
    position: absolute;
    inset: 0;
  }
  .responsive-section-5-background-wrapper-inner {
    position: absolute;
    inset: 0;
    border-radius: inherit;
  }
  .responsive-section-5-background-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
    border-radius: inherit;
  }
  .responsive-section-5-inner-images-container-outter {
    position: absolute;
    inset: 0;
  }
  .responsive-section-5-inner-images-container-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .responsive-section-5-person-wrapper-outter {
    position: absolute;
    width: 66%;
    bottom: 0;
    right: -10%;
  }
  .responsive-section-5-person-wrapper-inner {
    position: relative;
    width: 100%;
    padding-top: 180%;
    bottom: 0;
  }
  .responsive-section-5-person-image {
  }
  .responsive-section-5-database-wrapper-outter,
  .responsive-section-5-profile-wrapper-outter,
  .responsive-section-5-landing-wrapper-outter {
    position: absolute;
    width: 25%;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
  }
  .responsive-section-5-database-wrapper-inner,
  .responsive-section-5-profile-wrapper-inner,
  .responsive-section-5-landing-wrapper-inner {
    position: relative;
    width: 100%;
  }

  .responsive-section-5-database-wrapper-inner {
    padding-top: 150%;
  }
  .responsive-section-5-profile-wrapper-inner {
    padding-top: 150%;
  }
  .responsive-section-5-landing-wrapper-inner {
    padding-top: 150%;
    position: relative;
  }
`;

export default ServicesSection5Images;
