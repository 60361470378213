import PanelContentBodyWrapper from "../PanelContentBodyWrapper";
import PanelContentFooterWrapper from "../PanelContentFooterWrapper";
import PanelContentSubtitleWrapper from "../PanelContentSubTitleWrapper";
import PanelContentTitleWrapper from "../PanelContentTitleWrapper";
import PanelContentWrapper from "../PanelContentWrapper";
import panel5Escala from "assets/b2b marketing/Logo Escala-01.svg";
import panel5Abb from "assets/b2b marketing/Logo ABB.svg";
import panel5Workplace from "assets/b2b marketing/Facebook Workplace.svg";
import panel5MaerskLogo from "assets/b2b marketing/logo maersk-01.svg";
import { ReactComponent as FlechaPequena } from "assets/flecha-pequena-v2.svg";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import { useAppContext } from "utilities/context";

const B2BMarketingContent = ({ id = "panel-5-content" }) => {
  const { t } = useTranslation(["common"]);
  const { openContactForm } = useAppContext();
  return (
    <StyledSection>
      <PanelContentWrapper id={id}>
        <PanelContentTitleWrapper number={5} titlePreview={t("services.B2B marketing campaign.title-preview")}>
          {t("services.B2B marketing campaign.title")}
        </PanelContentTitleWrapper>
        <PanelContentSubtitleWrapper>{t("services.B2B marketing campaign.subtitle")}</PanelContentSubtitleWrapper>
        <PanelContentBodyWrapper>
          <Trans i18nKey="services.B2B marketing campaign.content">
            <p className="block mb-6">parrafo 1</p>
            <p className="block">parrafo 2</p>
          </Trans>
          <br />
          <div className="pointer-events-auto">
            <button onClick={openContactForm}>
            {/* <a href="https://blogperformly.ldmclientes.com/publicidad-de-robots-estrategias-posicionamiento-abb/" noopener="true" noreferrer="true" nofollow="true" target="new"> */}
              <div className="flex flex-row-reverse justify-end items-center mt-3">
                <div className="flex flex-col justify-start items-start space-y-2">
                  <div className="text-light-medium text-md font-light uppercase leading-none">Business to Business </div>
                  <div className="text-light-medium font-semibold text-md leading-none flex-row justify-start items-center">
                    {t("services.B2B marketing campaign.success case link label")}<FlechaPequena className="text-light-medium inline-block relative fill-current w-4 h-auto ml-3" />
                  </div>
                </div>
                <div className="pr-4">
                  <img src={panel5Abb} alt="ABB" className="abb h-auto w-12" />
                </div>
            </div>
            </button>
            {/* </a> */}
          </div>
        </PanelContentBodyWrapper>
        <PanelContentFooterWrapper>
          <h4 className="uppercase whitespace-pre-line text-sm pr-6 underline-decoration w-1/6">{t("services.B2B marketing campaign.our clients")}</h4>
          <img src={panel5Abb} alt="Abb logo" title="Abb" className="h-14 w-auto  object-contain " />
          <img src={panel5Escala} alt="Escala logo" title="Escala" className="h-14 w-auto  object-contain pb-2" />
          <img src={panel5Workplace} alt="Workplace logo" title="Workplace" className="h-32 w-auto  object-contain" />
          <img src={panel5MaerskLogo} alt="Maersk logo" title="Maersk" className="h-24 w-auto object-contain" />
        </PanelContentFooterWrapper>
      </PanelContentWrapper>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  .abb {
    background-color: #ffffff94;
    border-radius: 50%;
    height: 64px;
    display: flex;
    width: 64px;
    padding: 7px;
    img {
      display: flex;
      width: 100%;
      object-position: center;
      object-fit: contain;
    }
  }
`;

export default B2BMarketingContent;
