import styled from "styled-components";
import React from "react";
import panel2ResponsiveBackground from "assets/high-converting-ecommerce/ecommerce-background-responsive.webp";
import panel2DesktopFrame from "assets/high-converting-ecommerce/desktop-frame@1x.webp";
import panel2AppFrame from "assets/high-converting-ecommerce/app frame@1x.webp";
import panel2Step1Desktop from "assets/high-converting-ecommerce/step 1 desktop ecommerce@1x.webp";
import panel2Step1App from "assets/high-converting-ecommerce/step 1 mobile ecommerce@1x.webp";
import panel2Step2Desktop from "assets/high-converting-ecommerce/step 2 desktop ecommerce@1x.webp";
import panel2Step2App from "assets/high-converting-ecommerce/step 2 mobile ecommerce@1x.webp";
import panel2Step3Desktop from "assets/high-converting-ecommerce/step 3 desktop e-commerce@1x.webp";
import panel2Step3App from "assets/high-converting-ecommerce/mobile con elementos fuera@1x.webp";
import panel2Step4Desktop from "assets/high-converting-ecommerce/step 4 desktop ecommerce@1x.webp";
import panel2Step4App from "assets/high-converting-ecommerce/4 step mobile e-commerce@1x.webp";
import panel2Step5Desktop from "assets/high-converting-ecommerce/step 5 desktop ecommerce@1x.webp";
import panel2Step5App from "assets/high-converting-ecommerce/step 5 mobile ecommerce@1x.webp";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const slides = [
  {
    appImage: panel2Step1App,
    desktopImage: panel2Step1Desktop,
    i18nKey: "services.high converting ecommerce.step1.title",
  },
  {
    appImage: panel2Step2App,
    desktopImage: panel2Step2Desktop,
    i18nKey: "services.high converting ecommerce.step2.title",
  },
  {
    appImage: panel2Step3App,
    desktopImage: panel2Step3Desktop,
    i18nKey: "services.high converting ecommerce.step3.title",
  },
  {
    appImage: panel2Step4App,
    desktopImage: panel2Step4Desktop,
    i18nKey: "services.high converting ecommerce.step4.title",
  },
  {
    appImage: panel2Step5App,
    desktopImage: panel2Step5Desktop,
    i18nKey: "services.high converting ecommerce.step5.title",
  },
];

const ServicesSection2Slider = () => {
  const { t } = useTranslation("common");

  return (
    <StyledDiv>
      <div id="responsive-services-section-2-slider-container">
        <div className="responsive-section-2-slider-wrapper-outter">
          <div className="responsive-section-2-slider-wrapper-inner">
            {slides.map((slide, index) => (
              <div key={`slide-${index}`} className={`section-2-slide section-2-slide-${index}`}>
                <div className="slide-aspect-ratio-wrapper">
                  <div className="slide-aspect-ratio">
                    <div className="slide-outer-wrapper">
                      <div className={`slide-background slide-background-${index}`}>
                        <img src={panel2ResponsiveBackground} alt="" className="slide-background-image" />
                      </div>
                      <div className={`slide-inner-wrapper slide-inner-wrapper-${index}`}>
                        <div className="current-slide">
                          <div className="paginator-progress">
                            <svg className={`paginator-progress-indicator paginator-progress-indicator-${index} progress-${20 * (index + 1)}`}>
                              <circle r="14" cx="16" cy="16" />
                            </svg>
                            <span className="current-slide-number">{index + 1}</span>
                          </div>
                          <div className="slide-title">{t(slide.i18nKey)}</div>
                        </div>
                        <div className="slide-images">
                          <div className="desktop-frame-outter">
                            <div className="desktop-frame-inner">
                              <img src={panel2DesktopFrame} alt="" className="desktop-frame-image" />
                              <div className="desktop-image-outter">
                                <div className="desktop-image-inner">
                                  <img src={slide.desktopImage} className={`desktop-content-image`} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="app-frame-outter">
                            <div className="app-frame-inner">
                              <img src={panel2AppFrame} alt="" className={`app-frame-image`} />
                              <div className="app-image-outter">
                                <div className="app-image-inner">
                                  <img src={slide.appImage} className={`app-content-image app-content-image-${index}`} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  & {
    --card-separation: 1rem;
  }
  .responsive-section-2-slider-wrapper-outter {
    position: relative;
    width: 80vw;
    left: 50%;
    transform: translatex(-50%);
  }
  .responsive-section-2-slider-wrapper-inner {
    position: relative;
    width: calc(80vw * 5);
    max-width: calc(414px * 5);
    overflow-x: visible;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .section-2-slide {
    position: relative;
    min-width: 80vw;
    max-width: 414px;
  }
  .slide-aspect-ratio {
    position: relative;
    padding: 0 1rem 150% 0;
  }
  .slide-aspect-ratio-wrapper {
    position: relative;
    margin-right: var(--card-separation);
  }
  .slide-outer-wrapper {
    position: absolute;
    background: #ccc;
    border-radius: 1rem;
    overflow: hidden;
    inset: 0;
    /* box-shadow: 0.3px 0.3px 2.2px rgba(0, 0, 0, 0.02), 0.8px 0.8px 5.3px rgba(0, 0, 0, 0.028), 1.5px 1.5px 10px rgba(0, 0, 0, 0.035), 2.7px 2.7px 17.9px rgba(0, 0, 0, 0.042),
      5px 5px 33.4px rgba(0, 0, 0, 0.05), 12px 12px 80px rgba(0, 0, 0, 0.07); */
  }
  .slide-background {
    position: absolute;
    inset: 0;
  }
  .slide-background-image {
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
  }
  .slide-inner-wrapper {
    position: absolute;
    inset: 0;
  }
  .slide-images {
    position: absolute;
    inset: 0;
  }
  .desktop-frame-outter,
  .app-frame-outter {
    position: absolute;
    overflow: visible;
  }
  .desktop-frame-outter {
    width: 90%;
    box-shadow: 0px 1.7px 2.9px rgba(0, 0, 0, 0.02), 0px 4.1px 6.9px rgba(0, 0, 0, 0.028), 0px 7.6px 13px rgba(0, 0, 0, 0.035), 0px 13.6px 23.2px rgba(0, 0, 0, 0.042), 0px 25.5px 43.4px rgba(0, 0, 0, 0.05), 0px 61px 104px rgba(0, 0, 0, 0.07);
    top: 23.33%;
    right: 33%;
  }
  .app-frame-outter {
    width: 80%;
    top: 36.66%;
    left: 16.66%;
  }
  .desktop-frame-inner,
  .app-frame-inner {
    position: relative;
    width: 100%;
    padding-bottom: 80%;
  }
  .desktop-frame-inner {
    padding-bottom: 63.5%;
  }
  .desktop-frame-image {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
  }
  .desktop-image-outter,
  .app-image-outer {
    position: absolute;
    inset: 0;
  }
  .desktop-image-inner,
  .app-image-inner {
    position: absolute;
    inset: 0;
  }
  .app-image-outter {
    position: absolute;
    top: 12.45%;
    left: 32%;
    padding-bottom: 97%;
    right: 19.5%;
    overflow: visible;
  }
  .app-content-image {
    height: 100%;
    width: auto;
    max-width: none;
  }
  .desktop-content-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }
  .app-content-image-2 {
    position: relative;
    transform: translateX(-26.7%);
  }
  .current-slide {
    display: flex;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
  }
  .slide-title {
    position: relative;
    font-size: 1.25rem;
  }
  .paginator-progress {
    width: 2rem;
    height: 2rem;
    position: relative;
    margin-right: 1rem;
  }
  .current-slide-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .paginator-progress-indicator {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    fill: #d5ddea99;
    stroke: #ffffff;
    stroke-width: 2px;
    --dash-array: 87px;
    --perimeter: calc(28px * 3.14159);
    stroke-dasharray: 300px;
    stroke-dashoffset: 0;
  }
  .paginator-progress-indicator.progress-20 {
    stroke-dashoffset: 80%;
  }
  .paginator-progress-indicator.progress-40 {
    stroke-dashoffset: 60%;
  }
  .paginator-progress-indicator.progress-60 {
    stroke-dashoffset: 40%;
  }
  .paginator-progress-indicator.progress-80 {
    stroke-dashoffset: 20%;
  }
  .paginator-progress-indicator.progress-100 {
    stroke-dashoffset: 0%;
  }
`;

export default ServicesSection2Slider;
