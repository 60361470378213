import PanelContentBodyWrapper from "../../components/ResponsiveContentBodyWrapper";
import PanelContentFooterWrapper from "../../components/ResponsiveContentFooterWrapper";
import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentModal";
import seoTecnico from "assets/seo web/seo-tecnico.png";
import seoOffPage from "assets/seo web/seo-offpage.png";
import seoOnSite from "assets/seo web/seo-on-site.png";
import { ReactComponent as FlechaPequena } from "assets/flecha-pequena-v2.svg";
import styled from "styled-components";

import { useTranslation, Trans } from "react-i18next";

const B2BMarketingContent = () => {
    const { t } = useTranslation(["common"]);

    return (
        <StyledSection>
            <PanelContentWrapper>
                <PanelContentTitleWrapper number={6}>{t("services.seo web.title")}</PanelContentTitleWrapper>
                <PanelContentSubtitleWrapper>{t("services.seo web.subtitle")}</PanelContentSubtitleWrapper>
                <PanelContentBodyWrapper>
                    <Trans i18nKey="services.seo web.content">
                        <p className="block mb-6">parrafo 1</p>
                        <p className="block">parrafo 2</p>
                    </Trans>
                    <br />
                    <div className="flex w-11/12">
                        <div className="relative overflow-visible flex flex-col justify-center w-full">
                            <div className="icon rounded-full aspect-square w-1/2 h-12 flex justify-center items-center">
                                <img src={seoTecnico} alt={t("services.seo web.service1")} className="" />
                            </div>
                            <p className="text-left uppercase text-sm w-1/2 text-white md:text-light-dark">
                                {t("services.seo web.service1")}
                            </p>
                        </div>
                        <div className="relative overflow-visible flex flex-col justify-center w-full">
                            <div className="icon rounded-full aspect-square w-1/2 h-12 flex justify-center items-center">
                                <img src={seoOffPage} alt={t("services.seo web.service2")} className="" />
                            </div>
                            <p className="text-left uppercase text-sm w-1/2 text-white md:text-light-dark">
                                {t("services.seo web.service2")}
                            </p>
                        </div>
                        <div className="relative overflow-visible flex flex-col justify-center w-full">
                            <div className="icon rounded-full aspect-square w-1/2 h-12 flex justify-center items-center">
                                <img src={seoOnSite} alt={t("services.seo web.service3")} className="" />
                            </div>
                            <p className="text-left uppercase text-sm w-1/2 text-white md:text-light-dark">
                                {t("services.seo web.service3")}
                            </p>
                        </div>
                    </div>
                </PanelContentBodyWrapper>
                <PanelContentFooterWrapper>
                    <div className="pointer-events-auto">
                        <a href={t("services.seo web.url")} className="flex">
                            <h4 className="whitespace-pre-line text-md text-white">
                                {t("services.seo web.action button")}
                            </h4>
                            <FlechaPequena className="text-white inline-block relative fill-current w-4 h-auto ml-3" />
                        </a>
                    </div>
                </PanelContentFooterWrapper>
            </PanelContentWrapper>
        </StyledSection>
    );
};

const StyledSection = styled.section`
    #panel-6-content .panel-content-subtitle {
        font-weight: 600;
    }
`;

export default B2BMarketingContent;
