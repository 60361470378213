//import styled from "styled-components"; //permite crear estilos para este archivo
import { useTranslation } from "react-i18next";
import ModalContentWrapper from "components/full-size-modal/ModalContentWrapper";
import { LOCATIONS, SECTIONS } from "constants/constants";
import { scrollIntoView } from "utilities/functions";
import { useAppContext } from "utilities/context";
import LocationSlider from "components/where-are-we/components/Slider";

export default function Sitemap() {
  const { t } = useTranslation("common");
  const { closeModal } = useAppContext();
  const links = [
    // { link: t("sitemap.links.link1"), to: SECTIONS[2].id }, // Performance Consulting
    // { link: t("sitemap.links.link2"), to: SECTIONS[14].id }, // High converting e-commerce
    // { link: t("sitemap.links.link3"), to: SECTIONS[15].id }, // Digital Marketing Campaign
    // { link: t("sitemap.links.link4"), to: SECTIONS[16].id }, // Marketplace performance
    // { link: t("sitemap.links.link5"), to: SECTIONS[17].id }, // B2B Marketing Campaign
    { link: t("sitemap.links.link0"), to: SECTIONS[2].id }, // Services
    { link: t("sitemap.links.link6"), to: SECTIONS[3].id }, // About Us
    { link: t("sitemap.links.link7"), to: SECTIONS[4].id }, // Success Cases
    { link: t("sitemap.links.link8"), to: SECTIONS[5].id }, // Partners
    { link: t("sitemap.links.link9"), to: SECTIONS[6].id }, // Awards
    { link: t("sitemap.links.link17"), to: SECTIONS[17].id }, // Diseño
    { link: t("sitemap.links.link18"), to: SECTIONS[19].id }, // SEO
    { link: t("sitemap.links.link19"), to: SECTIONS[18].id }, // Marketing
    // { link: t("sitemap.links.link10"), to: SECTIONS[7].id }, // Blog
  ];

  const locations = [
    { link: t("sitemap.links.link11"), to: LOCATIONS[0].target }, // Panama
    { link: t("sitemap.links.link12"), to: LOCATIONS[1].target }, // Mexico
    { link: t("sitemap.links.link13"), to: LOCATIONS[2].target }, // Costa Rica
    { link: t("sitemap.links.link14"), to: LOCATIONS[3].target }, // Guatemala
    { link: t("sitemap.links.link15"), to: LOCATIONS[4].target }, // United States
    { link: t("sitemap.links.link16"), to: LOCATIONS[5].target }, // Venezuela
  ];

  const linkClasses = "text-lg lg:text-xl cursor-pointer";
  return (
    <ModalContentWrapper>
      <div className="text-white py-24">
        <h2 className="h1 mb-12">{t("sitemap.title")}</h2>
        <ul className="list-disc pl-6 mt-8">
          {/* Map internal links */}
          {links.map((item) => (
            <li
              className={`${linkClasses}`}
              onClick={() => {
                scrollIntoView(item.to);
                closeModal();
              }}>
              {item.link}
            </li>
          ))}
          <li
            className={`${linkClasses}`}
            onClick={() => {
              closeModal();
            }}>
            <a href="https://www.perform-ly.com/blog" target="_blank" rel="nofollow noreferrer">
              Insights
            </a>
          </li>
          {/* Map locations */}
          {locations.map((item) => (
            <li className={`${linkClasses}`}>
              <a href={item.to} target="_blank" rel="noopener noreferrer">
                {t(item.link)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </ModalContentWrapper>
  );
}
