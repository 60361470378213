import styled from "styled-components";
import React from "react";

import panel3Dashboard from "assets/digital-marketing/dashboard@1x.webp";
import panel3Circle1 from "assets/digital-marketing/circle-1.svg";
import panel3Circle2 from "assets/digital-marketing/circle-2.svg";
import panel3Circle3 from "assets/digital-marketing/circle-3.svg";
import panel3Woman from "assets/digital-marketing/woman@1x.webp";
import panel3GooglePointer from "assets/digital-marketing/pointer google ads@1x.webp";
import panel3FacebookPointer from "assets/digital-marketing/pointer facebook@1x.webp";
import panel3Facebooklogo from "assets/digital-marketing/facebook@1x.webp";
import panel3Googlelogo from "assets/digital-marketing/Google Ads Icon@1x.webp";
import panel3TourismPill from "assets/digital-marketing/pildora turismo@1x.webp";
import panel3_25_35Pill from "assets/digital-marketing/pildora-25-35@1x.webp";
import panel3RemarketingPill from "assets/digital-marketing/pildora-remarketing@1x.webp";
import panel3ResponsiveBackground from "assets/digital-marketing/digital marketing background responsive.webp";
import { PointerDot } from "../../../components/digital-marketing-campaign/PointerDot";

const ServicesSection3Images = () => {
  return (
    <StyledDiv>
      <div>
        <div id="responsive-services-section-3-images-container">
          <div className="responsive-section-3-wrapper-outter">
            <div className="responsive-section-3-wrapper-inner">
              <div className="responsive-section-3-background-wrapper-outter">
                <div className="responsive-section-3-background-wrapper-inner">
                  <img src={panel3ResponsiveBackground} alt="" className="responsive-section-3-background-image" />
                </div>
              </div>
              <div className="responsive-section-3-woman-container-outter">
                <div className="responsive-section-3-woman-container-inner">
                  <div className={`responsive-section-3-woman-wrapper-outter`}>
                    <div className={`responsive-section-3-woman-wrapper-inner`}>
                      <div className={`responsive-section-3-circles-wrapper-outter`}>
                        <div className={`responsive-section-3-circles-wrapper-inner`}>
                          <img src={panel3Circle1} className="rotating-1 absolute" alt="" />
                          <img src={panel3Circle2} className="rotating-2 absolute" alt="" />
                          <img src={panel3Circle3} className="rotating-3 absolute" alt="" />
                        </div>
                      </div>
                      <img src={panel3Woman} alt="" className="section-3-woman-image" />
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="responsive-section-3-dashboard-container-outter">
                <div className="responsive-section-3-dashboard-container-inner">
                  <div className={`responsive-section-3-dashboard-wrapper-outter`}>
                    <div className={`responsive-section-3-dashboard-wrapper-inner`}>
                      <div className={`responsive-section-3-dashboard-frame`}>
                        <img src={panel3Dashboard} className="responsive-panel-3-dashboard-frame-image object-contain w-full h-auto" alt="decoration" />
                      </div>
                      <div className={`responsive-section-3-google-pointer-wrapper-outter`}>
                        <div className={`responsive-section-3-google-pointer-wrapper-inner`}>
                          <div className={`responsive-section-3-pointer-shadow`} />
                          <div className="animated-green-pointer-dot animated-dot">
                            <PointerDot id="green-pointer-dot" className="" color="green" />
                          </div>
                          <div className="responsive-section-3-google-pointer-images">
                            <img src={panel3GooglePointer} className="panel-3-google-pointer" alt="decoration" />
                            <img src={panel3Googlelogo} className="panel-3-google-logo" alt="decoration" />
                            <img src={panel3TourismPill} className="panel-3-pildora-turismo" alt="decoration" />
                            <img src={panel3_25_35Pill} className="panel-3-pildora-25-35" alt="decoration" />
                          </div>
                        </div>
                      </div>
                      <div className={`responsive-section-3-facebook-pointer-wrapper-outter`}>
                        <div className={`responsive-section-3-facebook-pointer-wrapper-inner`}>
                          {/* <div className={`responsive-section-3-pointer-shadow`} /> */}
                          <div className="animated-blue-pointer-dot animated-dot">
                            <PointerDot id="blue-pointer-dot" className="" color="blue" />
                          </div>
                          <div className="responsive-section-3-facebook-pointer-images">
                            <img src={panel3FacebookPointer} className="panel-3-facebook-pointer" alt="decoration" />
                            <img src={panel3Facebooklogo} className="panel-3-facebook-logo" alt="decoration" />
                            <img src={panel3RemarketingPill} className="panel-3-pildora-remarketing" alt="decoration" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  #responsive-service-section-3-images-container {
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translatex(-50%);
  }
  .responsive-section-3-wrapper-outter {
    position: relative;
    width: 100%;
    //margin-left: 10%;
  }
  .responsive-section-3-wrapper-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 125%;
  }
  .responsive-section-3-images-wrapper-outter {
    position: absolute;
    width: 100%;
    //margin-left: 10%;
  }
  .responsive-section-3-images-wrapper-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 125%;
  }
  .responsive-section-3-background-wrapper-outter {
    position: absolute;
    inset: 0;
    /* box-shadow: 0.3px 0.3px 2.2px rgba(0, 0, 0, 0.02), 0.8px 0.8px 5.3px rgba(0, 0, 0, 0.028), 1.5px 1.5px 10px rgba(0, 0, 0, 0.035), 2.7px 2.7px 17.9px rgba(0, 0, 0, 0.042),
      5px 5px 33.4px rgba(0, 0, 0, 0.05), 12px 12px 80px rgba(0, 0, 0, 0.07);
     */
    overflow: hidden;
  }
  .responsive-section-3-background-wrapper-inner {
    position: absolute;
    inset: 0;
    border-radius: inherit;
  }
  .responsive-section-3-background-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
  }
  .responsive-section-3-dashboard-container-outter {
    position: absolute;
    inset: 0;
  }
  .responsive-section-3-dashboard-container-inner {
    position: absolute;
    inset: 0;
  }
  .responsive-section-3-dashboard-wrapper-outter {
    position: absolute;
    top: 23.3%;
    width: 80%;
  }
  .responsive-section-3-dashboard-wrapper-inner {
    position: relative;
    right: 52%;
    width: 100%;
    max-height: 0;
    padding-bottom: 67.5%;
    border-radius: 0.5rem;
    box-shadow: 0px 6.7px 5.3px rgba(0, 0, 0, 0.048), 0px 22.3px 17.9px rgba(0, 0, 0, 0.072), 0px 100px 80px rgba(0, 0, 0, 0.12);
  }
  .responsive-panel-3-dashboard-frame {
    position: absolute;
    width: 100%;
    margin: 0 -1%;
    height: 100%;
    max-width: none;
  }
  .responsive-section-3-google-pointer-wrapper-outter {
    position: absolute;
    left: 100%;
    width: 30%;
    bottom: 52%;
  }
  .responsive-section-3-facebook-pointer-wrapper-outter {
    position: absolute;
    left: 100%;
    width: 30%;
    top: 52%;
  }
  /*   .responsive-section-3-facebook-pointer-wrapper-inner {
    position: absolute;
    inset: 0;
  }
  .responsive-section-3-facebook-pointer-wrapper-inner {
    position: absolute;
    inset: 0;
  } */
  .responsive-section-3-pointer-shadow {
    position: absolute;
    inset: 0 0 0 6%;
    box-shadow: 0px 6.7px 5.3px rgba(0, 0, 0, 0.048), 0px 22.3px 17.9px rgba(0, 0, 0, 0.072), 0px 100px 80px rgba(0, 0, 0, 0.12);
  }
  .responsive-section-3-google-pointer-images {
    position: relative;
    z-index: 2;
    & * {
      z-index: 2;
    }
  }
  .responsive-section-3-facebook-pointer-images {
    position: relative;
    z-index: 2;
    & * {
      z-index: 2;
    }
  }
  .responsive-section-3-woman-container-outter {
    position: absolute;
    width: 77%;
    right: -35%;
    bottom: 0;
  }
  .responsive-section-3-woman-container-inner {
    position: relative;
    width: 100%;
    padding-top: 151%;
  }
  .responsive-section-3-woman-wrapper-outter {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
  }
  .responsive-section-3-circles-wrapper-outter {
    position: absolute;
    width: 50%;
    top: -5%;
  }
  .responsive-section-3-circles-wrapper-inner {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }
  .rotating-1,
  .rotating-2,
  .rotating-3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .rotating-1 {
    width: 60%;
    height: 60%;
  }
  .rotating-2 {
    width: 80%;
    height: 80%;
  }
  .rotating-3 {
    width: 100%;
    height: 100%;
  }
`;

export default ServicesSection3Images;
