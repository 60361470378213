import React from "react";

export const ServiceIcon = ({ icon, caption, alt = "decorative", link = null }) =>
    link ? (
        <div class="pointer-events-auto">
            <a href={link} className="relative overflow-visible w-12 flex flex-col items-center">
                <div className="icon rounded-full w-12 h-12 mb-4 bg-gray-500 md:bg-dark-2 shadow-lg flex justify-center items-center">
                    <img src={icon} alt={alt} className="w-6 h-6 object-contained" />
                </div>
                <p className="text-center text-md text-white md:text-light-dark">{caption}</p>
            </a>
        </div>
    ) : (
        <div className="relative overflow-visible w-12 flex flex-col items-center">
            <div className="icon rounded-full w-12 h-12 mb-4 bg-gray-500 md:bg-dark-2 shadow-lg flex justify-center items-center">
                <img src={icon} alt={alt} className="w-6 h-6 object-contained" />
            </div>
            <p className="text-center text-md text-white md:text-light-dark">{caption}</p>
        </div>
    );
