import { useLayoutEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAppContext } from "utilities/context";
/* import AlbertoInfo from "../about-us/AlbertoInfoModal";
import OscarInfo from "../about-us/OscarInfoModal";
import PerformanceConsultingContent from "../services/ResponsiveServiceSection/sections/performance-consulting/ModalContent";
import HighPerformanceEcommerceContent from "../services/ResponsiveServiceSection/sections/high-converting-ecommerce/ModalContent";
import MarketplacePerformanceContent from "../services/ResponsiveServiceSection/sections/marketplace-performance/ModalContent";
import DigitalMarketingContent from "../services/ResponsiveServiceSection/sections/digital-campaign/ModalContent";
import B2BMarketingContent from "../services/ResponsiveServiceSection/sections/b2b-marketing/ModalContent";
 */
import { useSelector } from "utilities/functions";

gsap.registerPlugin(ScrollTrigger);

/* const component = {
  alberto: <AlbertoInfo />,
  oscar: <OscarInfo />,
  performanceConsulting: <PerformanceConsultingContent />,
  marketplacePerformance: <MarketplacePerformanceContent />,
  highConvertingEcommerce: <HighPerformanceEcommerceContent />,
  digitalMarketing: <DigitalMarketingContent />,
  B2BMarketing: <B2BMarketingContent />,
}; */

/* const background = {
  alberto: "bg-gray-2",
  oscar: "bg-gray-2",
}; */

export const FullSizeModal = () => {
  const { isModalOpen, dismissModal, modalContent, modalBackground } = useAppContext();

  const [q, ref] = useSelector();

  useLayoutEffect(() => {
    let modalAnimation = gsap.timeline();

    if (isModalOpen && isModalOpen !== "close") {
      modalAnimation = gsap
        .timeline({
          onComplete: () => {
            q(".name-field"); //&& console.log(q("name-field"));
          },
        })
        .fromTo(
          ref.current,
          {
            scale: 1,
            yPercent: "-100",
          },
          {
            scale: 1,
            yPercent: 0,
            duration: 0.6,
          }
        )
        .fromTo(
          q(".modal-content"),
          {
            opacity: 0,
            y: "-15",
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.6,
          }
        )
        .fromTo(
          q("#close-modal-button"),
          {
            autoAlpha: 0,
            scale: "1.2",
          },
          {
            autoAlpha: 1,
            scale: 1,
            duration: 0.6,
          },
          "<+=0.5"
        );
    }
    if (isModalOpen && isModalOpen === "close") {
      modalAnimation = gsap
        .timeline({ onComplete: dismissModal })
        .set(q(".modal-content *"), { overflow: "hidden" })
        .fromTo(
          q("#close-modal-button"),
          {
            opacity: 1,
            scale: 1,
          },
          {
            duration: 0.6,
            opacity: 0,
            scale: "1",
          }
        )
        .fromTo(
          q(".modal-content"),
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            y: "-15",
            duration: 0.6,
          },
          "<"
        )
        .fromTo(
          ref.current,
          {
            scale: 1,
            yPercent: 0,
            duration: 0.6,
          },
          {
            scale: 1,
            yPercent: "-100",
          }
        );
    }
    return () => {
      modalAnimation.kill();
    };
  }, [isModalOpen, q, ref, dismissModal]);

  if (Boolean(isModalOpen)) {
    return (
      <StyledDiv id="modal-window" ref={ref} className={`${modalBackground ? modalBackground : "bg-secondary"} w-full`}>
        <div className="modal-content-wrapper">
          <div className="modal-content h-screen">{modalContent}</div>
        </div>
      </StyledDiv>
    );
  } else {
    return null;
  }
};

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 5000;

  .modal-content-wrapper {
    top: 0;
    left: 50%;
    transform: translatex(-50%);
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    // background: #171717e8 !important;
    // opacity: 0.9;
  }
  #close-modal-button {
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    top: 2rem;
    right: 2rem;
    transition: 0.3s transform;
    cursor: pointer;
    z-index: 5050;
    &:hover {
      scale: 1.13;
    }
  }
  .modal-content {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
  &.modal-content-wrapper {
    @media screen and (max-width: 1023px) {
      opacity: 1;
      background: #171717 !important;
    }
    @media screen and (min-width: 1024px) {
      background: rgb(23, 23, 23);
      background: linear-gradient(65deg, rgba(23, 23, 23, 0.45) 0%, rgba(23, 23, 23, 1) 86%);
    }
  }
  @media screen and (min-width: 1024px) {
    .home-section-active .modal-content-wrapper {
      max-width: 500px;
    }
  }
`;
