import React from "react"; //importamos react
import styled from "styled-components"; //permite crear estilos para este archivo
import LogoLight from "assets/logo-light.svg";
import NavigationMenu from "./components/NavigationMenu";
import ContactUsButton from "./components/ContactUsButton";
import { gsap } from "gsap";
import { EASE_OUT_DEFAULT } from "constants/constants";
import { MenuToggler } from "./components/MobileToggle";
import { useSelector } from "utilities/functions";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function HeaderBar() {
  const [q, ref] = useSelector();

  React.useEffect(() => {
    gsap.from(q("#header-logo"), {
      autoAlpha: 0,
      yPercent: 30,
      delay: 0.05,
      duration: 0.8,
      ease: EASE_OUT_DEFAULT,
    });
    gsap.from(q("#navigation-bar"), {
      delay: 0.2,
      autoAlpha: 0,
      x: 20,
      duration: 0.9,
      ease: EASE_OUT_DEFAULT,
    });
  }, [q]);

  const location = useLocation();
  const isH2 = ["calculator/","calculadora/"].reduce((acc,route)=>{
    return acc ? acc :location.pathname.includes(route)
  },false)

  const HeaderTitle = (_props) => {
    let HeadingTag = isH2 ? "h2" : "h1";
    return (
      <HeadingTag title={_props?.title ? _props?.title : null}>
        {_props.children}
      </HeadingTag>
    )
  }

  if(location.pathname === "/google-grader") {
    return (
      <></>
    )
  }

  return (
    <>
      <StyledContainer
        ref={ref}
        className="container md:mx-auto flex flex-row justify-start md:justify-between items-center absolute absolute-center-h text-gray-11 z-10 overflow-y-visible"
      >
        <div
          id="header-logo"
          className="my-8 w-1/2 md:max-w-6/12 lg:max-w-[10rem] xl:max-w-[12rem]] md:my-12 invisible shrink-0"
        >
          <Link to="/">
            {/* <HeaderTitle title="Performly"> */}
              <img
                src={LogoLight}
                className="2xl:w-full h-12 object-left object-contain w-auto"
                alt="Performly Logo"
                title="Performly"
              />
            {/* </HeaderTitle> */}
          </Link>
        </div>
        <div id="navigation-bar" className="w-full">
          <div className="hidden lg:flex flex-row justify-end text-base">
            <NavigationMenu />
            <ContactUsButton />
          </div>
        </div>
      </StyledContainer>
      <MenuToggler />
    </>
  );
}

const StyledContainer = styled.div`
  #header-logo {
    img {
      transform: translatey(-12%);
    }
    @media screen and (min-width: 1024px) {
    }

    @media only screen and (min-width: 2000px) {
    }
    @media only screen and (min-width: 1536px) {
    }
  }
`;
