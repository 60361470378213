import React from "react"; //importamos react
import { useTranslation } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import Container from "../Container";
import capsulas from "assets/images/capsulas-blog.webp";
import { ArrowLink } from "../common/ArrowLink";
import FadeInPlace from "components/animations/FadeInPlace";

export default function OurBlog({ id = null }) {
  const { t } = useTranslation("common");

  return (
    <SeccionConEstilos id={id} className=" bg-gray-3 ">
      <Container className="flex flex-row lg:pt-36 pt-24 pb-24 lg:pb-48 flex-wrap ">
        <h3 className="reveal-animation-title h2 text-light-medium text-3xl titulo-seccion mb-4 block lg:hidden pl-7 md:text-2xl  md:px-24">{t("our-blog.title")}</h3>
        <span className="text-md font-light mb-12 lg:hidden block pl-7">{t("about-us.sub-title")}</span>
        <div className="fade-in-left image-container left-custom w-full lg:w-4/12 relative overflow-visible">
          <div className="image-wrapper w-screen lg:w-full relative lg:mb-0 lg:flex height-custom absolute-center-h">
            <div className="relative h-0 lg:h-auto lg:absolute lg:right-0 padding-custom">
              <div id="featured-blog-post-image" className="block h-full w-full absolute lg:relative right-0  w-custom ">
                <img className="object-cover h-full lg:h-auto z-10 lg:w-full lg:min-w-full block rounded md:rounded-xl shadow-img lg:absolute object-right" src="https://www.perform-ly.com/blog/wp-content/uploads/2021/08/pexels-kristina-snowasp-8978878-2-1440x720.jpg" alt="author" />
              </div>
            </div>

            <div className="relative lg:block block lg:mt-auto lg:mb-0">
              <img className=" relative z-0" src={capsulas} alt="blog decoration" />
            </div>
          </div>
        </div>
        <div className="w-1/12"></div>
        <div className="flex-grow flex flex-col w-full lg:w-7/12 items-start justify-center px-7  lg:px-0 ">
          <h3 className="reveal-animation-title h2 text-light-medium text-3xl titulo-seccion hidden lg:block ">{t("our-blog.title")}</h3>
          <FadeInPlace className="mb-12">
            <span className="font-light text-lg text-light-medium">{t("our-blog.sub-title")}</span>
          </FadeInPlace>

          <FadeInPlace>
            <a href="https://www.perform-ly.com/blog/category/ecommerce-es/" target="_blank" rel="noreferrer nofollow" className="pointer-events-auto">
              <span className="uppercase font-light text-base text-grey-7 md:text-md md:mb-6">{t("our-blog.item1.category")}</span>
            </a>
          </FadeInPlace>
          <h4 className="fade-in-place text-gray-2 relative text-2x1 text-xl font-light lg:w-10/12 leading-[1.13] md:text-2xl">{t("our-blog.item1.title")}</h4>
          <FadeInPlace>
            <ArrowLink url={t("our-blog.item1.link")} className="block text-gray-1 text-base mt-12 font-normal lg:font-light md:text-md md:mt-6">
              {t("our-blog.button")}
            </ArrowLink>
          </FadeInPlace>

          <div className="my-16 relative under-line-blog"></div>

          <div className="flex flex-wrap lg:w-15/10 self-end 2xl:w-full flex-row justify-between">
            <div className="fade-in-right lg:w-5/12 w-10/12 flex md:flex-col lg:flex-row lg:min-w-0 min-w-full md:w-6/12 md:min-w-min">
              <div className="w-2/12 md:w-4/12 flex h-24 md:justify-center">
                <div className="w-full pb-full max-h-0 relative">
                  <img src="https://www.perform-ly.com/blog/wp-content/uploads/2021/08/pexels-nappy-936094-1-2-295x250.jpg" className="w-4/5 h-4/5 absolute object-cover rounded-full" alt="author of blog post" width={72} height={72} />
                </div>
              </div>
              <div className="w-1/12 block md:hidden"></div>
              <div className="w-9/12 md:w-11/12 lg:w-8/12">
                <a href="https://www.perform-ly.com/blog/category/performance-marketing-es/" target="_blank" rel="noreferrer nofollow" className="pointer-events-auto">
                  <span className="text-base text-light-medium uppercase font-light">{t("our-blog.item2.category")}</span>
                </a>
                <h4 className="text-gray-1 md:text-md">{t("our-blog.item2.title")}</h4>
                <ArrowLink url={t("our-blog.item2.link")} className="block text-gray-1 text-base mt-12 md:mt-8 font-normal">
                  {t("our-blog.button")}
                </ArrowLink>
              </div>
            </div>

            <div className="fade-in-right lg:w-5/12 w-1/12 md:flex-col lg:flex-row lg:min-w-0 min-w-full md:min-w-min lg:flex hidden md:flex md:w-6/12">
              <div className="w-4/12 flex h-24 md:justify-center">
                <div className="w-full pb-full max-h-0 relative">
                  <img src="https://www.perform-ly.com/blog/wp-content/uploads/2021/08/pexels-karolina-grabowska-5882683-1-2-295x250.jpg" className="w-4/5 h-4/5 absolute object-cover rounded-full" alt="author" width={72} height={72} />
                </div>
              </div>
              <div className="w-9/12 md:w-11/12 lg:w-8/12">
                <a href="https://www.perform-ly.com/blog/category/martech-es/" target="_blank" rel="noreferrer nofollow" className="pointer-events-auto">
                  <span className="text-base text-light-medium uppercase font-light">{t("our-blog.item3.category")}</span>
                </a>
                <h4 className="text-gray-1 md:text-md">{t("our-blog.item3.title")}</h4>
                <ArrowLink url={t("our-blog.item3.link")} className="block text-gray-1 text-base mt-12 md:mt-8 font-normal">
                  {t("our-blog.button")}
                </ArrowLink>
              </div>
            </div>

            <div className="lg:hidden block relative lg:mt-auto lg:mb-0 w-full">
              <img className=" relative z-0 md:max-w-xs" src={capsulas} alt="blog background decoration" />
            </div>
          </div>
        </div>
      </Container>
    </SeccionConEstilos>
  );
}
const SeccionConEstilos = styled.div`
  #featured-blog-post-image {
    box-shadow: 0px 2.8px 3.3px rgba(0, 0, 0, 0.025), 0px 6.7px 8px rgba(0, 0, 0, 0.036), 0px 12.5px 15px rgba(0, 0, 0, 0.045), 0px 22.3px 26.8px rgba(0, 0, 0, 0.054), 0px 41.8px 50.1px rgba(0, 0, 0, 0.065), 0px 100px 120px rgba(0, 0, 0, 0.09);

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      right: unset;
      left: calc((100vw / 8 + 1rem) * -1);
    }
  }
  .padding-custom {
    padding-bottom: 75%;
    @media only screen and (min-width: 768px) {
      padding-bottom: 50%;
    }
    @media only screen and (min-width: 1024px) {
      padding-bottom: unset;
    }
  }
  .absolute-center-h {
    @media only screen and (min-width: 1040px) {
      left: unset;
      transform: unset;
    }
  }
  .w-custom {
    min-width: 658px;
  }
  .shadow-img {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.003), 0 6.7px 5.3px rgba(0, 0, 0, 0.004), 0 12.5px 10px rgba(0, 0, 0, 0.005), 0 22.3px 17.9px rgba(0, 0, 0, 0.006), 0 41.8px 33.4px rgba(0, 0, 0, 0.007), 0 100px 80px rgba(0, 0, 0, 0.01);
  }

  .under-line-blog:before {
    content: "";
    width: 1100px;
    height: 1px;
    position: absolute;
    background-color: #696969;
    top: 0px;
    right: 0px;
    left: 0;
  }
  @media only screen and (min-width: 1440px) {
    .left-custom {
      left: 61px;
    }
    .height-custom {
      height: 698px;
    }
    .top-410 {
      top: 410px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .under-line-blog:after {
      content: "";
      width: 32px;
      height: 1px;
      position: absolute;
      background-color: #696969;
      top: 1px;
      left: 0;
    }
  }
  @media only screen and (max-width: 765px) {
    .w-custom {
      min-width: 429px;
    }
    .under-line-blog:after {
      content: none;
    }
  }
`;
