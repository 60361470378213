import styled from "styled-components";
import avatarOscar from "assets/contact-us/avatar-oscar-alvarez.webp";
import { useTranslation } from "react-i18next";
import ig from "assets/ig.svg";
import lk from "assets/lk.svg";

export default function ThankYouModal(props) {
  const { t } = useTranslation(["contact-us"]);
  return (
    <StyledDiv className="bg-secondary min-h-screen w-full flex justify-center items-center">
      <div className="grid grid-cols-1 gap-y-8 lg:gap-y-16">
        <div className="oscar-avatar w-20 h-20 mx-auto  ">
          <img src={avatarOscar} alt={t("contact-us:oscar alvares avatar alt")} className="w-full h-full object-contain" />
        </div>
        <div className="thank-you-message mb-16 lg:mb-0">
          <h1 className="text-center text-white text-lg lg:text-3xl font-light mb-4 lg:mb-6">{t("contact-us:gracias por tu solicitud")}</h1>
          <p className="text-center text-white text-base lg:text-md-larger font-light">{t("contact-us:pronto te contactaremos")}</p>
        </div>
        <div>
          <p className="text-center text-dark-dark lg:text-white mb-8 lg:mb-6 text-base lg:text-md font-light">{t("contact-us:sigamos en contacto")}</p>
          <div className="flex justify-center gap-x-3">
            <div className="social-link">
              <a className="" href="https://www.instagram.com/performly_agency/" target="_blank" rel="noreferrer nofollow">
                <img src={ig} alt="Logo Instagram" />
              </a>
            </div>
            <div className="social-link">
              <a className="" href="https://www.linkedin.com/company/perform-ly/" target="_blank" rel="noreferrer nofollow">
                <img src={lk} alt="Logo Linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
}
const StyledDiv = styled.div``;
