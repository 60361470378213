import React, { useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import education from "assets/success-cases/iesa-casos-de-exito.webp";
import educationResponsive from "assets/success-cases/iesa-casos-de-exito-mobile.webp";
import retail from "assets/success-cases/casos-de-exito-retail.webp";
import retailResponsive from "assets/success-cases/02 Novey Mobile.webp";
import realState from "assets/success-cases/Casos-de-exito-Real-Estate.webp";
import realStateResponsive from "assets/success-cases/03 RE Mobile.webp";
import foodIndustry from "assets/success-cases/Casos-de-exito-Campero.webp";
import foodIndustryResponsive from "assets/success-cases/01 Pollo Campero Mobile.webp";
import wellBeing from "assets/success-cases/Casos-de-exito-Impilo.webp";
import wellBeingResponsive from "assets/success-cases/04 Impilo Mobile.webp";

import retailLogo from "assets/partners/Novey.svg";
import realStateLogo from "assets/success-cases/Panama Pacifico Logo.svg";
import foodIndustryLogo from "assets/success-cases/pollocampero_logo-casos-de-exito 1.svg";
import wellBeingLogo from "assets/success-cases/Impilo logo.svg";
import educationLogo from "assets/success-cases/Iesa-Logo.svg";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Draggable } from "gsap/all";
import { useSelector } from "utilities/functions";
import LinkToCase from "./LinkToCase";
import LearnMoreLink from "./LearnMoreLink";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable);

const slides = [
  {
    i18nKey: "iesa",
    brand: "IESA",
    case: "success-cases.education",
    logo: educationLogo,
    photo: education,
    photoResponsive: educationResponsive,
    google: false,
    target: "mercadotecnia-educativa",
  },
  {
    i18nKey: "panama pacifico",
    brand: "Panamá Pacífico",
    case: "success-cases.real State",
    logo: realStateLogo,
    photo: realState,
    photoResponsive: realStateResponsive,
    google: false,
    target: "marketing-digital-inmobiliario",
  },
  {
    i18nKey: "impilo",
    brand: "Impilo",
    case: "success-cases.wellbeing",
    logo: wellBeingLogo,
    photo: wellBeing,
    photoResponsive: wellBeingResponsive,
    google: false,
    target: "marketing-de-salud",
  },
  {
    i18nKey: "campero",
    brand: "Campero",
    case: "success-cases.food industry",
    logo: foodIndustryLogo,
    photo: foodIndustry,
    photoResponsive: foodIndustryResponsive,
    google: false,
    target: "marketing-de-restaurantes",
  },
];

export default function SuccessfulCases({ id = null }) {
  const { t } = useTranslation(["common"]);
  const menuItems = ["education", "real State", "wellbeing", "food industry"];
  const [activeSlide, setActiveSlide] = useState(1);

  const [q, ref] = useSelector();

  const slidesAnimations = useRef([]);
  slidesAnimations.current = [];

  const prevSlide = useRef(0);

  function getNextSlide() {
    if (activeSlide === slides.length - 1) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  }

  function getPreviousSlide() {
    if (activeSlide === 0) {
      setActiveSlide(slides.length - 1);
    } else {
      setActiveSlide(activeSlide - 1);
    }
  }

  useLayoutEffect(() => {
    let draggable = Draggable.create("#success-cases-touch-control", {
      zIndexBoost: false,
      type: "x",
      snap: {
        x: 0,
        y: 0,
      },
      cursor: "initial",
      onPress: function () {
        //console.log("clicked");
      },
      onDrag: function () {
        //console.log("dragging");
      },
      onDragEnd: function () {
        if (draggable[0].deltaX < -5) {
          getNextSlide();
        }
        if (draggable[0].deltaX > 5) {
          getPreviousSlide();
        }
        gsap.to(this.target, { x: 0, y: 0, duration: 0 });
      },
    });
  });

  useLayoutEffect(() => {
    let defaultDuration = 1;
    function slideEntry(slide) {
      let animationObj = gsap
        .timeline()
        .addLabel("start")
        .fromTo(q(`#case-image-container > .slide-${slide}-background`), { xPercent: 0, scale: 1.2, opacity: 0, zIndex: 2, immediateRender: false }, { xPercent: 0, scale: 1, opacity: 1, duration: defaultDuration, immediateRender: false, zIndex: 1 })
        .to(q(`#case-image-container > *:not(.slide-${slide}-background)`), { xPercent: 300, zIndex: 0 })
        .set(q(`#case-image-container > .slide-${slide}-background`), { zIndex: 0 })
        .fromTo(q(`.slide-${slide}-case-name`), { yPercent: -50, opacity: 0, immediateRender: false }, { yPercent: 0, opacity: 1, duration: defaultDuration }, "start")
        .to(q(`.case-title-wrapper > *:not(.slide-${slide}-case-name`), { yPercent: 100, opacity: 0, duration: defaultDuration }, "start")
        .to(q(`#case-content-wrapper > *:not(.case-content-${slide}-item)`), { yPercent: 2, opacity: 0, duration: defaultDuration / 2 }, "start")
        .fromTo(q(`.case-content-${slide}-item`), { yPercent: -2, opacity: 0, immediateRender: false }, { yPercent: 0, opacity: 1, duration: defaultDuration / 2 }, `start+=${defaultDuration / 2}`)
        .repeat(0)
        .paused(true);
      return animationObj;
    }

    slides.forEach((slide, index) => {
      slidesAnimations.current.push(slideEntry(index));
    });

    slidesAnimations.current[activeSlide].play();

    prevSlide.current = activeSlide;
    //console.log("current slide", activeSlide, "post animation", prevSlide.current);
  }, [activeSlide, q]);

  return (
    <StyledSection id={id} className={`success-cases-section relative min-h-[600px] md:min-h-570 py-10 md:py-24 lg:py-0 xl:mt-16 2xl:mt-20`} ref={ref}>
      <div className="success-cases-background absolute inset-0 overflow-hidden">
        <div id="case-image-container" className="case-image-container h-full relative">
          {slides.map((item, index) => (
            <div id={`slide-${index}-background`} className={`slide-${index}-background absolute inset-0 case-image-item w-screen min-h-570 ${index === activeSlide ? "active" : ""}`}>
              <img src={item.photo} alt="foto cliente" className="hidden lg:block object-cover h-full w-full" />
              <img src={item.photoResponsive} alt="foto cliente" className="lg:hidden object-cover h-full w-full" />
            </div>
          ))}
        </div>
      </div>
      <div id="success-cases-touch-control" className="absolute inset-0 z-10 lg:hidden" />
      <div id="success-cases-content" className="container relative x-50">
        <div className="relative min-h-full md:min-h-570 flex flex-col-reverse lg:block ">
          {/* Menu Items */}
          <div className="fade-in-left relative section-menu lg:absolute left-0 bottom-0 text-dark-dark flex flex-col lg:flex-row items-baseline lg:justify-start lg:items-end">
            {menuItems.map((item, index) => (
              <div key={item} onClick={() => setActiveSlide(index)} className={`cursor-pointer success-cases-menu-item-wrapper flex border-b lg:border-b-0 lg:border-r border-dark-dark ${index === activeSlide ? "active" : ""}`}>
                <div className={`menu-item  transform lg:rotate-180 text-md lg:text-lg ${index === activeSlide ? "font-semibold text-white" : "font-light"} leading-none lg:pb-12 `}>{t(`success-cases.${item}`)}</div>
              </div>
            ))}
          </div>
          {/* End Menu Items */}
          <div className="content-column flex-grow md:flex-grow-0 pb-8 md:pb-0 relative lg:absolute lg:left-5/12 lg:top-24  lg:bottom-8 lg:w-6/12 2xl:max-w-4/12 text-dark-dark flex flex-col">
            <div>
              <h3 className="reveal-animation-subtitle titulo h3 text-xxl font-light">{t("success-cases.title")}</h3>
              {/* Case names */}
              <h4 className="fade-in-left case-title-wrapper h6[1rem] h-6 overflow-y-hidden overflow-x-visible mb-6 lg:mb-0">
                {slides.map((item, index) => (
                  <div key={index} className={`slide-${index}-case-name absolute h-6 client space-x-2 mb-4 divide-x divide-dark-heading uppercase`}>
                    <span>{item.brand}</span>
                    <span className="pl-2">{t(item.case)}</span>
                  </div>
                ))}
              </h4>
              {/* end case names */}
            </div>
            <div className="fade-in-left case-content-container h-56 w-full lg:w-10/12 2xl:w-3/4 lg:ml-4 flex-grow overflow:visible">
              {/* case content start */}
              <div id="case-content-wrapper" className=" case-content-wrapper h-full relative">
                {slides.map((item, index) => (
                  <div key={index} className={`case-content-${index}-item absolute h-full w-full flex flex-shrink-0 flex-col justify-between md:justify-evenly overflow-visible`}>
                    <div className="mb-8">
                      <p>{t(`success-cases.${item.i18nKey}.content`)}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                      {[1, 2, 3].map((metric, index) => (
                        <div className="flex flex-col justify-start items-center">
                          <div className="text-base font-regular">{t(`success-cases.${item.i18nKey}.metric${metric}.title`)}</div>
                          <div className="text-[20px] font-regular">{t(`success-cases.${item.i18nKey}.metric${metric}.content`)}</div>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-row justify-between items-center mt-8">
                      <div className="fade-in-left h-14 md:h-14 max-w-[96px] relative">
                        <img src={item.logo} alt="client logo" className="h-full w-full max-w-[96px] object-contain object-left" />
                      </div>
                      <div className="pointer-events-auto fade-in-left cta ml-auto relative">{Boolean(slides[activeSlide].target) ? <LinkToCase url={slides[activeSlide].target} google={slides[activeSlide].google} /> : <LearnMoreLink className="relative z-20" />}</div>
                    </div>
                  </div>
                ))}
              </div>
              {/* case content end */}
            </div>
            <div className="hidden ml-auto  flex-row justify-end">
              <div className="fade-in-leftcta relative">{Boolean(slides[activeSlide].target) ? <LinkToCase url={slides[activeSlide].target} google={slides[activeSlide].google} /> : <LearnMoreLink className="relative z-20" />}</div>
            </div>
          </div>
        </div>
      </div>
    </StyledSection>
  );
}

const StyledSection = styled.section`
  @media screen and (min-width: 1920px) {
    .success-cases-section {
      margin-top: 12rem !important;
    }
  }
  .case-title-wrapper {
    font-size:1rem;
  } 
  .case-content-container {
    max-width: 24rem;
  }
  .cta-arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid gray;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    padding-bottom: 5px;
  }
  #success-cases-content {
    z-index: 11;
    pointer-events: none;
  }
  .content-column {
    @media screen and (max-width: 1023px) {
      min-height: 26rem;
    }
  }
  .section-menu {
    @media screen and (max-width: 1023px) {
      min-width: 100vw;
      left: 50%;
      transform: translatex(-50%);
    }
  }
  .success-cases-menu-item-wrapper {
    transition: 0.3s;
    position: relative;
    pointer-events: auto;
    .menu-item {
      @media screen and (max-width: 1023px) {
        padding: 0.5rem 3rem 0.5rem 2rem;
      }
    }
    @media screen and (min-width: 1024px) {
      padding-bottom: 3.5rem;
      padding-top: 3.5rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
    @media screen and (min-width: 1440px) {
      padding-bottom: 3.5rem;
      padding-top: 3.5rem;
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
    @media screen and (min-width: 2000px) {
      padding-bottom: 3.5rem;
      padding-top: 3.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &:after {
      position: absolute;
      content: "";
      background: currentColor;
      right: 0;
      transition: 0.3s;
      @media screen and (min-width: 1024px) {
        height: 3.5rem;
        width: 0px;
        top: 0;
      }
      @media screen and (max-width: 1023px) {
        height: 0;
        width: 2rem;
        bottom: 0;
      }
    }
  }
  .case-cta {
    transition: color 0.5s ease;
  }
  .case-cta:hover {
    color: white;
  }
  .google-case-icon {
    opacity: 0;
    transition: opacity 1s ease;
  }
  .google-case .google-case-icon {
    opacity: 1;
  }
  .success-cases-menu-item-wrapper {
    transition: color 0.5s ease, padding 0.5s ease;
  }
  .success-cases-menu-item-wrapper:hover {
    color: white;
    .menu-item {
      @media screen and (max-width: 1023px) {
        padding: 0.75rem 4rem 0.75rem 2rem;
      }
    }
  }
  .success-cases-menu-item-wrapper.active {
    transition: padding 0.5s ease;
    .menu-item {
      transition: padding 0.5s ease;
      @media screen and (max-width: 1023px) {
        padding: 0.75rem 4rem 0.75rem 2rem;
      }
    }
    @media screen and (min-width: 1024px) {
      padding-bottom: 3.5rem;
      padding-top: 3.5rem;
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
    @media screen and (min-width: 1440px) {
      padding-bottom: 3.5rem;
      padding-top: 3.5rem;
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
    @media screen and (min-width: 2000px) {
      padding-bottom: 3.5rem;
      padding-top: 5.5rem;
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
    &:after {
      @media screen and (min-width: 1024px) {
        width: 3px;
      }
      @media screen and (max-width: 1023px) {
        height: 3px;
      }
    }
  }
  .menu-item {
    @media screen and (min-width: 1023px) {
      writing-mode: vertical-lr;
      text-orientation: sideways;
    }
  }
`;
