import React, { forwardRef } from "react";

const defaultCSSClasses = "cta flex flex-row md:flex-grow text-light-dark space-x-2 justify-between pt-12 md:pt-0 items-center text-base";

const PanelContentFooterWrapper = forwardRef(({ children, id = null, className = null }, ref) => (
  <div id={id} ref={ref} className={`${defaultCSSClasses} ${className ? ` ${className}` : ""}`}>
    {children}
  </div>
));

export default PanelContentFooterWrapper;
