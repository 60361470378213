import styled from "styled-components";
//import logoPerformly from "assets/logo-light.svg";
import Lottie from "lottie-react";
import loading from "assets/Loading.json";

export default function Loader() {
  return (
    <StyledLoader position="">
      <div className="w-1/4">
        <Lottie animationData={loading} className="" />
      </div>
    </StyledLoader>
  );
}

const StyledLoader = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  background-color: #333;
  color: white;
`;
