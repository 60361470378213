import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { EASE_OUT_DEFAULT } from "constants/constants";
gsap.registerPlugin(ScrollTrigger);

/*
 * Services Section All Sections Desktop
 * Params q gsap selector utility for id and class scoping
 */

export function wideServicesAnimationSequence(q) {
    const panelExitAnimationObj = {
        opacity: 0,
        x: 200,
        duration: 0.3,
        ease: "power.out",
    };
    const textEnteringAnimationObj = {
        opacity: 0,
        y: 30,
        duration: 0.3,
        ease: "power.out",
        display: "none",
    };
    const textExitAnimationObj = {
        opacity: 0,
        y: -20,
        duration: 0.3,
        ease: "power.out",
    };

    let panelTira1 = gsap
        .timeline()
        .add(marketplacePerformanceStripeAnimation(".panel-4-tira1"))
        .repeat(-1)
        .paused(true);
    let panelTiraCart = gsap
        .timeline()
        .add(marketplacePerformanceStripeAnimation(".panel-4-tira-con-carrito", "up"), "<")
        .repeat(-1)
        .paused(true);
    let panelTiraResponsive = gsap
        .timeline()
        .add(marketplacePerformanceStripeAnimation(".panel-4-tira-responsive"))
        .repeat(-1)
        .paused(true);

    function rotatingCircle(el, duration = 4) {
        return gsap.timeline().to(q(el), { rotate: 360, duration: duration }).repeat(-1).paused(true);
    }

    let circle1Animation = rotatingCircle(".rotating-1", 6);
    let circle2Animation = rotatingCircle(".rotating-2", 4);
    let circle3Animation = rotatingCircle(".rotating-3", 5);

    function rotateCircles() {
        circle1Animation.play();
        circle2Animation.play();
        circle3Animation.play();
    }
    function stopCircles() {
        circle1Animation.pause();
        circle2Animation.pause();
        circle3Animation.pause();
    }

    function pointerDots(el) {
        return gsap
            .timeline()
            .fromTo(
                q(el),
                {
                    scale: 0,
                },
                {
                    scale: 1,
                    duration: 1.5,
                }
            )
            .fromTo(
                q(el),
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    duration: 1,
                },
                "-=0.5"
            )
            .repeat(-1)
            .paused(true);
    }

    function responsiveBackgroundEnterAnimation(number) {
        return gsap.timeline().fromTo(
            q(`#panel-${number}-background`),
            {
                autoAlpha: 0,
            },
            {
                duration: 2,
                autoAlpha: 1,
            }
        );
    }

    function responsiveBackgroundExitAnimation(number) {
        return gsap
            .timeline()
            .set(q(`#panel-${number}-background`), {
                visibility: "visible",
            })
            .set(q(`#panel-${number}-background`), {
                visibility: "hidden",
            });
    }

    function responsiveBackgroundAnimation(number) {
        return gsap
            .timeline()
            .add(responsiveBackgroundEnterAnimation(number))
            .add(responsiveBackgroundExitAnimation(number - 1));
    }
    function marketplacePerformanceStripeAnimation(el, direction = "down") {
        return gsap.timeline().from(q(`${el}`), {
            y: direction === "down" ? `-${100 / 3}%` : `${100 / 3}%`,
            duration: 30,
            ease: "none",
        });
    }

    let pointerDotsAnimation = pointerDots(".ring-wrapper");

    let animationObj = gsap
        .timeline({
            scrollTrigger: {
                markers: false,
                id: "services-desktop-panel",
                trigger: q("#services-animation-container"),
                start: "center center",
                end: "4755px center",
                scrub: true,
                pin: true,
                snap: {
                    snapTo: "labelsDirectional", // snap to the closest label in the timeline
                },
                toggleClass: { targets: `#content`, className: `services-section-active` },
            },
        })
        .set(q("#services-content-wrapper > *"), { background: "transparent" })
        // Panel 1
        .set(q("#panel-1-content"), {
            opacity: 1,
            y: 0,
        })
        .delay(5)
        .addLabel("slide-1")
        .fromTo(
            q("#panel-1-content > * "),
            {
                opacity: 1,
                y: 0,
                display: "none",
            },
            {
                y: -16.67,
                autoAlpha: 0,
                duration: 0.3,
                ease: "none",
                immediateRender: false,
                stagger: {
                    each: 0.15,
                },
            }
        )
        // Panel 2
        .add(responsiveBackgroundAnimation(2))
        .set(q("#panel-2-over-the-mask"), { autoAlpha: 1 })
        .from(q("#panel-2-desktop-frame"), {
            autoAlpha: 0,
            y: -40,
            duration: 0.4,
            ease: "back.out(1.7)",
        })
        .from(q("#panel-2-paginator"), {
            opacity: 0,
            duration: 0.5,
        })
        .from(
            q("#panel-2-app-frame"),
            {
                display: "none",
                opacity: 0,
                x: -100,
                duration: 0.3,
                ease: "back.out(1.7)",
            },
            "-=0.10"
        )
        .from(
            q("#panel-2-content > *"),
            {
                ...textEnteringAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=1"
        )
        .to(q("#panel-1-content"), {
            display: "none",
        })
        .fromTo(
            q("#services-paginator"),
            {
                y: 0,
            },
            {
                y: "-16.67%",
                ease: "none",
                duration: 2,
                immediateRender: false,
            },
            "slide-1"
        )
        .addLabel("slide-2")
        .fromTo(
            q("#panel-2-content > * "),
            {
                opacity: 1,
                y: -16.671,
                display: "none",
            },
            {
                y: -33.34,
                autoAlpha: 0,
                duration: 0.3,
                ease: "none",
                immediateRender: false,
                stagger: {
                    each: 0.15,
                },
            }
        )
        // Panel 3
        .add(responsiveBackgroundAnimation(3))
        .set(q("#panel-3-over-the-mask"), { autoAlpha: 1 })
        .from(
            q(".panel-3-dashboard"),
            {
                opacity: 0,
                y: "-=50",
                duration: 0.3,
                ease: "power.out",
            },
            "-=0.2"
        )
        .to(q("#panel-2-paginator"), {
            autoAlpha: 0,
            duration: 0.5,
            ease: EASE_OUT_DEFAULT,
        })
        .to(q("#panel-2-desktop-frame"), {
            opacity: 0,
            y: -40,
            duration: 0.3,
            ease: "power.out",
            delay: 0.5,
        })
        .to(
            q("#panel-2-app-frame"),
            {
                opacity: 0,
                x: -100,
                duration: 0.3,
                ease: "power.out",
            },
            "-=0.10"
        )
        .to(q("#panel-2-content"), {
            display: "none",
            duration: 0,
        })
        .from(
            q(".panel-3-google-pointer,.panel-3-facebook-pointer"),
            {
                opacity: 0,
                x: "+=30",
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .from(
            q(".panel-3-woman-image"),
            {
                opacity: 0,
                x: "+=30",
                duration: 0.3,
                ease: "power.out",
            },
            "-=0.15"
        )
        .from(
            q(".panel-3-google-logo,.panel-3-facebook-logo"),
            {
                opacity: 0,
                scale: 1.2,
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .from(q(".animated-dot"), { autoAlpha: 0, duration: 0.2 })
        .from(
            q(".panel-3-pildora-turismo,.panel-3-pildora-25-35,.panel-3-pildora-remarketing"),
            {
                opacity: 0,
                scale: 1.2,
                y: "-10",
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .from(
            q(".panel-3-circles-wrapper > *"),
            {
                opacity: 0,
                scale: 1.2,
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .from(
            q("#panel-3-content > *"),
            {
                ...textEnteringAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=0.7"
        )
        .to(
            q("#panel-1-content > *"),
            {
                ...textExitAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=0.15"
        )
        .fromTo(
            q("#services-paginator"),
            {
                y: "-16.671%",
            },
            {
                y: "-33.34%",
                ease: "none",
                duration: 2,
                immediateRender: false,
            },
            "slide-2"
        )
        .addLabel("slide-3")
        .fromTo(
            q("#panel-3-content > * "),
            {
                opacity: 1,
                y: -33.341,
                display: "none",
            },
            {
                y: 50,
                autoAlpha: 0,
                duration: 0.3,
                ease: "none",
                immediateRender: false,
                stagger: {
                    each: 0.15,
                },
            }
        )
        // Panel 4
        .set(q("#panel-3-over-the-mask"), { autoAlpha: 0 })
        .set(q("#panel-4-over-the-mask"), { autoAlpha: 1 })
        .to(q("#panel-3-over-the-mask > *"), {
            ...panelExitAnimationObj,
            stagger: {
                each: -0.05,
            },
            delay: 0.5,
        })
        .from(
            q("#panel-4-over-the-mask > *"),
            {
                opacity: 0,
                scale: 1.2,
                left: "-=10",
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.2,
                },
            },
            "-=0.15"
        )
        .from(
            q("#panel-4-content > *"),
            {
                ...textEnteringAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=0.2"
        )
        .to(q("#panel-3-content"), {
            display: "none",
            duration: 0,
        })
        .to(q("#panel-2-content > *"), {
            ...textExitAnimationObj,
            stagger: {
                each: 0.1,
            },
        })
        .add(responsiveBackgroundAnimation(4))
        .fromTo(
            q("#services-paginator"),
            {
                y: "-33.341%",
            },
            {
                y: "-50%",
                ease: "none",
                duration: 2,
                immediateRender: false,
            },
            "slide-3"
        )
        .addLabel("slide-4")
        .fromTo(
            q("#panel-4-content > * "),
            {
                opacity: 1,
                y: -50.1,
                display: "none",
            },
            {
                y: -66.67,
                autoAlpha: 0,
                duration: 0.3,
                ease: "none",
                immediateRender: false,
                stagger: {
                    each: 0.15,
                },
            }
        )
        // Panel 5
        .set(q("#panel-5-over-the-mask"), { autoAlpha: 1 })
        .to(q("#panel-4-over-the-mask > *"), {
            ...panelExitAnimationObj,
            stagger: {
                each: -0.05,
            },
            delay: 0.5,
        })
        .from(
            q("#panel-5-content > *"),
            {
                ...textEnteringAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=0.2"
        )
        .from(
            q(".panel-5-images-wrapper > *:not(.panel-5-persona)"),
            {
                opacity: 0,
                scale: 1.2,
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .to(q("#panel-4-content"), {
            display: "none",
            duration: 0,
        })
        .to(
            q(".panel-3-circles-wrapper > *"),
            {
                opacity: 0,
                scale: 1.2,
                duration: 0.3,
                ease: "none",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .to(
            q(".panel-3-pildora-turismo,.panel-3-pildora-25-35,.panel-3-pildora-remarketing"),
            {
                opacity: 0,
                scale: 1.2,
                y: "-10",
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .to(q(".animated-dot"), { autoAlpha: 0, duration: 0.3 })
        .to(
            q(".panel-3-google-logo,.panel-3-facebook-logo"),
            {
                opacity: 0,
                scale: 1.2,
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .to(
            q(".panel-3-woman-image"),
            {
                opacity: 0,
                x: "+=30",
                duration: 0.3,
                ease: "power.out",
            },
            "-=0.15"
        )
        .to(
            q(".panel-3-google-pointer,.panel-3-facebook-pointer"),
            {
                opacity: 0,
                x: "+=30",
                duration: 0.3,
                ease: "power.out",
                stagger: {
                    each: 0.05,
                },
            },
            "-=0.15"
        )
        .to(
            q(".panel-3-dashboard"),
            {
                opacity: 0,
                y: "-=50",
                duration: 0.3,
                ease: "power.out",
            },
            "-=0.2"
        )
        .to(
            q("#panel-3-content > *"),
            {
                ...textExitAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=0.15"
        )
        .add(responsiveBackgroundAnimation(5))
        .fromTo(
            q("#services-paginator"),
            {
                y: "-50.1%",
            },
            {
                y: "-66.67%",
                ease: "none",
                duration: 2,
                immediateRender: false,
            },
            "slide-4"
        )
        .addLabel("slide-5")
        .fromTo(
            q("#panel-5-content > * "),
            {
                opacity: 1,
                y: -66.671,
                display: "none",
            },
            {
                y: -83.34,
                autoAlpha: 0,
                duration: 0.3,
                ease: "none",
                immediateRender: false,
                stagger: {
                    each: 0.15,
                },
            }
        )
        // Panel 6
        .set(q("#panel-6-over-the-mask"), { autoAlpha: 1 })
        .to(q("#panel-5-over-the-mask > *"), {
            ...panelExitAnimationObj,
            stagger: {
                each: -0.05,
            },
            delay: 0.5,
        })
        .from(
            q("#panel-6-content > *"),
            {
                ...textEnteringAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=0.2"
        )
        .to(q("#panel-5-content"), {
            display: "none",
            duration: 0,
        })
        .to(
            q("#panel-4-content > *"),
            {
                ...textExitAnimationObj,
                stagger: {
                    each: 0.1,
                },
            },
            "-=0.15"
        )
        .add(responsiveBackgroundAnimation(6))
        .fromTo(
            q("#services-paginator"),
            {
                y: "-66.671%",
            },
            {
                y: "-83.34%",
                ease: "none",
                duration: 2,
                immediateRender: false,
            },
            "slide-5"
        )
        .addLabel("slide-6")
        .fromTo(
            q("#panel-6-content > * "),
            {
                opacity: 1,
                y: -83.341,
                display: "none",
            },
            {
                y: -100,
                autoAlpha: 0,
                duration: 0.3,
                ease: "none",
                immediateRender: false,
                stagger: {
                    each: 0.15,
                },
            }
        )
        .fromTo(
            q("#services-paginator"),
            {
                y: "-83.333%",
            },
            {
                y: "-100%",
                ease: "none",
                duration: 2,
                immediateRender: false,
            },
            "slide-6"
        )
        .delay(0.5)
        .add(() => {
            animationObj.scrollTrigger.direction > 0 ? panelTira1.play() : panelTira1.pause().seek(0);
        }, `slide-3+=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction < 0 ? panelTira1.play() : panelTira1.pause().seek(0);
        }, `slide-5-=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction > 0 ? panelTiraCart.play() : panelTiraCart.pause().seek(0);
        }, `slide-3+=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction < 0 ? panelTiraCart.play() : panelTiraCart.pause().seek(0);
        }, `slide-5-=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction > 0 ? rotateCircles() : stopCircles();
        }, `slide-2+=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction < 0 ? rotateCircles() : stopCircles();
        }, `slide-4-=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction > 0 ? pointerDotsAnimation.play() : pointerDotsAnimation.pause();
        }, `slide-2+=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction < 0 ? pointerDotsAnimation.play() : pointerDotsAnimation.pause();
        }, `slide-4-=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction > 0 ? panelTiraResponsive.play() : panelTiraResponsive.pause().seek(0);
        }, `slide-3+=0.01`)
        .add(() => {
            animationObj.scrollTrigger.direction < 0 ? panelTiraResponsive.play() : panelTiraResponsive.pause().seek(0);
        }, `slide-5-=0.01`);

    return animationObj;
}
