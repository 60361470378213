import React from "react";
import AwardsOnlyMobile from './components/awards/AwardsOnlyMobile';
import ServicesOnlyMobile from "./components/services/ServicesOnlyMobile";
import SuccessfulCasesOnlyMobile from './components/succesful-cases/SuccesfulCasesOnlyMobile';
import Hero from "./components/hero/hero";
import WhyUs from "./components/why-us/WhyUs";
import OurPartners from "./components/our-partners/Our-Partners";
import AboutUs from "./components/about-us/AboutUs";
import OurBlog from "./components/blog/Blog";
import WhereAreWe from "./components/where-are-we/WhereAreWe";

const SiteMobile = React.forwardRef((props,ref) => {
  return (
    <div id="home" ref={ref}>
      <Hero id="home-section" />
      <WhyUs id="why-us-section" />
      <ServicesOnlyMobile id="services-section" />
      <AboutUs id="about-us-section" />
      <SuccessfulCasesOnlyMobile id="success-cases-section" />
      <OurPartners id="partners-section" />
      <AwardsOnlyMobile id="awards-section" />
      <OurBlog id="blog-section" />
      <WhereAreWe id="where-are-we-section" />
    </div>
  );
});

export default SiteMobile;