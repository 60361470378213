import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);

/*
 * Services Section High converting e-commerce animation control
 * Params q gsap selector utility for id and class scoping 
 */

export function responsiveSection2Slider(q) {
  let animationObj = gsap
    .timeline({
      scrollTrigger: {
        markers: false,
        trigger: "#responsive-services-section-2-slider-container",
        start: "center 45%",
        end: "2000px 45%",
        scrub: true,
        pin: "#responsive-services-section-2-slider-container",
        pinSpacing: true,
      },
    })
    .fromTo(
      q(".responsive-section-2-slider-wrapper-outter"),
      {
        scale: 1,
        transformOrigin: "left center",
        opacity: 1,
      },
      { scale: 1, opacity: 1, duration: 1.6 }
    )
    .fromTo(q(".responsive-section-2-slider-wrapper-inner"), { xPercent: 6.66 }, { xPercent: -80, duration: 10.2, ease: "none" }, "<")
    .fromTo(q(".slide-inner-wrapper-0 .desktop-frame-outter"), { xPercent: 10 }, { xPercent: 0, duration: 2.2 }, 0)
    .fromTo(q(".slide-inner-wrapper-1 .desktop-frame-outter"), { xPercent: 10 }, { xPercent: 0, duration: 2.2 }, 2)
    .fromTo(q(".slide-inner-wrapper-2 .desktop-frame-outter"), { xPercent: 10 }, { xPercent: 0, duration: 2.2 }, 4)
    .fromTo(q(".slide-inner-wrapper-3 .desktop-frame-outter"), { xPercent: 10 }, { xPercent: 0, duration: 2.2 }, 6)
    .fromTo(q(".slide-inner-wrapper-4 .desktop-frame-outter"), { xPercent: 10 }, { xPercent: 0, duration: 2.2 }, 8)
    .fromTo(q(".slide-inner-wrapper-0 .app-frame-outter"), { xPercent: 12 }, { xPercent: -6, duration: 2.2 }, 0)
    .fromTo(q(".slide-inner-wrapper-1 .app-frame-outter"), { xPercent: 12 }, { xPercent: -6, duration: 2.2 }, 2)
    .fromTo(q(".slide-inner-wrapper-2 .app-frame-outter"), { xPercent: 12 }, { xPercent: -6, duration: 2.2 }, 4)
    .fromTo(q(".slide-inner-wrapper-3 .app-frame-outter"), { xPercent: 12 }, { xPercent: -6, duration: 2.2 }, 6)
    .fromTo(q(".slide-inner-wrapper-4 .app-frame-outter"), { xPercent: 12 }, { xPercent: -6, duration: 2.2 }, 8);

  return animationObj;
}