import React from "react";

const defaultCSSClasses = "h2 xl:text-2xl whitespace-nowrap leading-tight panel-content-title title text-white font-light lg:text-3xl leading-tight mb-2";

const PanelContentTitleWrapper = ({ children, id = null, className = null, number = null, titlePreview = null }) => (
  <>
    {number && (
      <div className="pt-6 md:mt-0 responsive-title-preview text-md-larger font-semibold text-white panel-content-title-preview-wrapper md:hidden flex flex-row mb-6">
        <div className="panel-content-title-preview-number  pr-4">{number}</div>
        <p className={`panel-content-title-preview whitespace-nowrap`}>{titlePreview ? titlePreview : ""}</p>
      </div>
    )}
    <h3 id={id} className={` ${defaultCSSClasses} ${className ? ` ${className}` : ""}`}>
      {children}
    </h3>
  </>
);

export default PanelContentTitleWrapper;
