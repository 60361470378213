import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AwardsResponsivePanel from "./AwardsResponsivePanel";

const AwardsdResponsive = ({ awardSlides, className }) => {
  const [activePanel, setActivePanel] = React.useState(0);

  const AwardsAccordeon = useMemo(() => {
    return awardSlides.map((award, index) => (
      <AwardsResponsivePanel
        award={award}
        key={award.contentId}
        open={Boolean(activePanel === index)}
        close={() => {
          //console.log("colapse", index);
          setActivePanel(null);
        }}
        expand={() => {
          //console.log("expand", index);
          setActivePanel(index);
        }}
      />
    ));
  }, [awardSlides, activePanel]);

  const { t } = useTranslation(["common"]);
  return (
    <div className={`md:hidden awards-responsive-panel container relative z-10 space-y-6 pb-20 ${className}`}>
      <div className="flex flex-col w-full md:w-10/12 lg:w-6/12">
        <div className="w-full">
          <h2 className="text-gray-1 lg:pl-0 md:text-4xl">
            <div className="reveal-animation-title">{t("awards.title")}</div>
          </h2>
        </div>
      </div>
      {AwardsAccordeon}
    </div>
  );
};

export default AwardsdResponsive;
