import React, { useEffect } from "react";
import styled from "styled-components";
import SwiperCore, { Controller, EffectFlip, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import locationSliderControlArrow from "assets/flecha-location-slider.svg";
import { useTranslation } from "react-i18next";

import "swiper/swiper.scss";
import FadeIn from "../../../components/animations/FadeIn";

SwiperCore.use([Controller, EffectFlip, Pagination, Navigation]);

const Country = ({ active, children }) => {
  const className = "location-country text-dark-dark text-xl font-medium leading-none mb-3";
  return (
    <h3 className={className}>
      <span className="location-country-content">{children}</span>
    </h3>
  );
};
const City = ({ active, children }) => {
  const className = "location-city text-dark-dark text-md ";
  return (
    <p className={className}>
      <span className="location-city-content">{children}</span>
    </p>
  );
};
const Address = ({ children }) => {
  const className = "location-address text-dark-dark text-md mt-3 pr-2 lg:w-72 overflow-y-hidden";
  return (
    <div className={className}>
      <p className="location-address-content">{children}</p>
    </div>
  );
};

export default function LocationSlider({ locations }) {
  const { t } = useTranslation();
  const [controlledSwiper, setControlledSwiper] = React.useState(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const [readyToRender, setReadyToRender] = React.useState(false);

  /*   function nextSlide() {
    if (currentSlide < locations.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  } */

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    locations.forEach((location, index) => {
      if (window.location.href.indexOf(location.urlName.toLowerCase()) > -1) {
        setCurrentSlide(index);
      }
    });
  }, [locations]);

  React.useLayoutEffect(() => {
    setReadyToRender(true);
  }, []);

  /*   function goToSlide(slide) {
    if (Boolean(controlledSwiper)) {
      controlledSwiper.slideTo(slide);
    } else {
      console.warn("slider not initialized yet");
    }
  } */

  React.useEffect(() => {
    //controlledSwiper.slideTo(currentSlide)
    if (Boolean(controlledSwiper)) {
      //console.log("activeindex", controlledSwiper);
      //history.push(`/${locations[controlledSwiper.activeIndex].urlName}`);
    }
  }, [controlledSwiper]);
  return (
    <StyledDiv className="hidden xl:block">
      <FadeIn direction="right" className="location-slider ml-1/12 pl-1/12 2xl:mr-1/12 overflow-hidden">
        {readyToRender && (
          <React.Fragment>
            <div className="hidden location-slider-control flex flex-row justify-end mb-4">
              <div className="location-slider-control-next cursor-pointer transition-transform duration-300 transform hover:scale-110" onClick={() => controlledSwiper.slideNext()}>
                <img src={locationSliderControlArrow} className="h-3 w-3" alt={t("img alt")} />
              </div>
            </div>
            <Swiper
              loop={true}
              slidesPerView={5}
              scrollEventThrottle={16}
              onSliderMove={(e) => {
                //console.log("slider move", e.progress);
              }}
              onUpdate={(e) => {
                //console.log("update", e.progress);
              }}
              onScroll={(e) => {
                //console.log("scroll", e.progress);
              }}
              autoHeight={false}
              spaceBetween={16}
              initialSlide={currentSlide}
              onSlideChange={() => {}}
              speed={1000}
              modules={[Controller]}
              onSwiper={setControlledSwiper}>
              {locations.map((location, index) => (
                <SwiperSlide key={"location" + index}>
                  <div className="location-slide-content pointer-cursor">
                    <Country>{location.country}</Country>
                    <City>{location.city}</City>
                    <Address>{location.address}</Address>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </React.Fragment>
        )}
      </FadeIn>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  & .location-slider {
    --left-spacing: calc(100% / 12);
    & .swiper-container {
      min-height: 12rem;
      overflow: visible;
      & .swiper-slide {
        max-width: calc((100% / 4) - 16px) !important;
      }
      & .location-city {
        margin-bottom: 2rem;
      }
      & .location-slide-content {
        transition-property: transform;
        transition-duration: 1s;
        margin-top: 2rem;
        margin-left: 0;
      }
      & .location-address {
        height: 8rem;
        & .location-address-content {
          transition-property: transform, opacity;
          transition-duration: 1s;
          transform: translatey(-100%);
          opacity: 0;
        }
      }
      & .swiper-slide-active {
        & .location-slide-content {
          transform: translate(-50%, -2rem);
        }
        & .location-country-content {
          font-size: 1.1em;
          color: white;
        }
        & .location-city-content {
          font-size: 1.1em;
          color: white;
        }
        & .location-city {
          color: white;
        }
        & .location-address {
          & .location-address-content {
            transform: translatey(0);
            opacity: 1;
          }
        }
      }
      & .swiper-slide-prev {
        opacity: 0;
      }
    }
  }
`;
