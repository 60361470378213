//import styled from "styled-components"; //permite crear estilos para este archivo
import { useTranslation } from "react-i18next";
import ModalContentWrapper from "components/full-size-modal/ModalContentWrapper";

export default function TermsAndConditions() {
  const { t } = useTranslation("common");

  const sections = [
    { title: t("tos.sections.title1"), content: t("tos.sections.content1") },
    { title: t("tos.sections.title2"), content: t("tos.sections.content2") },
    { title: t("tos.sections.title3"), content: t("tos.sections.content3") },
    { title: t("tos.sections.title4"), content: t("tos.sections.content4") },
    { title: t("tos.sections.title5"), content: t("tos.sections.content5") },
    { title: t("tos.sections.title6"), content: t("tos.sections.content6") },
  ];
  return (
    <ModalContentWrapper>
      <div className="text-white py-24">
        <h2 className="h1 mb-12">{t("tos.title")}</h2>
        <p className="h3">{t("tos.sub-title")}</p>
        <p className="whitespace-pre-line">{t("tos.content")}</p>
        {/* Sections */}
        {sections.map((item) => (
          <div>
            <p className="h3 mt-12">{t(item.title)}</p>
            <p dangerouslySetInnerHTML={{ __html: t(item.content) }}></p>
          </div>
        ))}
      </div>
    </ModalContentWrapper>
  );
}
