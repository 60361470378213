import React, { useLayoutEffect, useEffect, Suspense } from "react";
import Loader from "./components/loader/Loader";
import { useTranslation } from "react-i18next";
import { useAppContext } from "./utilities/context";
import { FullSizeModal } from "./components/full-size-modal/FullSizeModal";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import { gsap } from "gsap";
import { SECTIONS } from "./constants/constants";
import { BrowserRouter, Routes, Route, useParams, Outlet } from "react-router-dom";
//import parallax from "./components/animations/section-parallax";
import { scrollIntoView, useSelector } from "./utilities/functions";
import { ResponsiveServicesAnimationSequence } from "./components/services/ResponsiveServiceSection/animations/complete-animation-sequence";
import { wideServicesAnimationSequence } from "./components/services/DesktopServicesSection/animations/wide-viewport-animation";

import SiteMobile from "./SiteMobile";
import SiteDesktop from "./SiteDesktop";
import HeaderBar from "components/Navigation/HeaderBar";
import Footer from "components/footer/footer";

const Calculator = React.lazy(() => import("./components/calculator/Calculator"));
const CampaignGrader = React.lazy(() => import("./components/campaignGrader"));

gsap.registerPlugin(ScrollTrigger);

const Site = () => {
  const { t } = useTranslation("common");
  const [q, ref] = useSelector();
  const { userOnMobile, openContactForm, openTermsConditions } = useAppContext();
  const { section } = useParams();
  const mainURL = window.location.pathname;

  useLayoutEffect(() => {
    //console.log("rerun use layout of app");

    function hideWhenOffViewPort(elements) {
      q(elements).forEach(function (elem) {
        ScrollTrigger.create({
          start: 0,
          end: "max",
          onUpdate: elementInViewport,
        });

        function elementInViewport() {
          if (!ScrollTrigger.isInViewport(elem)) {
            ////console.log("not in viewport")
            elem.classList.add("invisible");
          } else {
            elem.classList.remove("invisible");
            ////console.log("in viewport")
          }
        }
      });
    }

    function revealAnimationTitle(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });

        const splitTitle = new SplitText(elem, { type: "words,lines", linesClass: "overflow-hidden whitespace-pre-line leading-[1.13] -mb-[0.1em]", specialChars: ["\n"] });
        splitTimeline.from(splitTitle.words, { duration: 1, autoAlpha: 0, yPercent: 100, stagger: { amount: 0.1 }, overwrite: "auto" });
      });
    }
    function revealAnimationSubtitle(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });

        const splitTitle = new SplitText(elem, { type: "words,lines", linesClass: "overflow-hidden" });
        splitTimeline.from(splitTitle.words, { duration: 1, autoAlpha: 0, yPercent: 100, overwrite: "auto" });
      });
    }

    function revealFromBottom(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });
        splitTimeline.from(elem, { duration: 1, autoAlpha: 0, yPercent: 100, overwrite: "auto" });
      });
    }

    function revealFromTop(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });
        splitTimeline.from(elem, { duration: 1, autoAlpha: 0, yPercent: -100, overwrite: "auto" });
      });
    }

    function fadeInPlace(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });

        splitTimeline.from(elem, { duration: 1.2, autoAlpha: 0, overwrite: "auto" });
      });
    }
    function fadeInLeft(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });

        splitTimeline.from(elem, { duration: 1, autoAlpha: 0, x: -20, overwrite: "auto" });
      });
    }

    function fadeInRight(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });

        splitTimeline.from(elem, { duration: 1, autoAlpha: 0, x: 20, overwrite: "auto" });
      });
    }

    function fadeInBottom(elements) {
      q(elements).forEach(function (elem) {
        const splitTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 75%",
            toggleActions: "restart none none reverse",
            //markers: true,
          },
        });

        splitTimeline.from(elem, { duration: 1, autoAlpha: 0, y: 20, overwrite: "auto" });
      });
    }

    function setScrolledClass() {
      let animationObj = gsap.timeline({
        scrollTrigger: {
          trigger: q("#content"),
          id: "scrolled class",
          start: "top -15",
          end: "bottom+=100 bottom",
          toggleClass: { targets: `#content`, className: `scrolled` },
        },
      });
      return animationObj;
    }

    function setActiveSection(el, endEl = null) {
      ////console.log(endEl);
      let elID = `#${el}`;
      let STObj = ScrollTrigger.create({
        trigger: q(elID),
        endTrigger: endEl ? q(`#${endEl}`) : q(elID),
        id: elID,
        start: "top center",
        end: endEl ? "top center" : "bottom center+1",
        toggleClass: { targets: `#content`, className: `${el}-active` },
      });
      return STObj;
    }

    // Openning Contact Modal if URL is contact
    if (mainURL === "/contactanos" || mainURL === "/en/contact-us") {
      openContactForm();
    }
    // Openning Terms and Conditions
    if (mainURL === "/terminos-y-condiciones" || mainURL === "/en/terms-and-conditions") {
      openTermsConditions();
    }

    // Disable all animations

    ["home-section", "why-us-section"].forEach((section) => {
      hideWhenOffViewPort(`#${section} .hide-off-viewport`);
      revealAnimationTitle(`#${section} .reveal-animation-title`);
      revealAnimationSubtitle(`#${section} .reveal-animation-subtitle`);
      revealFromBottom(`#${section} .reveal-from-bottom`);
      revealFromTop(`#${section} .reveal-from-top`);
      fadeInRight(`#${section} .fade-in-right`);
      fadeInLeft(`#${section} .fade-in-left`);
      fadeInBottom(`#${section} .fade-in-bottom`);
      fadeInPlace(`#${section} .fade-in-place`);
    });
    if (userOnMobile()) {
      ResponsiveServicesAnimationSequence(q);
    } else {
      ScrollTrigger.matchMedia({
        "(max-width:767px)": () => ResponsiveServicesAnimationSequence(q),
        "(min-width: 768px)": () => wideServicesAnimationSequence(q),
      });
    }
    ["about-us-section", "success-cases-section", "partners-section", "awards-section", "blog-section", "where-are-we-section"].forEach((section) => {
      hideWhenOffViewPort(`#${section} .hide-off-viewport`);
      revealAnimationTitle(`#${section} .reveal-animation-title`);
      revealAnimationSubtitle(`#${section} .reveal-animation-subtitle`);
      revealFromBottom(`#${section} .reveal-from-bottom`);
      revealFromTop(`#${section} .reveal-from-top`);
      fadeInRight(`#${section} .fade-in-right`);
      fadeInLeft(`#${section} .fade-in-left`);
      fadeInBottom(`#${section} .fade-in-bottom`);
      fadeInPlace(`#${section} .fade-in-place`);
    });

    setScrolledClass();
    SECTIONS.forEach((section, index) => {
      if (SECTIONS[index + 1]) {
        setActiveSection(section.id, SECTIONS[index + 1].id);
      } else {
        setActiveSection(section.id);
      }
    });

    return () => {
      ScrollTrigger.getAll().forEach((st) => {
        st.kill();
      });
    };
  }, [q]);

  useLayoutEffect(() => {
    let targetID = SECTIONS.find((sectionURL) => t(sectionURL.to) === `/${section}`)?.id;
    console.log("section", targetID);
    if (targetID) {
      scrollIntoView(targetID);
      ScrollTrigger.refresh();
    }
  }, [section]);

  /*   useLayoutEffect(() => {
      setDomRendered(true);
      //   ScrollTrigger.config({
      //  autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
      // });
      ScrollTrigger.refresh({ safe: true });
      window.addEventListener("resize", () => ScrollTrigger.refresh({ safe: true }));
      return () => {
        //window.removeEventListener("resize", ScrollTrigger.refresh());
      };
    }, [setDomRendered]); */

  //console.log("useronmobile", userOnMobile());
  if (userOnMobile()) return <SiteMobile ref={ref} />;
  if (!userOnMobile()) return <SiteDesktop ref={ref} />;
};

function App() {
  const { baseURL, baseURLParams } = useAppContext();
  const { i18n, t } = useTranslation("common"); //this is magic dont touch if removed scrolltrigger pin section stops working <-- hahaha seriously? >.<
  // const recaptchaBadge = document.querySelector(".grecaptcha-badge");
  // recaptchaBadge.classList.add("-right-36 invisible");
  // console.log(recaptchaBadge);

  useEffect(() => {
    // console.log("baseurl updated", baseURL);
    // let language = window.location.pathname.split("/")[1];
    i18n.changeLanguage(Boolean(baseURLParams?.language) ? baseURLParams.language : "es");
  }, [baseURLParams]);

  useLayoutEffect(() => {
    let mouseCursor = "#mousecursor";
    gsap.set(mouseCursor, { xPercent: -50, yPercent: -50 });
    // //console.log(anchor);

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.2;
    const posInner = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouseInner = { x: posInner.x, y: posInner.y };
    const speedInner = 0.4;

    const xSet = gsap.quickSetter("#mousecursor", "x", "px");
    const ySet = gsap.quickSetter("#mousecursor", "y", "px");
    const xSetInner = gsap.quickSetter("#mousecursorinner", "x", "px");
    const ySetInner = gsap.quickSetter("#mousecursorinner", "y", "px");

    window.addEventListener("mousemove", (e) => {
      mouse.x = e.x;
      mouse.y = e.y;
      mouseInner.x = e.x;
      mouseInner.y = e.y;
    });

    gsap.ticker.add(() => {
      // adjust speed for higher refresh monitors
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
      const dtInner = 1.0 - Math.pow(1.0 - speedInner, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;

      posInner.x += (mouseInner.x - posInner.x) * dtInner;
      posInner.y += (mouseInner.y - posInner.y) * dtInner;

      xSet(pos.x);
      ySet(pos.y);

      xSetInner(posInner.x);
      ySetInner(posInner.y);
    });
  }, []);
  return (
    <div id="content" className="relative">
      <BrowserRouter basename={baseURL}>
        <Suspense fallback={<Loader />}>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Site />} />
                <Route path={"calculadora"} element={<Calculator />}>
                  <Route path=":calculatorMetric" element={<Calculator />} />
                </Route>
                <Route path={"calculator"} element={<Calculator />}>
                  <Route path=":calculatorMetric" element={<Calculator />} />
                </Route>
                <Route path=":section" element={<Site />} />
                <Route path="google-grader" element={<CampaignGrader />} />
              </Route>
            </Routes>
          </Suspense>
          <FullSizeModal />
        </Suspense>
      </BrowserRouter>

      <div id="mousecursor" className="mousecursor hidden lg:block cursor-outer"></div>
      <div id="mousecursorinner" className="mousecursor hidden lg:block cursor-inner w-1 h-1 rounded-full bg-white"></div>
    </div>
  );
}

const Layout = () => (
  <React.Fragment>
    <HeaderBar />
    <Outlet />
    <Footer />
  </React.Fragment>
);

export default App;
