import { useEffect } from "react";
import styled from "styled-components";
import { useAppContext } from "utilities/context";
//import { useSelector } from "utilities/functions";
import LocaleBar from "./LocaleBar";
//import MobileMenu from "./mobile-menu";

export const MenuToggler = () => {
  const { isModalOpen, openMenu, closeModal } = useAppContext();

  useEffect(() => {});
  return (
    <StyledDiv id="menu-toggle">
      <div onClick={!isModalOpen ? openMenu : closeModal} className={`${isModalOpen === true ? "close-action" : "open-action"} menu-toggle-button cursor-pointer`}>
        <span className="toggler-top-line block h-px w-2/3 " />
        <span className="toggler-middle-line block h-px w-full " />
        <span className="toggler-bottom-line block h-px w-2/3" />
      </div>
      <div className="menu-toggle-locale-changer hidden">
        <LocaleBar className="hidden lg:flex flex-col items-end space-y-2" localeClassName="px-0" />
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  & {
    --toggler-width: 2.5em;
    --distance-from-right: 1.5rem;
    filter: saturate(0) contrast(2);
    mix-blend-mode: exclusion;
    transition: all 1s ease 0s;
    position: fixed;
    top: 2.75rem;
    z-index: 50000;
    margin-right: var(--distance-from-right);
    right: 0;
    font-size: 1em;
    width: var(--toggler-width);
    height: 1rem;
    border-radius: 100em;
    color: #ffffff;
    @media screen and (min-width: 768px) {
      top: 4rem;
    }
    .menu-toggle-button {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;
      height: 1rem;
      margin-bottom: 1rem;
      z-index: 50000;
      position: relative;
    }
    .menu-toggle-button span {
      transition: 0.5s;
      background: currentColor;
      position: relative;
      transform-origin: center;
    }
    .menu-toggle-button:hover {
      .toggler-middle-line {
        @media screen and (min-width: 1024px){}
          transform: translate(-0.8rem, 0);
        }
      }
    }
    .close-action {
      & .toggler-top-line {
        transform: rotate(45deg) translate(0, 0.7rem);
      }
      & .toggler-middle-line {
        transform: scale(0);
        opacity: 0;
      }
      & .toggler-bottom-line {
        transform: rotate(-45deg) translate(0, -0.7rem);
      }
    }
    .close-action:hover {
      .toggler-middle-line {
        opacity: 0;
      }
      .toggler-bottom-line {
        transform: rotate(-45deg) translate(0, -0.7rem) scale(1.2, 1);
      }
      .toggler-top-line {
        transform: rotate(45deg) translate(0, 0.7rem) scale(1.2);
      }
    }
    &.light {
      color: #171717;
      .menu-toggle-button span {
        background: currentColor;
      }
    }
    @media screen and (min-width: 1024px) {
      right: calc((var(--toggler-width) + var(--distance-from-right)) * -1);
      opacity: 1;
    }
  }
`;
