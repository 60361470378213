import avatarOscar from "assets/contact-us/avatar-oscar-alvarez.webp";
import { useTranslation } from "react-i18next";

const AvatarPresidente = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center text-white mt-8 mb-20">
      <div className="pr-4">
        <img src={avatarOscar} alt="Oscar Álvarez" className="h-16 w-16 object-contain" />
      </div>
      <div>
        <div className="flex flex-col justify-between h-full">
          <div className="text-white font-semibold text-md">{t("contact-us:transformamos tu negocio")}</div>
          <div className="font-light text-base text-white leading-tight lg:whitespace-nowrap">
            <span className="block lg:inline-block">Oscar Álvarez</span> <span className="hidden lg:inline-block">&nbsp;|&nbsp;</span>
            <span className="block lg:inline-block">{t("contact-us:ceo")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarPresidente;
