import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/all";
import { gsap } from "gsap";
import { useMemo, useRef } from "react";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

/* export function addToRefArray({ element, refArray }) {
  if (!Array.isArray(refArray.current) && !Array.isArray(refArray)) {
    console.error("Ref not of type array");
    return false;
  }
  let array = Array.isArray(refArray) ? refArray : refArray.current;
  if (element && !array.includes(element)) {
    array.push(element);
    ////console.log(element, "added to RefArray")
    return true;
  } else {
    console.warn(element, " already in Ref Array");
    return false;
  }
} */

// export function scrollIntoView(id) {
export function scrollIntoView(id, offset = 0, duration = 2) {
  let currentY = window.scrollY;
  let targetY = document.getElementById(id)?.offsetTop
  if (Boolean(targetY)) {
    if (["desktop-services-section-1", "desktop-services-section-2", "desktop-services-section-3", "desktop-services-section-4", "desktop-services-section-5"].includes(id)) {
      if (currentY > targetY) {
        offset = offset - 15;
      } else {
        offset = offset + 15;
      }
    }

    //console.log("current", currentY, "target", targetY);
    gsap.to(window, {
      duration: duration,
      scrollTo: { y: `#${id}`, offsetY: offset },
      autoKill: true /* ,
    onComplete: () => {
      gsap.to(window, { duration: 0, scrollTo: { y: `#${id}`, offsetY: offset + 1 }, autoKill: true });
    }, */,
    });
    // gsap.to(window, {duration: 2, scrollTo:`#${id}`, autoKill: true})
  }
}

export function useSelector() {
  const ref = useRef();
  const q = useMemo(() => gsap.utils.selector(ref), [ref]);
  return [q, ref];
}
