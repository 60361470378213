import React, { useLayoutEffect } from "react"; //importamos react
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import { gsap } from "gsap";
import { EASE_OUT_DEFAULT, LINE_REVEAL_ANIMATION } from "constants/constants";
import { useSelector } from "utilities/functions";
import { AnimatedRevealHeadingLine } from "../common/AnimatedHeading";
import LocaleBar from "../Navigation/components/LocaleBar";
//import { useAppContext } from "utilities/context";
import isotipo from "assets/images/isotipo.png";
import heroImage from "assets/images/woman-header-17sep.webp";
import animacionPierna from "assets/pierna2.gif";
import ContactUsButton from "../Navigation/components/ContactUsButton";

export default function Hero({ id = null }) {
  const { t } = useTranslation("common");
  const [q, ref] = useSelector();

  useLayoutEffect(() => {
    const initialAnimation = gsap.timeline(); //Hero elements animations
    const defaultHeroImageAnimationObj = {
      autoAlpha: 0,
      duration: 2.5,
      y: 20,
      ease: EASE_OUT_DEFAULT,
    };

    initialAnimation
      .from(q(".hero-title"), {
        ...LINE_REVEAL_ANIMATION,
        stagger: {
          each: 0.26,
        },
      })
      .from(
        q(".hero-subtitle"),
        {
          ...LINE_REVEAL_ANIMATION,
          duration: 0.8,
          stagger: {
            each: 0.1,
          },
        },
        "-=0.6"
      )
      .from(
        q(".hero-subtitle"),
        {
          opacity: 0,
          duration: 0.8,
          stagger: {
            each: 0.1,
          },
        },
        "-=0.4"
      )
      .from(
        q("#hero-image"),
        {
          ...defaultHeroImageAnimationObj,
        },
        "-=2"
      )
      .from(
        q("#hero-image-background"),
        {
          ...defaultHeroImageAnimationObj,
          x: "-20",
        },
        "-=2.5"
      );
    /*       .from(
        q("#responsive-contact-button"),
        {
          autoAlpha: 0,
          y: "15",
          duration: 0.5,
          ease: EASE_OUT_DEFAULT,
        },
        "-=0.5"
      )
      .from(
        q("#locale-bar-wrapper"),
        {
          autoAlpha: 0,
          y: "15",
          duration: 0.5,
          ease: EASE_OUT_DEFAULT,
        },
        "-=0.5"
      ) */

    initialAnimation.play();

    return () => {
      initialAnimation.kill();
    };
  }, [q]);

  return (
    <StyledDiv id={id} ref={ref} className="bg-primary">
      <div className="container pt-32 md:pt-56 lg:py-0 flex flex-col-reverse lg:flex-row justify-start items-center relative lg:min-h-750 lg:mx-auto pb-24 lg:pb-0  text-gray-11">
        <div id="locale-bar-wrapper" className="container relative z-50 flex flex-row justify-center lg:block lg:absolute text-gray-11 lg:-right-0 lg:-bottom-0 pb-1/12 lg:text-right">
          <LocaleBar />
        </div>
        <div className="flex justify-center lg:hidden mb-10 -mt-8 relative z-50">
          <ContactUsButton />
        </div>
        <div id="hero-image-container" className="hero-image-container relative lg:absolute lg:top-0 pointer-events-none ">
          <div id="hero-image" className="relative invisible">
            <img src={isotipo} alt="Agencia de Marketing Digital de Performance" title="Performly Logo" className="w-full max-w-none" />
          </div>
          <div id="hero-image-background" className="absolute top-0">
            <img src={heroImage} alt={t("img-alts.roi-driven-digital-marketing-agency")} className="w-full max-w-none" />
            {/* <Lottie animationData={pierna} className="hidden lg:block lottie absolute w-1/2" /> */}
          </div>
          <div id="animacion-pierna" className="absolute">
            <img src={animacionPierna} alt="" className="animated-gif hide-off-viewport" />
          </div>
        </div>
        <div className="hero-content  relative w-full lg:w-1/2 ">
          <div className="h1 font-light lg:font-normal text-3xl sm:text-4xl text-dark-head lg:text-7xl mb-6 lg:mb-8">
            <Trans i18nKey="hero.title">
              <AnimatedRevealHeadingLine className="hero-title">{t("hero.title")}</AnimatedRevealHeadingLine>
            </Trans>
          </div>
          <AnimatedRevealHeadingLine className="hero-subtitle">
            <h1 className="subtitle text-dark-medium font-light text-lg lg:text-xl">{t("hero.subtitle")}</h1>
          </AnimatedRevealHeadingLine>
        </div>
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  & .lottie {
    left: 50%;
    top: 50%;
    transform: translate(-65%, -36%);
  }
  & #animacion-pierna {
    left: 50%;
    top: 50%;
    transform: translate(-66%, -34.5%);
  }
  & .hero-image-container > *:after {
    @media screen and (max-width: 1023px) {
      position: absolute;
      content: "";
      display: block;
      bottom: 0;
      top: 40%;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(23, 23, 23, 0) 0%, rgba(23, 23, 23, 1) 68%);
    }
  }
  & .hero-image-container {
    @media (max-width: 767px) {
      transform: scale(1.36);
      width: 100%;
      & #hero-image {
        height: 0;
        padding-bottom: 89%;
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      transform: scale(1.3);
      width: 100%;
      & #hero-image {
        height: 0;
        padding-bottom: 89%;
      }
    }
    @media (min-width: 1023px) and (max-width: 1439px) {
      top: 5%;
      right: 0;
      width: 73%;
    }

    @media (min-width: 1440px) {
      top: 5%;
      right: 13%;
      width: 65.5%;
    }
    @media (min-width: 1920px) {
      right: calc(100% / 5);
      width: 52%;
    }
  }
  .contact-us-button {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(54, 54, 54, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(54, 54, 54, 1) 100%);
  }
`;
