import React from "react";

import Awards from "components/awards/Awards";
import Services from "components/services/Services";
import SuccessfulCases from "components/succesful-cases/SuccesfulCases";
import Hero from "./components/hero/hero";
import WhyUs from "./components/why-us/WhyUs";
import OurPartners from "./components/our-partners/Our-Partners";
import AboutUs from "./components/about-us/AboutUs";
import OurBlog from "./components/blog/Blog";
import WhereAreWe from "./components/where-are-we/WhereAreWe";

const SiteDesktop = React.forwardRef((props,ref) => {
  return (
    <div id="site" ref={ref}>
      <Hero id="home-section" />
      <WhyUs id="why-us-section" />
      <Services id="services-section" />
      <AboutUs id="about-us-section" />
      <SuccessfulCases id="success-cases-section" />
      <OurPartners id="partners-section" />
      <Awards id="awards-section" />
      <OurBlog id="blog-section" />
      <WhereAreWe id="where-are-we-section" />
    </div>
  );
});

export default SiteDesktop;
