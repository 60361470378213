import React from "react";
import styled from "styled-components";
import { ReactComponent as OpenModalCross } from "assets/open-modal-cross.svg";

const defaultCSSClasses = `
responsive-panel-content-wrapper`;

const PanelContentWrapper = ({ children, id = null, className = null, openModal = null }) => {
  return (
    <StyledDiv id={id} className={`${defaultCSSClasses} ${className ? ` ${className}` : ""} container`}>
      <div className="relative">
        {children}
        <div onClick={openModal} className="expand-toggle text-white pointer-events-auto cursor-pointer font-light text-3xl lg:hidden absolute">
          <OpenModalCross className="open-modal-cross h-8 w-8" />
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  & {
    @media screen and (max-width: 767px) {
      padding-bottom: 10rem;
      position: relative;
    }
  }
  .expand-toggle {
    @media screen and (min-width: 768px) {
      display: none;
    }
    bottom: 2.3rem;
    right: 0rem;
  }
`;

export default PanelContentWrapper;
