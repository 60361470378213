import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);

/*
 * Services Section Digital Marketing animation control
 * Params q gsap selector utility for id and class scoping 
 */


export function responsiveSection3(q) {
  function rotatingCircle(el, duration = 4) {
    return gsap.timeline().to(el, { rotate: 360, duration: duration }).repeat(-1).paused(true);
  }
  
  function rotateCircles() {
    circle1Animation.play();
    circle2Animation.play();
    circle3Animation.play();
  }
  function stopCircles() {
    circle1Animation.pause();
    circle2Animation.pause();
    circle3Animation.pause();
  }
  
  function pointerDots(el) {
    return gsap
      .timeline()
      .fromTo(
        q(el),
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 1.5,
        }
      )
      .fromTo(
        q(el),
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 1,
        },
        "-=0.5"
      )
      .repeat(-1)
      .paused(true);
  }

  let circle1Animation = rotatingCircle(q(".rotating-1"), 6);
  let circle2Animation = rotatingCircle(q(".rotating-2"), 4);
  let circle3Animation = rotatingCircle(q(".rotating-3"), 5);

  let responsivePointerDotsAnimation = pointerDots("#services-responsive-version .ring-wrapper");
  
  function sectionAnimation(q) {
    let animationObj = gsap
      .timeline({
        scrollTrigger: {
          markers: false,
          trigger: "#responsive-services-section-3-images-container",
          scrub: true,
          start: "top 80%",
          end: "center 20%",
          /* pinType: 'fixed',
          start: "center 45%",
          end: "1000px 45%",
          anticipatePin: 2,
          pin: "#responsive-services-section-3-images-container",
          pinSpacing: true, */
        },
      })
      .fromTo(q(".responsive-section-3-background-wrapper-outter"), { xPercent: 33 }, { xPercent: 0, duration: 10 })
      .fromTo(q(".responsive-section-3-background-wrapper-outter"), { borderRadius: "16px" }, { borderRadius: 0, duration: 4 }, 6)
      .fromTo(q(".responsive-section-3-dashboard-wrapper-outter"), { xPercent: 20, scale: 1.25, opacity: 0 }, { xPercent: 0, scale: 1, duration: 4, opacity: 1 }, 2)
      .fromTo(
        q(".responsive-section-3-woman-container-outter"),
        {
          xPercent: 50,
          opacity: 0,
        },
        { xPercent: 0, opacity: 1, duration: 4 },
        1
      )
      .fromTo(
        q(".responsive-section-3-circles-wrapper-inner > *"),
        {
          scale: 1.2,
          transformOrigin: "center center",
          opacity: 0,
        },
        { scale: 1, opacity: 1, duration: 1, stagger: { each: 0.5 } },
        3.5
      )
      .add(() => {
        animationObj.scrollTrigger.direction > 0 ? rotateCircles() : stopCircles();
      }, `6.01`)
      .add(() => {
        animationObj.scrollTrigger.direction < 0 ? rotateCircles() : stopCircles();
      }, `5.99`) 
      .add(() => {
        animationObj.scrollTrigger.direction > 0 ? responsivePointerDotsAnimation.play() : responsivePointerDotsAnimation.pause();
      }, `4.01`)
      .add(() => {
        animationObj.scrollTrigger.direction < 0 ? responsivePointerDotsAnimation.play() : responsivePointerDotsAnimation.pause();
      }, `3.99`);
    return animationObj;
  }
  function cleanUp(q) {
    let animationObj = gsap
      .timeline({
        scrollTrigger: {
          markers: false,
          id: "services 3 clean up",
          trigger: "#responsive-services-section-3-images-container",
          start: "bottom 25%",
          end: "bottom 24%",
          scrub: true,
        },
      })
      .add(() => {
        animationObj.scrollTrigger.direction > 0 ? rotateCircles() : stopCircles();
      }, 0)
      .add(() => {
        animationObj.scrollTrigger.direction < 0 ? rotateCircles() : stopCircles();
      }, 0.1)
      .add(() => {
        animationObj.scrollTrigger.direction > 0 ? responsivePointerDotsAnimation.play() : responsivePointerDotsAnimation.pause();
      }, 0)
      .add(() => {
        animationObj.scrollTrigger.direction < 0 ? responsivePointerDotsAnimation.play() : responsivePointerDotsAnimation.pause();
      }, 0.1);
    return animationObj;
  }
  sectionAnimation(q)
  cleanUp(q)
}