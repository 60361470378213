import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);

/*
 * Services Section B2B Marketing animation control
 * Params q gsap selector utility for id and class scoping 
 */

export function responsiveSection5(q) {
  let animationObj = gsap
    .timeline({
      scrollTrigger: {
        markers: false,
        id: "services-ecommerce-slider",
        trigger: "#responsive-services-section-5-images-container",
        scrub: true,
        start: "top 80%",
        end: "center 20%",
/*         start: "center 45%",
        end: "1400px 45%",
        pin: "#responsive-services-section-5-images-container",
        pinSpacing: true, */
      },
    })
    .fromTo(q(".responsive-section-5-wrapper-outter"), { xPercent: 33 }, { xPercent: 0, duration: 10 })
    .fromTo(q(".responsive-section-5-background-wrapper-outter"), { borderRadius: "16px" }, { borderRadius: 0, duration: 4 }, "<+=6")
    .fromTo(q(".responsive-section-5-person-wrapper-inner"), { xPercent: 20, opacity: 0 }, { xPercent: 0, duration: 4, opacity: 1 }, "2")
    .fromTo(q(".responsive-section-5-database-wrapper-inner"), { scale: 1.1, opacity: 0 }, { opacity: 1, duration: 3 }, "4")
    .fromTo(q(".responsive-section-5-database-wrapper-inner"), { yPercent: 0, opacity: 1 }, { yPercent: -40, opacity: 0, duration: 4 }, "+=2")
    .fromTo(q(".responsive-section-5-profile-wrapper-inner"), { scale: 1.1, opacity: 0 }, { opacity: 1, duration: 3 }, "<+=2")
    .fromTo(q(".responsive-section-5-profile-wrapper-inner"), { yPercent: 0, opacity: 1 }, { yPercent: -40, opacity: 0, duration: 8 }, "+=2")
    .fromTo(q(".responsive-section-5-landing-wrapper-inner"), { scale: 1.1, opacity: 0 }, { opacity: 1, duration: 3 }, "<+=2").delay(2)
    .delay(2);

  return animationObj;
}