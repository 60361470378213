import PanelContentBodyWrapper from "../PanelContentBodyWrapper";
import PanelContentFooterWrapper from "../PanelContentFooterWrapper";
import PanelContentSubtitleWrapper from "../PanelContentSubTitleWrapper";
import PanelContentTitleWrapper from "../PanelContentTitleWrapper";
import PanelContentWrapper from "../PanelContentWrapper";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import panel3GoogleLogo from "assets/digital-marketing/google-logo@1x.svg";
import panel3InstagramLogo from "assets/digital-marketing/instagram-logo@1x.svg";
import panel3LinkedInLogo from "assets/digital-marketing/linkedin-logo@1x.svg";
import panel3AmazonAdsLogo from "assets/digital-marketing/amazon-ads-logo@1x.svg";
import panel3DV360Logo from "assets/digital-marketing/dv360-logo@1x.svg";

const DigitalMarketingContent = ({ id = null, expandedPanel, setExpandedPanel }) => {
  const { t } = useTranslation(["common"]);
  return (
    <StyledDiv>
      <PanelContentWrapper id="panel-3-content" setExpandedPanel={setExpandedPanel} expandedPanel={expandedPanel}>
        <PanelContentTitleWrapper number={3} titlePreview={t("services.digital marketing campaign.title-preview")}>
          {" "}
          <Trans i18nKey="services.digital marketing campaign.title">
            <span className="block">
              <span className="block">digital marketing</span>
              <span className="block whitespace-nowrap">campaign</span>
            </span>
          </Trans>
        </PanelContentTitleWrapper>
        <PanelContentSubtitleWrapper>{t("services.digital marketing campaign.subtitle")}</PanelContentSubtitleWrapper>
        <PanelContentBodyWrapper>
          <Trans i18nKey="services.digital marketing campaign.content">
            <span className="block mb-6">Los servicios de optimización de la tasa de conversión</span>
            <span className="block md:font-semibold md:mb-4"> examinan cada paso de sus esfuerzos de ventas y conocen las debilidades de su embudo de ventas. Una vez que convierta esas debilidades en fortalezas, encontrará más clientes realizando compras que nunca.</span>
          </Trans>
        </PanelContentBodyWrapper>
        <PanelContentFooterWrapper>
          <h4 className="uppercase whitespace-pre-line text-sm pr-6 underline-decoration">{t("services.digital marketing campaign.our-partners")}</h4>
          <div>
            <img src={panel3GoogleLogo} alt="Google logo" title="Google Ads" className="w-auto h-9 object-contain" />
          </div>
          <div>
            <img src={panel3InstagramLogo} alt="Instagram logo" title="Instagram Ads" className="w-auto h-9 object-contain" />
          </div>
          <div>
            <img src={panel3LinkedInLogo} alt="LinkedIn logo" title="LinkedIn Ads" className="w-auto h-9 object-contain" />
          </div>
          <div>
            <img src={panel3AmazonAdsLogo} alt="Amazon Ads logo" title="Amazon Ads" className="w-auto h-12 object-contain" />
          </div>
          <div>
            <img src={panel3DV360Logo} alt="DV360 logo" title="DV360 - Video 360" className="w-auto h-9 object-contain" />
          </div>
        </PanelContentFooterWrapper>
        <div className="text-gray-500">
          <p>{t("services.digital marketing campaign.footer-text")}</p>
        </div>
      </PanelContentWrapper>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  #panel-3-content span {
    //font-size: 1.7rem;
    //font-weight: 600;
  }
`;

export default DigitalMarketingContent;
