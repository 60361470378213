import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);

/*
 * Services Section Marketplace Performance animation control
 * Params q gsap selector utility for id and class scoping 
 */

export function responsiveSection4(q) {
  let animationObj = gsap
    .timeline({
      scrollTrigger: {
        markers: false,
        trigger: "#responsive-services-section-4-images-container",
        scrub: true,
        start: "top 80%",
        end: "center 30%",
/*         start: "center 45%",
        end: "1000px 45%",
        pin: "#responsive-services-section-4-images-container",
        pinSpacing: true, */
      },
    })
    .fromTo(q(".responsive-section-4-wrapper-outter"), { xPercent: 33 }, { xPercent: 0, duration: 10 })
    .fromTo(q(".responsive-section-4-background-wrapper-outter"), { borderRadius: "16px" }, { borderRadius: 0, duration: 4 }, "<+=6")
    .fromTo(q(".responsive-section-4-stripe-wrapper-outter"), { xPercent: 20, scale: 1.25, opacity: 0 }, { xPercent: 0, scale: 1, duration: 10, opacity: 1 }, "0")
    .fromTo(q(".panel-4-tira-responsive"), { yPercent: 100 }, { yPercent: 20, duration: 10 }, 2);

  return animationObj;
}