import React from "react"; //importamos react
import oscar_grande from "assets/images/Oscar-grande.webp";
import oscar_mobile from "assets/images/Oscar-mobile.webp";
import { BLOG_URL } from "constants/constants";
import AboutModal from "./AboutUsModal";

export default function OscarInfo() {
  const data = {
    photo: oscar_grande,
    photoResponsive: oscar_mobile,
    title: "about-us.oscar.title",
    content: "about-us.oscar.content",
    category: "about-us.oscar.category",
    blogLink: BLOG_URL,
    linkedIn: "https://www.linkedin.com/in/oscaralvarezvzla/?originalSubdomain=pa",
    cta: "about-us.oscar.cta",
  };

  return <AboutModal data={data} />;
}
