import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentWrapper";
import { useTranslation } from "react-i18next";
import { useAppContext } from "utilities/context";

const PerformanceConsultingContent = () => {
  const { t } = useTranslation(["common"]);
  const { openPerformanceConsultingModal } = useAppContext();
  return (
    <PanelContentWrapper id="panel-1-content" openModal={openPerformanceConsultingModal}>
      <PanelContentTitleWrapper number={1}>{t("services.performance consulting.title-preview")}</PanelContentTitleWrapper>
      <PanelContentSubtitleWrapper>{t("services.performance consulting.subtitle responsive")}</PanelContentSubtitleWrapper>
    </PanelContentWrapper>
  );
};

export default PerformanceConsultingContent;
