import PanelContentBodyWrapper from "../../components/ResponsiveContentBodyWrapper";
import PanelContentFooterWrapper from "../../components/ResponsiveContentFooterWrapper";
import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentModal";
import { useTranslation, Trans } from "react-i18next";
import panel2CanalPanamaLogo from "assets/high-converting-ecommerce/canal-de-panama-logo@1x.webp";
import panel2CamperoLogo from "assets/high-converting-ecommerce/pollo-campero-logo@1x.webp";
import panel2SamsungLogo from "assets/high-converting-ecommerce/samsung-logo@1x.webp";
import panel2DigicelLogo from "assets/high-converting-ecommerce/digicel-logo@1x.webp";

const HighPerformanceEcommerceContent = () => {
  const { t } = useTranslation(["common"]);

  return (
    <PanelContentWrapper id="panel-2-content">
      <PanelContentTitleWrapper number={2}>
        {" "}
        <Trans i18nKey="common:services.high converting ecommerce.title">
          <span className="block 2xl:whitespace-pre-wrap">
            <span className="block 2xl:inline-block">high performance</span>
            <span className="block 2xl:inline-block whitespace-nowrap">e-commerce</span>
          </span>
        </Trans>
      </PanelContentTitleWrapper>
      <PanelContentSubtitleWrapper className="whitespace-pre-line 2xl:whitespace-pre-line">{t("services.high converting ecommerce.subtitle responsive")}</PanelContentSubtitleWrapper>
      <PanelContentBodyWrapper>
        <Trans i18nKey="common:services.high converting ecommerce.content">
          <span className="block mb-6 text-lg whitespace-normal font-semibold">Los servicios de </span>
          <span className="block">optimización de la tasa de conversión</span>
        </Trans>
      </PanelContentBodyWrapper>
      <div className="uppercase whitespace-nowrap text-sm pt-12 underline-decoration text-white">{t("services.high converting ecommerce.our partners")}</div>
      <PanelContentFooterWrapper>
        <div>
          <img src={panel2SamsungLogo} alt="Samsung logo" className="w-auto h-auto object-contain pr-2" />
        </div>
        <div>
          <img src={panel2DigicelLogo} alt="Digicel logo" className="w-auto h-auto object-contain" />
        </div>
        <div>
          <img src={panel2CanalPanamaLogo} alt="Canal de Panama logo" className="w-auto h-auto object-contain pr-2" />
        </div>
        <div>
          <img src={panel2CamperoLogo} alt="Campero logo" className="w-auto h-9 object-contain pr-2" />
        </div>
      </PanelContentFooterWrapper>
    </PanelContentWrapper>
  );
};

export default HighPerformanceEcommerceContent;
