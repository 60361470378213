import React, { useRef } from "react"; //importamos react
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import Container from "../Container";
import fb from "assets/fb.svg";
import ig from "assets/ig.svg";
import lk from "assets/lk.svg";
import tw from "assets/tw.svg";
import pt from "assets/pt.svg";
import googleAdsLogo from "assets/campaignGrader/googleAdsLogo.png"
import campaignGraderLogo from "assets/campaignGrader/campaignGraderLogo.png"
import LogoLight from "assets/logo-light.svg";
import { Collapse } from "@material-ui/core";
import { ReactComponent as Isotipo } from "assets/isotipo logo.svg";
import { useAppContext } from "utilities/context";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { SECTIONS } from "constants/constants";
import FooterLink from "./components/FooterLink";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

export default function Footer() {
  const isotypeHeart = useRef(null);
  const { openContactForm, openTermsConditions, openSitemap } = useAppContext();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const collapseRef = useRef(null);
  const BLOG_URL = "https://www.perform-ly.com/blog/";
  const location = useLocation()
  function toggleFooter() {
    !isExpanded ? expandFooter() : closeFooter();
  }
  // const [q, ref] = useSelector();
  React.useLayoutEffect(() => {
    gsap.to(isotypeHeart.current, { duration: 3.5, ease: "elastic.out(1,0.3)", scale: 1.4, repeat: -1 });
  }, []);
  function expandFooter() {
    setIsExpanded(true);
    let repeatIntervalUntil = parseInt(collapseRef.current.style.transitionDuration);
    repeatIntervalUntil = isNaN(repeatIntervalUntil) ? 300 : repeatIntervalUntil;
    let counter = 0;
    let frecuency = 10;
    ////console.log("repeatIntervalUntil",collapseRef.current.style.transitionDuration," counter",counter, " frecuency", frecuency)

    let intervalID = setInterval(() => {
      counter = counter + repeatIntervalUntil / frecuency;
      window.scrollTo(0, document.body.scrollHeight + 300);
      //console.log(counter);
      if (counter > repeatIntervalUntil * 2) {
        //console.log("clearing interval");
        clearInterval(intervalID);
      }
    }, frecuency);
  }

  function closeFooter() {
    setIsExpanded(false);
  }

  const { t } = useTranslation("common");
  const aboutus = [
    { label: t("footer.column-0.servicios"), to: t(SECTIONS[2].to) },
    { label: t("footer.column-0.quienes somos"), to: t(SECTIONS[3].to) },
    { label: t("footer.column-0.casos de exito"), to: t(SECTIONS[4].to) },
    { label: t("footer.column-0.donde estamos"), to: t(SECTIONS[8].to) },
    { label: t("footer.column-0.contacto"), onClick: openContactForm },
    { label: t("footer.column-0.whiteLabel"), href: "https://perform-ly.com/white-label-marketing", target: "_blank" },
    { label: t("footer.column-0.seo"), href: `https://perform-ly.com/agencia-seo`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-0.diseno"), href: `https://perform-ly.com/diseno-ux-ui`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-0.terminos y condiciones"), onClick: openTermsConditions, className: "lg:hidden" },
  ];
  const mostread = [
    /*{ label: t("footer.column-1.ecommerce"), href: `${BLOG_URL}index.php/category/ecommerce-es/`, target: "_blank" },
    { label: t("footer.column-1.performance marketing"), href: `${BLOG_URL}index.php/category/performance-marketing-es/`, target: "_blank" },
    { label: t("footer.column-1.lead generation"), href: `${BLOG_URL}index.php/category/lead-generation-es/`, target: "_blank" },
    { label: t("footer.column-1.martech"), href: `${BLOG_URL}index.php/category/martech-es/`, target: "_blank" },
    { label: t("footer.column-1.performly"), href: `${BLOG_URL}`, target: "_blank" },*/
    { label: t("footer.column-0.insights"), href: `${BLOG_URL}`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-1.ecommerce"), href: `${BLOG_URL}index.php/category/ecommerce-es/`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-1.performance marketing"), href: `${BLOG_URL}index.php/category/performance-marketing-es/`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-1.lead generation"), href: `${BLOG_URL}index.php/category/lead-generation-es/`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-1.martech"), href: `${BLOG_URL}index.php/category/martech-es/`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-1.performly"), href: `${BLOG_URL}index.php/category/marketing-digital/`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-1.seo"), href: `${BLOG_URL}index.php/category/seo/`, target: "_blank", rel: "noreferrer" },
  ];
  const terms = [
    { label: t("footer.column-2.politica de servicio"), onClick: openTermsConditions },
    { label: t("footer.column-2.politica de privacidad"), onClick: openTermsConditions },
    { label: t("footer.column-2.politica de reembolsos y cancelaciones"), onClick: openTermsConditions },
    { label: t("footer.column-2.politica de marcas y registros"), onClick: openTermsConditions },
    { label: t("footer.column-2.politica de cookies"), onClick: openTermsConditions },
    { label: t("footer.column-2.politica de mercadeo"), onClick: openTermsConditions },
    { label: t("footer.column-0.sitemap"), onClick: openSitemap },
  ];

  const tools = [
    { label: t("footer.column-3.google-grader"), href: `https://perform-ly.com/google-grader`, target: "_blank", rel: "noreferrer" },
    { label: t("footer.column-3.calculadora-roi-roas"), href: `https://perform-ly.com/calculadora/roi`, target: "_blank", rel: "noreferrer" },
  ]

  /*if(location.pathname === "/google-grader") {
    return (
      <footer className="bg-primary py-8 md:py-12 lg:py-16">
      <div className="border-gray-5 border-solid border-b md:border-0">
        <Container className="relative">
          <div className="flex justify-between items-center">
            <div className="pb-8">
              <img src={googleAdsLogo} className="lg:h-10 md:h-8 w-auto h-6" alt="google logo" />
            </div>
            <div className="border-gray-5 border-solid pb-8 border-0 md:border-b md:pl-14 lg:pl-24">
              <img src={campaignGraderLogo} className="lg:h-8 md:h-6 w-auto h-4" alt="Performly campaign grader logo" />
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container>
          <div className="mt-8 flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-xs font-normal md:text-sm text-white text-center md:text-left tracking-widest">PERFORMLY | A PART OF <a href="https://endavantgroup.com" target="_blank" rel="noopener" className="font-bold">ENDAVANT HOLDING GROUP</a></p>
            <SocialNetworks />
          </div>
        </Container>
      </div>
      </footer>
    )
  }*/

  const year = new Date().getFullYear();

  return (
    <StyledDiv className="bg-secondary pt-16 lg:pt-12 pb-16 lg:pb-12 relative z-20">
      <div onClick={toggleFooter} className="arrow-container cursor-pointer absolute rounded-full bg-secondary mx-auto w-14 h-14 text-center left-0 right-0">
        <div className={`arrow-footer inset-0 block absolute m-auto h-4 ${!isExpanded ? "active" : ""}`}></div>
      </div>

      <Container className="relative">
        <Collapse in={isExpanded} ref={collapseRef}>
          <div className="lg:w-full lg:min-m-max 3xl:w-10/12 md:flex mx-auto mb-12">
            <div className="hidden lg:block xl:w-1/12 3xl:w-2/12 "></div>
            <div className="lg:w-1/4 flex flex-col items-start mb-8 md:mb-0 lg:mb-0 md:w-6/12 justify-start">
              <p className="w-full text-md text-white font-medium mb-4">{t("footer.column-0.title")}</p>
              {aboutus.map((item, index) => (
                <FooterLink {...item} />
              ))}
            </div>
            <div className="lg:w-1/4 flex flex-col items-start justify-start mb-8 md:mb-0 lg:mb-0 md:w-6/12">
              <p className="w-full text-md text-white font-medium mb-4">{t("footer.column-1.title")}</p>
              {mostread.map((item, index) => (
                <FooterLink {...item} />
              ))}
            </div>
            <div className="hidden lg:flex lg:w-1/4 flex flex-col items-start justify-start mb-8 lg:mb-0 md:w-full md:flex-wrap tablet-fix">
              <p className="w-full text-md text-white font-medium mb-4 ">{t("footer.column-2.title")}</p>
              {terms.map((item, index) => (
                <FooterLink {...item} />
              ))}
            </div>
            <div className="lg:w-1/4 flex flex-col items-start justify-start mb-8 md:mb-0 lg:mb-0 md:w-6/12">
              <p className="w-full text-md text-white font-medium mb-4 ">{t("footer.column-3.title")}</p>
              {tools.map((item, index) => (
                <FooterLink {...item} />
              ))}
            </div>
          </div>
        </Collapse>
        <div className="flex lg:w-full lg:min-m-max flex-col lg:flex-row">
          <div className="lg:w-6/12 w-full lg:order-1 order-2 mt-2 md:mt-3 lg:mt-0 lg:flex lg:flex-row 3xl:text-lg lg:justify-start lg:items-end text-dark-grey-medium ">
            <p className="hidden md:inline lg:block lg:text-sm  whitespace-nowrap text-xs 3xl:text-md lg:text-white lg:mb-0 mt-4 text-center md:text-sm md:w-full lg:w-auto lg:my-0 md:text-white md:text-left md:tracking-wider ">{t("footer.copyright")}{" "}{year}</p>
            <span className="hidden md:inline lg:block">&nbsp;|&nbsp;</span>
            <p className="flex md:inline-flex md:ml-0 md:w-auto justify-center lg:justify-start lg:align-center  text-xs 3xl:text-md lg:text-white  lg:mb-0 mt-0 text-center md:text-sm lg:w-auto xl:my-0 md:text-white md:text-left md:tracking-wider ">
              <span className="pr-3 whitespace-nowrap">{t("footer.slogan1")}</span> <Isotipo ref={isotypeHeart} className="h-4 w-4" /> <span className="pl-3 whitespace-nowrap">{t("footer.slogan2")}</span>
              {/* {t("footer.slogan")} */}
            </p>
          </div>

          <div className="lg:order-3 order-1 lg:w-1/2 w-custom mx-auto md:w-full">
            <img className="block mx-auto md:ml-auto md:mr-0 mb-4" src={LogoLight} alt="Logo light" width={135} />
            <div className="line-footer block mb-4"></div>
            <div className="md:flex block md:justify-between items-center">
              <div className="block lg:w-2/5 md:w-1/2">
                <SocialNetworks />
              </div>
              <div className="flex lg:w-3/5 justify-center">
                <p className="lg:text-sm text-custom text-white text-center lg:text-right my-2 lg:my-auto lg:ml-auto lg:mr-0 font-light md:tracking-widest">
                  <Trans i18nKey="footer.apartof">
                    first paragraph<a href="https://endavantgroup.com" target="_blank" className="uppercase tracking-widest font-bold" rel="noopener">span part</a>
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block w-1/12 order-4 "></div>
      </Container>
    </StyledDiv>
  );
}

const SocialNetworks = () => (
  <div className="flex justify-start space-x-4 items-center">
    <div className="social-link">
      <a className="" href="https://www.facebook.com/Performly-107615398216726" target="_blank" rel="noreferrer nofollow">
        <img src={fb} alt="Facebook Logo" />
      </a>
    </div>
    <div className="social-link">
      <a className="" href="https://www.instagram.com/performly_agency/" target="_blank" rel="noreferrer nofollow">
        <img src={ig} alt="Instagram Logo" />
      </a>
    </div>
    <div className="social-link">
      <a className="" href="https://www.linkedin.com/company/perform-ly/" target="_blank" rel="noreferrer nofollow">
        <img src={lk} alt="Linkedin Logo" />
      </a>
    </div>
    <div className="social-link">
      <a className="" href="https://twitter.com/Performlyagency" target="_blank" rel="noreferrer nofollow">
        <img src={tw} alt="Twitter Logo" />
      </a>
    </div>
    <div className="social-link">
      <a className="" href="https://www.pinterest.com/Performly_agencia/" target="_blank" rel="noreferrer nofollow">
        <img src={pt} alt="Pinterest Logo" />
      </a>
    </div>
  </div>
)

const StyledDiv = styled.div`
  .line-footer:before {
    content: "";
    position: relative;
    height: 1px;
    width: 100%;
    background-color: #9b9b9b;
    top: 0;
    display: block;
    left: 0;
  }
  .footer-link {
    transition: color 0.3s ease;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      transition: all 0.3s ease;
      width: 100%;
      height: 1px;
      background: currentColor;
      left: 0;
      bottom: 0;
      opacity: 0;
      transform: translatex(0);
    }
    &:hover {
      color: white;
      &:after {
        transform: translatex(0);
        opacity: 1;
      }
    }
  }
  .arrow-container {
    top: 0;
    transform: translateY(-50%);
  }
  .arrow-footer {
    will-change: transform;
    max-width: 20px;
    transition: 0.4s all ease;
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4s all ease;
    &::before {
      content: "";
      width: 20px;
      height: 1px;
      background-color: #ffffff;
      position: absolute;
      left: 0;
      transform: rotate(315deg);
      top: 0;
      bottom: 0;
      margin: auto;
      transition: 0.4s all ease;
      -webkit-transition: 0.4s all ease;
      -moz-transition: 0.4s all ease;
    }
    &::after {
      content: "";
      width: 20px;
      height: 1px;
      background-color: #ffffff;
      position: absolute;
      right: 0;
      transform: rotate(45deg);
      top: 0;
      bottom: 0;
      margin: auto;
      transition: 0.4s all ease;
      -webkit-transition: 0.4s all ease;
      -moz-transition: 0.4s all ease;
    }
  }
  .arrow-footer:hover {
    transform: scale(120%);
  }

  .arrow-footer.active {
    max-width: 20px;
    transform: rotate(45deg);
  }
  .arrow-footer.active:hover {
    -webkit-transform: rotate(225deg) !important;
    transform: rotate(225deg) !important;
  }
  @media only screen and (max-width: 765px) {
    .text-custom {
      font-size: 9px;
    }
    .w-custom {
      width: min-content;
    }
  }
`;
