import Lottie from "react-lottie";
import animationDataResponsive from "./metodology-animation-data/Infinito 320px.json";
import animationDataTablet from "./metodology-animation-data/Infinito 560px.json";
import animationDataDesktop from "./metodology-animation-data/Infinito 1098px.json";
import animationDataResponsiveEN from "./metodology-animation-data/Infinite 320px.json";
import animationDataTabletEN from "./metodology-animation-data/Infinite 560px.json";
import animationDataDesktopEN from "./metodology-animation-data/Infinite 1098px.json";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FadeInPlace from "components/animations/FadeInPlace";
import FadeFromLeft from "components/animations/FadeFromLeft";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const boxTitleClasses = "text-white text-md md:text-lg font-regular mb-4 md:mb-8";
const boxContentClasses = "text-light-medium-2 font-regular whitespace-pre-line";
const boxWrapperClasses = "text-white w-full md:w-5/12 lg:w-[23%]";

const Metodology = () => {
  const { t, i18n } = useTranslation("common");
  let responsiveOptions = {};
  let tabletOptions = {};
  let desktopOptions = {};

  if (i18n.language.includes("en")) {
    responsiveOptions = {
      ...defaultOptions,
      animationData: animationDataResponsiveEN,
    };
    tabletOptions = {
      ...defaultOptions,
      animationData: animationDataTabletEN,
    };
    desktopOptions = {
      ...defaultOptions,
      animationData: animationDataDesktopEN,
    };
  } else {
    responsiveOptions = {
      ...defaultOptions,
      animationData: animationDataResponsive,
    };
    tabletOptions = {
      ...defaultOptions,
      animationData: animationDataTablet,
    };
    desktopOptions = {
      ...defaultOptions,
      animationData: animationDataDesktop,
    };
  }
  return (
    <StyledSection id="metodology" className="bg-secondary text-gray-3 -mt-10">
      <div className="container mx-auto">
        <div className="pt-[calc(28%+2rem)] md:pt-[calc(32%+2rem)] lg:pt-12 pb-28 lg:mt-0">
          <div className="flex lg:justify-end">
            <div className="lg:w-[calc(50%-3rem)] xl:w-5/12 mb-12 md:mb-12 flex flex-col items-start">
              <h2 className="text-center block text-lg-larger md:text-[38px] xl:text-3xl font-light">
                <div className="reveal-animation-subtitle">{t("about-us.methodology.title")}</div>
              </h2>
              <p className="reveal-animation-subtitle text-md font-light text-center">{t("about-us.methodology.subtitle")}</p>
            </div>
          </div>
          <div className="">
            <div className="hide-off-viewport">
              <FadeInPlace className="hidden md:block lg:hidden ">
                <Lottie options={tabletOptions} width={"100%"} />
              </FadeInPlace>
              <div className="flex flex-col md:flex-row w-full justify-between">
                <FadeFromLeft className={boxWrapperClasses}>
                  <div className="">
                    <h4 className={boxTitleClasses}>{t("about-us.methodology.customer problem.title")}</h4>
                    <p className={boxContentClasses}>{t("about-us.methodology.customer problem.content")}</p>
                  </div>
                </FadeFromLeft>
                <FadeInPlace className="md:hidden  my-4">
                  <Lottie options={responsiveOptions} width={"100%"} />
                </FadeInPlace>
                <FadeInPlace className="hidden lg:block  my-4 w-[54%] flex-shrink-0 ">
                  <div className="">
                    <Lottie options={desktopOptions} width={"100%"} />
                  </div>
                </FadeInPlace>
                <div className={`${boxWrapperClasses} fade-in-right`}>
                  <div className="">
                    <h4 className={boxTitleClasses}>{t("about-us.methodology.customer solution.title")}</h4>
                    <p className={boxContentClasses}>{t("about-us.methodology.customer solution.content")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  @media screen and (min-width: 1024px) {
    .metodology-title {
      margin-left: 48vw;
    }
  }
`;

export default Metodology;
