import React from "react"; //importamos react
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { SECTIONS } from "constants/constants";
import { scrollIntoView } from "utilities/functions";
import { useAppContext } from "utilities/context";
import styled from "styled-components";

export default function NavigationMenu() {
  const { t } = useTranslation("common");
  const MenuLinksClasses = "px-1 xl:px-2 leading-tight text-dark-medium tracking-wider xl:tracking-widest transition-opacity hover:opacity-60";
  const { baseURL } = useAppContext();
  const MenuLinks = [
    { name: "services", to: t(SECTIONS[2].to) }, // 0
    { name: "about us", to: t(SECTIONS[3].to), customClass: "lg:hidden xl:block" }, //1
    { name: "succesful cases", to: t(SECTIONS[4].to) }, // 2
    { name: "blog", to: "https://www.perform-ly.com/blog" }, // 3
    { name: "where are we", to: t(SECTIONS[8].to) }, // 4
    [{ name: "calculator", to: t(SECTIONS[14].to)}, { name: "googleGrader", to: t(SECTIONS[15].to)}], // 5
  ];
  return (
    <ul className="hidden lg:inline-flex flex-row items-center">
      {/* {MenuLinks.map((item) => (
        <div key={item.name} className={`px-2 leading-tight text-dark-medium tracking-widest transition-opacity hover:opacity-60 ${item.customClass ? item.customClass : null}`}>
          <NavLink to={item.to} key={item.name} className="whitespace-nowrap uppercase text-sm font-light">
            {t(`navigation.${item.name}`)}
          </NavLink>
          </div>
        ))} */}
      {/* Services */}
      <li className={MenuLinksClasses}>
        <NavLink to={MenuLinks[0].to} className="whitespace-nowrap uppercase text-sm font-light py-2">
          {t(`navigation.${MenuLinks[0].name}`)}
        </NavLink>
      </li>
      {/* About Us */}
      <li className={MenuLinksClasses}>
        <NavLink to={MenuLinks[1].to} className="whitespace-nowrap uppercase text-sm font-light py-2">
          {t(`navigation.${MenuLinks[1].name}`)}
        </NavLink>
      </li>
      {/* Succesful Cases */}
      <li className={MenuLinksClasses}>
        <NavLink to={MenuLinks[2].to} className="whitespace-nowrap uppercase text-sm font-light py-2">
          {t(`navigation.${MenuLinks[2].name}`)}
        </NavLink>
      </li>
      {/* Insights */}
      <li className={MenuLinksClasses}>
        <a href={MenuLinks[3].to} target="_blank" className="whitespace-nowrap uppercase text-sm font-light py-2">
          {t(`navigation.${MenuLinks[3].name}`)}
        </a>
      </li>
      {/* Where are We */}
      <li className={MenuLinksClasses}>
        <NavLink to={MenuLinks[4].to} className="whitespace-nowrap uppercase text-sm font-light py-2">
          {t(`navigation.${MenuLinks[4].name}`)}
        </NavLink>
      </li>
      {/* Calculator ROI/ROAS */}
      <Li className={MenuLinksClasses}>
        <a type="button" className="appearance-none dropbtn whitespace-nowrap uppercase text-sm font-light py-2">{t("navigation.tools")}</a>
        <div className="dropdown-content">
          {MenuLinks[5]?.map((link) => (
            <a key={link.name} href={link.to} target="_blank">
              {t(`navigation.${link.name}`)}
            </a>
          ))}
        </div>
      </Li>
    </ul>
  );
}

const Li = styled.li`
  position: relative;
  /* transform: translateY(2px);*/
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    opacity: 100%;
    display: none;
    position: absolute;
    right: 0;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: white;
    background-color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #2e2e2e;}

  /* Show the dropdown menu on hover */
  :hover .dropdown-content {display: block;}

  :hover {
    opacity: 100;
  }
`