import React from "react"; //importamos react
import pildoraAlberto from "assets/about-us/pildora-alberto-alvarez.webp";
import alberto_mobile from "assets/images/Alberto-Mobile.webp";
import AboutModal from "./AboutUsModal";
import { BLOG_URL } from "constants/constants";

export default function AlbertoInfo() {
  const data = {
    photo: pildoraAlberto,
    photoResponsive: alberto_mobile,
    title: "about-us.Alberto.title",
    content: "about-us.Alberto.content",
    category: "about-us.Alberto.category",
    blogLink: BLOG_URL,
    linkedIn: "https://www.linkedin.com/in/albertoalvarezm/",
    cta: "about-us.Alberto.cta",
  };
  return <AboutModal data={data} />;
}
