import styled from "styled-components";
import React from "react";

import panel4ResponsiveBackground from "assets/marketplace-performance/Market place background responsive.webp";
import { ProductStripe } from "../../../components/marketplace performance/ProductStripe";

const ServicesSection4Images = () => {
  return (
    <StyledDiv>
      <div>
        <div id="responsive-services-section-4-images-container">
          <div className="responsive-section-4-wrapper-outter">
            <div className="responsive-section-4-wrapper-inner">
              <div className="responsive-section-4-background-wrapper-outter">
                <div className="responsive-section-4-background-wrapper-inner">
                  <img src={panel4ResponsiveBackground} alt="" className="responsive-section-4-background-image" />
                </div>
              </div>
              <div className="responsive-section-4-stripe-container-outter">
                <div className="responsive-section-4-stripe-container-inner">
                  <div className={`panel-4-tira-responsive`}>
                    <ProductStripe responsive />
                  </div>
                  <div className="upper-gradient" />
                  <div className="bottom-gradient" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  #responsive-service-section-4-images-container {
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translatex(-50%);
    overflow: visible;
  }
  .responsive-section-4-wrapper-outter {
    position: relative;
    width: 100%;
    //margin-left: 10%;
    box-shadow: 0.3px 0.3px 2.2px rgba(0, 0, 0, 0.02), 0.8px 0.8px 5.3px rgba(0, 0, 0, 0.028), 1.5px 1.5px 10px rgba(0, 0, 0, 0.035), 2.7px 2.7px 17.9px rgba(0, 0, 0, 0.042), 5px 5px 33.4px rgba(0, 0, 0, 0.05), 12px 12px 80px rgba(0, 0, 0, 0.07);
  }
  .responsive-section-4-wrapper-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 125%;
    overflow: hidden;
  }
  .responsive-section-4-background-wrapper-outter {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }
  .responsive-section-4-background-wrapper-inner {
    position: absolute;
    inset: 0;
    border-radius: inherit;
  }
  .responsive-section-4-background-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
  }
  .responsive-section-4-dashboard-container-outter {
    position: absolute;
    inset: 0;
  }
  .responsive-section-4-dashboard-container-inner {
    position: absolute;
    inset: 0;
  }
  .responsive-section-4-dashboard-wrapper-outter {
    position: absolute;
    top: 23.3%;
    width: 80%;
  }
  .responsive-section-4-stripe-container-outter {
    position: Absolute;
    left: 5%;
    width: 33%;
  }
  .responsive-section-4-stripe-container-inner {
    position: relative;
    width: 100%;
    padding-bottom: 466%;
  }
  .panel-4-tira-responsive {
    position: absolute;
  }
`;

export default ServicesSection4Images;
