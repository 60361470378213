/**
 * Animation Constants
 */
export const EASE_OUT_DEFAULT = "power2.out"; //ease default

export const LINE_REVEAL_ANIMATION = {
  y: "100%",
  ease: EASE_OUT_DEFAULT,
  duration: 1,
};

/**
 * parse the url to get the language and return the prefix
 * @returns string
 */
const languageURLPrefix = (url = "") => {
  if (window.location.href.includes(window.location.origin + "/en")) return "/en" + url;
  if (window.location.href.includes(window.location.origin + "/es")) return "/es" + url;
  return url;
};

export const SECTIONS = [
  { id: "home-section", name: "home", to: "/" }, //0
  { id: "why-us-section", name: "why us", to: "/why-us" }, //1
  { id: "services-section", name: "menu-mobile.item2.url", to: "menu-mobile.item2.url" }, //2
  { id: "about-us-section", name: "menu-mobile.item3.url", to: "menu-mobile.item3.url" }, //3
  { id: "success-cases-section", name: "menu-mobile.item4.url", to: "menu-mobile.item4.url" }, //4
  { id: "partners-section", name: "menu-mobile.item5.url", to: "menu-mobile.item5.url" }, //5
  { id: "awards-section", name: "menu-mobile.item6.url", to: "menu-mobile.item6.url" }, //6
  { id: "blog-section", name: "menu-mobile.item8.url", to: "menu-mobile.item8.url" }, //7
  { id: "where-are-we-section", name: "menu-mobile.item9.url", to: "menu-mobile.item9.url" }, //8
  /* { id: "responsive-service-section-2", name: "panel 2", to: "/high-converting-ecommerce" }, //9
  { id: "responsive-service-section-3", name: "panel 3", to: "/digital-marketing-campaign" }, //10
  { id: "responsive-service-section-4", name: "panel 4",to: "/marketplace-performance" }, //11
  { id: "responsive-service-section-5", name: "panel 5", to: "/b2b-marketing" }, //12*/
  { id: "desktop-services-section-1", name: "desktop panel 1", to: "/performance-consulting" }, //9
  { id: "desktop-services-section-2", name: "desktop panel 2", to: "/high-converting-ecommerce" }, //10
  { id: "desktop-services-section-3", name: "desktop panel 3", to: "/digital-marketing-campaign" }, //11
  { id: "desktop-services-section-4", name: "desktop panel 4", to: "/marketplace-performance" }, //12
  { id: "desktop-services-section-5", name: "desktop panel 5", to: "/b2b-marketing" }, //13
  { name: "calculator", to: "menu-mobile.item7.url" }, //14
  { name: "googleGrader", to: "/google-grader"},
  { id: "desktop-services-section-6", name: "desktop panel 6", to: "menu-mobile.item11.url" }, //13
  { name: "desktop panel 7", to: "https://perform-ly.com/diseno-ux-ui" }, //17
  { name: "desktop panel 8", to: "https://perform-ly.com/white-label-marketing" }, //18
  { name: "desktop panel 8", to: "/agencia-seo" }, //19
];

export const COUNTRIES_URLS = ["mexico", "panama", "costa-rica", "guatemala", "estados-unidos"];

export const BREAKPOINTS = {
  sm: "640px",
  // => @media (min-width: 640px) { ... }

  md: "768px",
  // => @media (min-width: 768px) { ... }

  lg: "1024px",
  // => @media (min-width: 1024px) { ... }

  xl: "1280px",
  // => @media (min-width: 1280px) { ... }
};

export const BLOG_URL = "https://perform-ly.com/blog/";

export function MEDIA(breakpoint, styles) {
  return "";
}

export const LOCATIONS = [
  {
    country: "where-are-we.item1.country",
    city: "where-are-we.item1.state",
    address: "where-are-we.item1.address",
    urlName: "panama",
    target: "/panama",
  },
  {
    country: "where-are-we.item2.country",
    city: "where-are-we.item2.state",
    address: "where-are-we.item2.address",
    urlName: "mexico",
    target: "/mexico",
  },
  {
    country: "where-are-we.item3.country",
    city: "where-are-we.item3.state",
    address: "where-are-we.item3.address",
    urlName: "costa-rica",
    target: "/costa-rica",
  },
  {
    country: "where-are-we.item4.country",
    city: "where-are-we.item4.state",
    address: "where-are-we.item4.address",
    urlName: "guatemala",
    target: "/guatemala",
  },
  /*{
    country: "where-are-we.item5.country",
    city: "where-are-we.item5.state",
    address: "where-are-we.item5.address",
    urlName: "peru",
  },
  {
    country: "where-are-we.item6.country",
    city: "where-are-we.item6.state",
    address: "where-are-we.item6.address",
    urlName: "ecuador",
  },
  {
    country: "where-are-we.item7.country",
    city: "where-are-we.item7.state",
    address: "where-are-we.item7.address",
    urlName: "colombia",
  },*/
  {
    country: "where-are-we.item8.country",
    city: "where-are-we.item8.state",
    address: "where-are-we.item8.address",
    urlName: "estados-unidos",
    target: "/estados-unidos",
  },
  {
    country: "where-are-we.item10.country",
    city: "where-are-we.item10.state",
    address: "where-are-we.item10.address",
    urlName: "venezuela",
    target: "/venezuela",
  },
  /*{
    country: "where-are-we.item9.country",
    city: "where-are-we.item9.state",
    address: "where-are-we.item9.address",
    urlName: "españa",
  },*/
];
