// import React, { useState, useMemo } from "react"; //importamos react
import React from "react"; //importamos react
import { useTranslation } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
//import arrowWhite from "assets/arrow-white.svg";
import Container from "../Container";
//import LocationSlider from "./components/Slider";
import background from "assets/images/bg-where-are-we.webp";
import { useParams } from "react-router-dom";
import { LOCATIONS } from "constants/constants";
import { useAppContext } from "utilities/context";

export default function WhereAreWe({ id = null }) {
  const { t } = useTranslation("common");

  const { baseURLParams, baseURL } = useAppContext();
  const { country } = baseURLParams;

  function linkToCountry(targetCountry) {
    let pathname = window.location.pathname.replace(baseURL, "");
    let language = Boolean(baseURLParams.language) ? "/" + baseURLParams.language : "";
    let country = targetCountry;
    return window.location.origin + language + country;
      // pathname will be added later by checking which div has the active class
  }

  function _sortCountries(activeCountry, LOCATIONS) {

    if (activeCountry) {
      let sortedCountries = LOCATIONS.sort((a, b) => {
        console.log("sort countries",country, a, activeCountry);
        return a.urlName === activeCountry ? -1 : 1;
      });
      return sortedCountries;
    } else {
      return LOCATIONS;
    }
  }

  return (
    <SeccionConEstilos id={id} className="lg:pt-34 pt-10 md:pt-24 pb-10 md:pb-12 bg-gray-1 relative ">
      <img className="absolute lg:top-80 lg:-left-2 hidden pointer-events-none z-0 lg:block 2xl:top-64 size-ultra" src={background} alt="" />
      <Container>
        <div className="2xl:max-w-full lg:max-w-7xl mx-auto flex flex-wrap relative  z-1 ">
          <div className="lg:w-1/12 lg:block hidden"></div>
          <div className="lg:w-4/12 w-full relative mb-4 lg:mb-0 md:w-5/12">
            <h3 id="where-are-we-title" className="reveal-animation-title text-4xl md:text-2xl leading-none text-dark-head ">
              {t("where-are-we.title")}
            </h3>
          </div>
          <div className="lg:w-1/12 lg:block hidden"></div>
          <div data-aos="fade-left" className="lg:w-6/12 ">
            {/* <FadeIn id="where-are-we-caption" direction="top"> */}
            <p id="where-are-we-caption" className="reveal-animation-subtitle text-dark-grey text-md md:w-9/12 lg:w-full 2xl:max-w-lg">
              {t("where-are-we.caption")}
            </p>
            <div className="hidden lg:block mt-20 md:mt-16 mb-28 relative h-1 under-line-blog md:block md:mb-12">

            </div>
            {/* </FadeIn> */}
          </div>
        </div>
        {/* <LocationSlider locations={locations} /> */}
        <div id="locations-wrapper" className="2xl:max-w-full lg:ml-1/12 mx-auto flex flex-wrap z-1 lg:pl-0 mt-14 lg:mt-0 md:mt-0 justify-between">
          {_sortCountries(country, LOCATIONS).map((location, index) => {
            if (index === 0) {
              return (
                <div key={t(location.country) + index} className="where-are-we-active-country w-full mb-10 lg:mb-0 md:w-full md:flex md:flex-wrap md:items-baseline lg:items-start">
                  <div className="fade-in-place location-slide-content">
                    <h4 className="location-country text-white lg:font-medium lg:text-xl md:pr-8 text-xl lg:pr-0 lg:w-full">
                      <span>{t(location.country)}</span>
                    </h4>
                    <p className="location-city text-white text-base mt-4 lg:mt-0 md:flex-grow lg:flex-grow-0 lg:w-full md:mt-0">
                      <span>{t(location.city)}</span>
                    </p>
                    <div className="location-address-wrapper lg:mr-4">
                      <p className="location-address text-dark-grey text-base mt-3 md:w-full lg:mr-6">{t(location.address)}</p>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={t(location.country) + index} className="where-are-we-countries w-6/12 mb-10 lg:mb-0">
                  <div id={`where-are-we-country-${index}`} className="fade-in-place location-slide-content" direction="none">
                    <a href={linkToCountry(t(location.target))} target="_blank" rel="noreferrer">
                      <h4 className="location-country text-white text-lg font-light lg:font-medium lg:text-lg lg:text-dark-dark">
                        <span>{t(location.country)}</span>
                      </h4>
                      <p className="location-city text-white text-base lg:text-dark-dark">
                        <span>{t(location.city)}</span>
                      </p>
                      <div className="location-address-wrapper hidden lg:block lg:mr-4">
                        <p className="location-address text-dark-grey text-md lg:text-base mt-3 md:w-full">{t(location.address)}</p>
                      </div>
                    </a>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </Container>
    </SeccionConEstilos>
  );
}
const SeccionConEstilos = styled.div`
  .under-line-blog:before {
    content: "";
    width: 862px;
    height: 1px;
    position: absolute;
    background-color: #696969;
    top: 0px;
    right: -50px;
    left: 0;
  }
  @media only screen and (min-width: 640px) and (max-width: 1040) {
    .container {
    }
  }
  #locations-wrapper {
    --active-width: 20%;
    --default-width: calc((100% - var(--active-width)) / 4);
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      .where-are-we-active-country,
      .where-are-we-countries {
        padding: 0 0.75rem 0 0;
        overflow: visible;
        transition: width 0.8s, transform 0.6s;
        position: relative;
        .location-address-wrapper {
          width: 130%;
          position: relative;
          overflow: hidden;
          height: 9rem;
        }
        .location-country,
        .location-country span {
          transition: font-size 0.6s, color 0.6s;
        }
        .location-city,
        .location-city span {
          transition: font-size 0.6s, color 0.6s;
        }
      }
      .where-are-we-active-country {
        width: var(--active-width);
        .location-city,
        .location-country {
          color: white;
        }
        .location-address-wrapper {
          //width: 100%;
          .location-address {
            transition: opacity 1s ease, transform 0.8s;
          }
        }
      }
      &:hover {
        .where-are-we-active-country {
          width: var(--default-width);
          //transform: translateY(2rem);
          .location-city,
          .location-country {
            transition: color 0.3s ease;
            color: rgb(196, 196, 196);
          }
          .location-address-wrapper {
            transition: transform 0.6s;
            //width: 140%;
            .location-address {
              transition: opacity 0.4s ease;
              opacity: 0;
              //transform: translatey(-1rem);
            }
          }
          &:hover {
            width: var(--active-width);
            //transform: translateY(0);
            color: white;
            .location-country,
            .location-city {
              color: white;
            }
            .location-address-wrapper {
              //width: 100%;
              .location-address {
                transition: opacity 1s ease, transform 0.8s;
                opacity: 1;
                //transform: translatey(0);
              }
            }
          }
        }
      }
      .where-are-we-countries {
        width: var(--default-width);
        //transform: translateY(2rem);
        .location-address-wrapper {
          //width: 140%;
          .location-address {
            transition: opacity 0.4s ease, transform 0.8s;
            opacity: 0;
            transform: translatey(-1rem);
          }
        }
        &:hover {
          width: var(--active-width);
          //transform: translateY(0);
          color: white;
          .location-country,
          .location-city {
            color: white;
          }
          .location-address-wrapper {
            //width: 100%;
            .location-address {
              transition: opacity 0.4s ease, transform 0.8s;
              opacity: 1;
              transform: translatey(0);
            }
          }
        }
      }
      .location-country,
      .current-country {
        font-size: 1.25rem;
      }
    }
  }
  /* #locations-wrapper {
    @media screen and (min-width: 1024px) {
      & > * {
        transition: width 0.75s, transition 0.75s;
        width: calc(72% / 4);
      }
      & .where-are-we-active-country {
        width: 28%;
        transform: translatey(0);
        & * {
          transition: transform 0.75s, font-size 0.3s, color 0.3s;
        }
      }
      &:hover .where-are-we-active-country {
        width: calc(72% / 4);
        transform: translatey(2rem);
        & * {
          color: rgb(196, 196, 196);
        }
        & .current-address {
          transform: translatey(-150%);
        }
        &:hover {
          width: 28%;
          transform: translatey(0);
          & .current-address {
            transform: translatey(0);
          }
          & * {
            color: white;
          }
        }
      }
      &:hover .where-are-we-countries:hover {
        width: 28%;
      }
      .location-slide-content {
        transition: transform 0.3s, font-size 0.3s;
        min-height: 100%;
        & * {
          transition: transform 0.6s, font-size 0.3s, color 0.3s;
        }
        & .location-address-wrapper {
          overflow: hidden;
        }
      }
      .where-are-we-countries {
        .location-slide-content {
          transform: translatey(2rem);
          & .location-address {
            transform: translatey(-120%);
          }
          &:hover {
            transform: translatey(0);
            & .location-country {
              color: white;
              font-size: 28px;
            }
            & .location-city {
              color: white;
            }
            & .location-address {
              transform: translatey(0);
            }
          }
        }
      }
    }
  } */
`;
