import styled from "styled-components";

const color = {
  blue: {
    outer: "rgba(98,137,255,0.5)",
    dot: "rgba(72,93,157,0.6)",
    ring: "rgba(191,195,203,1)",
  },
  green: {
    outer: "rgba(118,191,125,0.5)",
    dot: "rgba(82,121,92,0.6)",
    ring: "rgba(191,195,203,1)",
  },
};

export const PointerDot = ({ id = null, className = null, color = "green" }) => {

  return (
  <StyledPointerDot id={id} className={`${className ? ` ${className}` : ""}`} $color={color}>
    <div className="inner-wrapper">
      <div className="dot-wrapper">
        <span className="dot" />
      </div>
      <div className="outer-wrapper">
        <span className="outer" />
      </div>
      <div className="ring-wrapper">
        <span className="animated-ring" />
      </div>
    </div>
  </StyledPointerDot>
)};

const StyledPointerDot = styled.div`
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    .inner-wrapper {
      height: 0;
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      .dot-wrapper,
      .outer-wrapper,
      .ring-wrapper {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .dot-wrapper {
        width: 30%;
        height: 30%;
      }
      .outer-wrapper {
        width: 100%;
        height: 100%;
      }
      .ring-wrapper {
        width: 125%;
        height: 125%;
      }
      .dot,
      .outer,
      .animated-ring {
        border-radius: 100%;
        display: block;
        width: 100%;
        height: 100%;
      }
      .dot {
        background: ${(props) => color[props.$color].dot};
      }
      .outer {
        background: ${(props) => color[props.$color].outer};
      }
      .animated-ring {
        border: 1px solid #ffffff;
      }
    }
  }
`;
