import React, { useLayoutEffect, useCallback } from "react";
import { useSelector } from "utilities/functions";
import { useAppContext } from "utilities/context";
import FadeInPlace from "components/animations/FadeInPlace";
import { RevealBox } from "components/common/RevealBox";
import capsula from "assets/capsulas-awards.svg";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { Draggable } from "gsap/all";
import { ArrowLink } from "../common/ArrowLink";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable);

const AwardsDesktop = ({ awardSlides }) => {
  const { t } = useTranslation(["common"]);
  const [q, ref] = useSelector();
  const { openContactForm } = useAppContext();

  let activeSlideRef = React.useRef(0);
  let sliderInterval = React.useRef(null);
  let inViewport = React.useRef(false);
  let stopAutoSlide = React.useRef(false);

  function setSlide(slide) {
    q("#award-paginator .award-paginator-bullet")[activeSlideRef.current].classList.remove("active");
    q(".award-photo")[activeSlideRef.current].classList.remove("active");
    q(".award-content")[activeSlideRef.current].classList.remove("active");
    activeSlideRef.current = slide;
    q("#award-paginator .award-paginator-bullet")[activeSlideRef.current].classList.add("active");
    q(".award-photo")[activeSlideRef.current].classList.add("active");
    q(".award-content")[activeSlideRef.current].classList.add("active");
    q(".active-award-logo")[0].style.transform = `translateY(${(-100 / 5) * activeSlideRef.current}%)`;
    q(".active-award")[0].style.transform = `translateY(${(-100 / 5) * activeSlideRef.current}%)`;
  }

  const getNextSlide = useCallback(() => {
    if (inViewport.current) {
      if (activeSlideRef.current === awardSlides.length - 1) {
        setSlide(0);
      } else {
        setSlide(activeSlideRef.current + 1);
      }
    }
  }, [inViewport, activeSlideRef, awardSlides, setSlide]);

  const getPreviousSlide = useCallback(() => {
    if (inViewport.current) {
      if (activeSlideRef.current === 0) {
        setSlide(awardSlides.length - 1);
      } else {
        setSlide(activeSlideRef.current - 1);
      }
    }
  }, [inViewport, activeSlideRef, awardSlides, setSlide]);

  function setSlideAndStop(slide) {
    clearInterval(sliderInterval.current);
    stopAutoSlide.current = true;
    setSlide(slide);
  }

  useLayoutEffect(() => {
    sliderInterval.current = setInterval(() => {
      getNextSlide();
    }, 5000);

    ScrollTrigger.create({
      start: 0,
      end: "max",
      onUpdate: elementInViewport,
    });

    function elementInViewport() {
      if (!ScrollTrigger.isInViewport(ref.current, 0.5)) {
        inViewport.current = false;
      } else {
        inViewport.current = true;
      }
    }

    let draggable = Draggable.create("#awards-touch-control", {
      zIndexBoost: false,
      type: "x",
      snap: {
        x: 0,
        y: 0,
      },
      cursor: "initial",
      onDragEnd: function () {
        //console.log(draggable);
        if (draggable[0].deltaX < 5) {
          getNextSlide();
          clearInterval(sliderInterval.current);
        }
        if (draggable[0].deltaX > 5) {
          getPreviousSlide();
          clearInterval(sliderInterval.current);
        }
        gsap.to(this.target, { x: 0, y: 0, duration: 0 });
      },
    });
    return () => {
      clearInterval(sliderInterval.current);
    };
  }, [getNextSlide, getPreviousSlide]);

  return (
    <div ref={ref} className="w-full h-full relative">
      {/* <div id="awards-touch-control" className="hidden md:block absolute inset-0 z-50" /> */}
      <div className="hidden md:block container relative lg:h-[639px] z-10 pointer-events-none">
        <div className="flex flex-col w-full md:w-10/12 lg:w-6/12">
          <div className="w-full mt-10">
            <h3 className="text-gray-1 lg:pl-0 md:text-4xl">
              <div className="reveal-animation-title">{t("awards.title")}</div>
            </h3>
            <RevealBox className="fade-in-place award-name h3 text-lg overflow-hidden">
              <div className="active-award">
                {awardSlides.map((award) => {
                  return (
                    <h4 key={award.contentId} className="text-light-medium font-normal uppercase text-lg tracking-tight whitespace-nowrap">
                      {t(`awards.${award.contentId}.award`)}
                    </h4>
                  );
                })}
              </div>
            </RevealBox>
          </div>
          <div className="w-full flex flex-col overflow-x-visible relative lg:mt-12">
            <div className="fade-in-left underline-awards desktop h-px bg-grey-6 right-0 absolute" />
            <div className="content-container w-full flex flex-col flex-grow mt-8 lg:mt-12 md:mt-12 line-control relative">
              <div direction="left" className="fade-in-left award-logo w-full lg:w-2/5 relative lg:absolute lg:pr-12 flex flex-row lg:justify-end">
                <div className="relative w-18 h-18 md:w-20 md:h-20 lg:w-3/4 lg:h-auto 2xl:w-1/2 overflow-hidden">
                  <div className="h-0 pb-full overflow-hidden">
                    <div className="active-award-logo ">
                      {awardSlides.map((item) => (
                        <div key={item.contentId}>
                          <img className="block h-full w-full w-max-none lg:w-full object-contain object-center" src={item.logo} width={116} height={116} alt={item.imageAlt} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div id="award-paginator" className="pointer-events-auto">
                  <div className="hidden lg:block">
                    {awardSlides.map((item, index) => (
                      <div onClick={() => setSlideAndStop(index)} className={`award-paginator-bullet ${index === 0 && "active"}`} />
                    ))}
                  </div>
                  <div className="underline-awards mobile h-px bg-grey-6 " />
                </div>
              </div>
              <FadeInPlace className="w-full lg:pl-2/5 md:text-md md:h-auto md:min-h-[16rem] mt-8 lg:mt-0 font-light text-light-medium overflow:hidden relative h-36 ">
                <div id="award-content-container" className="w-full h-full relative bg-red-500">
                  {awardSlides.map((item, index) => (
                    <p className={`award-content absolute whitespace-pre-line inset-0 mt-0 ${index === 0 && "active"}`}>{t(`awards.${item.contentId}.content`)}</p>
                  ))}
                </div>
              </FadeInPlace>
            </div>
            <div className="fade-in-left w-full lg:pl-2/5 lg:pb-12 pt-0 md:pt-8 lg:text-center pointer-events-auto relative z-[200] ">
              <ArrowLink onClick={openContactForm} className="lg:w-7/12 lg:mt-4 2xl:mt-6 mt-4 w-full">
                {t("awards.link")}
              </ArrowLink>
            </div>
            <div id="mobile-awards-paginator" className=" lg:hidden">
              {awardSlides.map((item, index) => (
                <div onClick={() => setSlideAndStop(index)} className={`award-paginator-bullet ${index === 0 && "active"}`} />
              ))}
            </div>
          </div>
        </div>
        <div className="lg:w-1/12 hidden lg:block"></div>
        <div className="fade-in-right photo-container -mt-4 md:-mt-24 lg:-mt-10 max-w-[530px]">
          <div className="photo-container-outer-wrapper">
            <div className="photo-container-inner-wrapper overflow-hidden">
              <div>
                <img className="relative w-full z-10 lg:absolute" src={capsula} alt="" />
              </div>
              <div className="awards-photo-wrapper">
                {awardSlides.map((item, index) => (
                  <div key={item.contentId} className={`award-photo ${index === 0 ? "active" : "hide"}`}>
                    <img className="absolute w-full h-n-1 w-max-none z-1 top-0 object-contain" src={item.photo} alt={item.imageAlt} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardsDesktop;
