import PanelContentBodyWrapper from "../../components/ResponsiveContentBodyWrapper";
import PanelContentFooterWrapper from "../../components/ResponsiveContentFooterWrapper";
import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentModal";
import { useTranslation, Trans } from "react-i18next";
import panel4AmazonLogo from "assets/marketplace-performance/amazon-logo@1x.svg";
import panel4RappiLogo from "assets/marketplace-performance/rappi-logo@1x.svg";
import panel4MercadoLibreLogo from "assets/marketplace-performance/mercado-libre-mobile.webp";

const MarketPlacePerformanceContent = () => {
  const { t } = useTranslation("common");
  return (
    <PanelContentWrapper>
      <PanelContentTitleWrapper number={4}>
        <Trans i18nKey="common:services.marketplace performance.title">
          <span className="block">marketplace</span>
          <span className="block whitespace-nowrap">performance</span>
        </Trans>
      </PanelContentTitleWrapper>
      <PanelContentSubtitleWrapper> {t("services.marketplace performance.subtitle responsive")}</PanelContentSubtitleWrapper>
      <PanelContentBodyWrapper>
        <Trans i18nKey="common:services.marketplace performance.content">
          <span className="block my-6 text-lg font-semibold">parrafo 1</span>
          <span className="block">parrafo 2.</span>
        </Trans>
      </PanelContentBodyWrapper>
      <div className="uppercase whitespace-nowrap text-sm text-white pt-12 underline-decoration">{t("services.marketplace performance.commercial-allies")}</div>
      <PanelContentFooterWrapper>
        <div>
          <img src={panel4AmazonLogo} alt="Amazon logo" className="w-auto h-8 object-contain brightness-150" />
        </div>
        <div>
          <img src={panel4RappiLogo} alt="Rappi logo" className="w-auto h-8 object-contain brightness-150" />
        </div>
        <div>
          <img src={panel4MercadoLibreLogo} alt="Mercado Libre logo" className="w-auto h-8 object-contain" />
        </div>
      </PanelContentFooterWrapper>
    </PanelContentWrapper>
  );
};

export default MarketPlacePerformanceContent;
