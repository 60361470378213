import { ServiceIcon } from "../../../../common/Icons";
import PanelContentBodyWrapper from "../../components/ResponsiveContentBodyWrapper";
import PanelContentFooterWrapper from "../../components/ResponsiveContentFooterWrapper";
import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentModal";
import { useTranslation, Trans } from "react-i18next";

import AnalysisUIUX from "assets/analysis-ux-ui.svg";
import CampaignAnalysis from "assets/campaign-analysis.svg";
import FunnelAnalysis from "assets/funnel-analysis.svg";
import SeoPerformance from "assets/seo-performance.svg";

const PerformanceConsultingContent = () => {
  const { t } = useTranslation(["common"]);
  return (
    <PanelContentWrapper id="panel-1-content">
      <PanelContentTitleWrapper number={1}>{t("services.performance consulting.title")}</PanelContentTitleWrapper>
      <PanelContentSubtitleWrapper>{t("services.performance consulting.subtitle responsive")}</PanelContentSubtitleWrapper>
      <PanelContentBodyWrapper>
        <Trans i18nKey="common:services.performance consulting.content">
          <span className="text-lg whitespace-normal font-semibold">Parrafo 1</span>
          <span>Parrafo 2</span>
        </Trans>
      </PanelContentBodyWrapper>
      <PanelContentFooterWrapper className="md:w-4/5">
        <ServiceIcon icon={CampaignAnalysis} caption={t("services.performance consulting.campaign analysis")} />
        <ServiceIcon icon={AnalysisUIUX} caption={t("services.performance consulting.analysis ux ui")} />
        <ServiceIcon icon={FunnelAnalysis} caption={t("services.performance consulting.funnel analysis")} />
        <ServiceIcon icon={SeoPerformance} caption={t("services.performance consulting.seo performace")} />
      </PanelContentFooterWrapper>
    </PanelContentWrapper>
  );
};

export default PerformanceConsultingContent;
