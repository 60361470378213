import { ServiceIcon } from "../../../common/Icons";
import PanelContentBodyWrapper from "../PanelContentBodyWrapper";
import PanelContentFooterWrapper from "../PanelContentFooterWrapper";
import PanelContentSubtitleWrapper from "../PanelContentSubTitleWrapper";
import PanelContentTitleWrapper from "../PanelContentTitleWrapper";
import PanelContentWrapper from "../PanelContentWrapper";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import AnalysisUIUX from "assets/analysis-ux-ui.svg";
import CampaignAnalysis from "assets/campaign-analysis.svg";
import FunnelAnalysis from "assets/funnel-analysis.svg";
import SeoPerformance from "assets/seo-performance.svg";

const PerformanceConsultingContent = ({ id = null, setExpandedPanel, expandedPanel }) => {
  const { t } = useTranslation(["common"]);
  const serviceIcons = [
    { icon: CampaignAnalysis, caption: "services.performance consulting.campaign analysis", alt: "services.performance consulting.campaign analysis" },
    { icon: AnalysisUIUX, caption: "services.performance consulting.analysis ux ui", alt: "services.performance consulting.analysis ux ui", to: "https://perform-ly.com/diseno-ux-ui" },
    { icon: FunnelAnalysis, caption: "services.performance consulting.funnel analysis", alt: "services.performance consulting.funnel analysis" },
    { icon: SeoPerformance, caption: "services.performance consulting.seo performace", alt: "services.performance consulting.seo performace", to: "/agencia-seo" },
  ];
  return (
    <StyledDiv>
      <PanelContentWrapper id="panel-1-content" setExpandedPanel={setExpandedPanel} expandedPanel={expandedPanel}>
        <PanelContentTitleWrapper number={1}>{t("services.performance consulting.title")}</PanelContentTitleWrapper>
        <PanelContentSubtitleWrapper>{t("services.performance consulting.subtitle")}</PanelContentSubtitleWrapper>
        <PanelContentBodyWrapper>
          <Trans i18nKey="services.performance consulting.content">
            <p>
              Los servicios de <b>optimización de la tasa de conversión</b> examinan cada paso de sus esfuerzos de ventas y conocen las debilidades de su embudo de ventas.
            </p>
            <p>Una vez que convierta esas debilidades en fortalezas, encontrará más clientes realizando compras que nunca.</p>
          </Trans>
        </PanelContentBodyWrapper>
        <PanelContentFooterWrapper className="w-4/5">
          <h4 className="uppercase whitespace-pre-line text-sm pr-6 underline-decoration">{t("services.performance consulting.our-services")}</h4>
          {serviceIcons.map((item, index) => (
            <ServiceIcon key={index} icon={item.icon} caption={t(item.caption)} alt={t(item.alt)} link={item.to} />
          ))}
        </PanelContentFooterWrapper>
      </PanelContentWrapper>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  #panel-1-content b {
    font-size: 1.7rem;
    font-weight: 600;
  }
`;

export default PerformanceConsultingContent;
