import { BLOG_URL } from "constants/constants";
import { useTranslation } from "react-i18next";
import { ReactComponent as FlechaPequena } from "assets/flecha-pequena-v2.svg";


const LinkToCase = ({ url = "", onClick=null, google = false, className = "" }) => {
  const { t } = useTranslation(["common"]);
  return (
    <a href={`${BLOG_URL}${url}`} target="_blank" rel="noreferrer nofollow" className={`case-cta flex flex-col items-end lg:flex-row justify-start lg:items-center text-dark-dark ${className}`}>
      <div className="flex flex-col justify-center items-end space-y-2 mb-2 lg:mb-0">
        <div className=" text-sm ">
          <span>{t("success-cases.see business case")}&nbsp; </span>
          <span className="cta-arrow">
            <FlechaPequena className="inline" />
          </span>
        </div>
      </div>
      {/* <div className={`hidden pl-4 w-16 ${google ? "google-case" : ""}`}>{google && <img src={GoogleLogoCta} alt="logo" className="h-auto w-auto google-case-icon" />}</div> */}
    </a>
  );
};

export default LinkToCase;