import PanelBackground from "../PanelBackground";
import styled from "styled-components";
import React, { forwardRef } from "react";
import panel3Background from "assets/digital-marketing/background.webp";
import panel3BackgroundResponsive from "assets/digital-marketing/digital marketing background responsive.webp";

const Panel3Background = forwardRef(({ children, className = null, id = null }, ref) => {
  return (
    <StyledPanelBackground id={id}>
      <div className="hidden md:block h-full">
        <img src={panel3Background} ref={ref} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
      <div className="md:hidden h-full">
        <img src={panel3BackgroundResponsive} ref={ref} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
    </StyledPanelBackground>
  );
});

const StyledPanelBackground = styled(PanelBackground)``;

export default Panel3Background;
