import PanelContentBodyWrapper from "../PanelContentBodyWrapper";
import PanelContentFooterWrapper from "../PanelContentFooterWrapper";
import PanelContentSubtitleWrapper from "../PanelContentSubTitleWrapper";
import PanelContentTitleWrapper from "../PanelContentTitleWrapper";
import PanelContentWrapper from "../PanelContentWrapper";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import panel4AmazonLogo from "assets/marketplace-performance/amazon-logo@1x.svg";
import panel4RappiLogo from "assets/marketplace-performance/rappi-logo@1x.svg";
import panel4MercadoLibreLogo from "assets/marketplace-performance/mercado-libre-logo@1x.svg";

const MarketPlacePerformanceContent = ({ setExpandedPanel, expandedPanel }) => {
  const { t } = useTranslation("common");
  return (
    <StyledDiv>
      <PanelContentWrapper id="panel-4-content" setExpandedPanel={setExpandedPanel} expandedPanel={expandedPanel}>
        <PanelContentTitleWrapper number={4} titlePreview={t("services.marketplace performance.title-preview")}>
          <Trans i18nKey="services.marketplace performance.title">
            <span className="block">marketplace</span>
            <span className="block whitespace-nowrap">performance</span>
          </Trans>
        </PanelContentTitleWrapper>
        <PanelContentSubtitleWrapper> {t("services.marketplace performance.subtitle")}</PanelContentSubtitleWrapper>
        <PanelContentBodyWrapper>
          <Trans i18nKey="services.marketplace performance.content">
            <span className="block mb-6">parrafo 1</span>
            <span className="block">parrafo 2.</span>
          </Trans>
        </PanelContentBodyWrapper>
        <PanelContentFooterWrapper>
          <h4 className="uppercase whitespace-pre-line text-sm pr-6 underline-decoration">{t("services.marketplace performance.commercial-allies")}</h4>
          <div>
            <img src={panel4AmazonLogo} alt="Amazon logo" title="Amazon" className="w-auto h-8 object-contain" />
          </div>
          <div>
            <img src={panel4RappiLogo} alt="Rappi logo" title="Rappi" className="w-auto h-8 object-contain" />
          </div>
          <div>
            <img src={panel4MercadoLibreLogo} alt="Mercado Libre logo" title="Mercado Libre" className="w-auto h-8 object-contain" />
          </div>
        </PanelContentFooterWrapper>
      </PanelContentWrapper>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  #panel-4-content span {
    font-size: 1.7rem;
    font-weight: 600;
  }
`;

export default MarketPlacePerformanceContent;
