import styled from "styled-components";
import productStripe1 from "assets/marketplace-performance/tira-1@1x.webp";
import productStripe2 from "assets/marketplace-performance/tira-con-carrito@1x.webp";
import productStripResponsive from "assets/marketplace-performance/tira-responsive.webp";

export const ProductStripe = ({ id = null, className = null, withCart = false, direction = "down", responsive = false }) => {
  return (
    <StyledStripe id={id}>
      <div className="stripe-wrapper">
        <div>
          {withCart && <img src={productStripe2} alt="" />}
          {responsive && <img src={productStripResponsive} alt="" />}
          {!responsive && !withCart && <img src={productStripe1} alt="" />}
        </div>
      </div>
    </StyledStripe>
  );
};

const StyledStripe = styled.div`
  & {
    width: 100%;
    top: 50%;
    //transform:translatey(-50%);
    .stripe-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
`;
