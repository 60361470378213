import { ReactComponent as AwardPhotoMask } from "assets/awards/awards responsive mask.svg";

const AwardsResponsivePhoto = ({ photo="", alt = "" }) => {
  return (
    <div className="awards-responsive-photo relative w-full">
      <div className="awards-responsive-photo-wrapper relative pb-[118%] overflow-hidden">
        <div className="awards-responsive-photo-mask absolute inset-0 z-10">
          <AwardPhotoMask className="w-full h-auto object-cover" />
        </div>
        <div className="absolute inset-px m-1">
          <img src={photo} alt={alt} className="w-full h-auto object-contain" />
        </div>
      </div>
    </div>
  );
};

export default AwardsResponsivePhoto;
