import React from "react"; //importamos react
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import { LOCATIONS, SECTIONS } from "constants/constants";
import { useAppContext } from "utilities/context";
import { scrollIntoView } from "utilities/functions";
import fb from "assets/fb.svg";
import ig from "assets/ig.svg";
import lk from "assets/lk.svg";
import tw from "assets/tw.svg";
import pt from "assets/pt.svg";
import ContactUsButton from "../components/ContactUsButton";
import { useParams, Link } from "react-router-dom";

export default function MobileMenu() {
  const { t } = useTranslation("common");
  const { closeModal, openContactModal } = useAppContext();
  const BLOG_URL = "https://www.perform-ly.com/blog"
  //console.log(useParams());
  let { country } = useParams();

  function _sortCountries(activeCountry, locations) {
    if (activeCountry) {
      let sortedCountries = locations.sort((a, b) => {
        return a.urlName === activeCountry ? -1 : 1;
      });
      return sortedCountries;
    } else {
      return locations;
    }
  }

  const MenuLinks = [
    { name: "home", to: SECTIONS[0].to, id: SECTIONS[0].id }, // 0
    { name: "services", to: SECTIONS[2].to, id: SECTIONS[2].id }, // 1
    { name: "about us", to: SECTIONS[3].to, id: SECTIONS[3].id }, // 2
    { name: "succesful cases", to: SECTIONS[4].to, id: SECTIONS[4].id }, // 3
    { name: "partners", to: SECTIONS[5].to, id: SECTIONS[5].id }, // 4
    { name: "Awards", to: SECTIONS[6].to, id: SECTIONS[6].id }, // 5
    { name: "blog", to: SECTIONS[7].to, id: SECTIONS[7].id }, // 6
    { name: "ubicacion", to: SECTIONS[8].to, id: SECTIONS[8].id }, // 7
    /*    { name: "panel-2-content", to: SECTIONS[9].to, id: SECTIONS[9].id }, // 8
    { name: "panel-3-content", to: SECTIONS[10].to,  id: SECTIONS[10].id }, // 9
    { name: "panel-4-content", to: SECTIONS[11].to,  id: SECTIONS[11].id }, // 10
    { name: "panel-5-content", to: SECTIONS[12].to,  id: SECTIONS[12].id }, // 11 */
    { name: "desktop-services-section-1", to: SECTIONS[9].to, id: SECTIONS[9].id }, // 8
    { name: "desktop-services-section-2", to: SECTIONS[10].to, id: SECTIONS[10].id }, // 9
    { name: "desktop-services-section-3", to: SECTIONS[11].to, id: SECTIONS[11].id }, // 10
    { name: "desktop-services-section-4", to: SECTIONS[12].to, id: SECTIONS[12].id }, // 11
    { name: "desktop-services-section-5", to: SECTIONS[13].to, id: SECTIONS[13].id }, // 12
    { name: "desktop-services-section-6", to: SECTIONS[16].to, id: SECTIONS[16].id }, // 13
    { name: "diseno", to: SECTIONS[17].to }, // 14
    { name: "marketing", to: SECTIONS[18].to }, // 15
  ];

  const linkDefaultCSSClasses = "top-level-link text-gray-300 lg:text-light text-lg xl:text-xl no-underline lg:py-2 cursor-pointer";
  /*   const isActive = (id) => {
    return id === currentSection ? "active" : "inactive";
  }; */

  return (
    <StyledDiv className="menu-items bg-scroll bg-no-repeat bg-contain flex flex-col md:justify-start lg:justify-center w-full lg:border-l-2 lg:border-solid lg:border-gray-500 lg:border-opacity-30 absolute top-0 right-0 bottom-0 h-screen z-50">
      {/* Desktop menu */}
      <div className="desktop-menu flex flex-col h-full pt-12 pb-16">
        <div className="flex flex-row items-center flex-grow">
          <div className="relative flex justify-end w-full ">
            {/* Social */}
            <div className="flex flex-col absolute left-0 md:justify-between items-center">
              <p className="text-sm text-white text-opacity-50 mb-4">{t("navigation.social")}</p>
              <div className="block w-full">
                <div className="flex flex-col justify-start space-y-4 items-start">
                  <div className="social-link">
                    <a className="" href="https://www.facebook.com/Performly-107615398216726" target="_blank" rel="nofollow noreferrer">
                      <img src={fb} alt="Logo Facebook" />
                    </a>
                  </div>
                  <div className="social-link">
                    <a className="" href="https://www.instagram.com/performly_agency/" target="_blank" rel="nofollow noreferrer">
                      <img src={ig} alt="Logo Instagram" />
                    </a>
                  </div>
                  <div className="social-link">
                    <a className="" href="https://www.linkedin.com/company/perform-ly/" target="_blank" rel="nofollow noreferrer">
                      <img src={lk} alt="Logo Linkedin" />
                    </a>
                  </div>
                  <div className="social-link">
                    <a className="" href="https://twitter.com/Performly2" target="_blank" rel="nofollow noreferrer">
                      <img src={tw} alt="Logo Twitter" />
                    </a>
                  </div>
                  <div className="social-link">
                    <a className="" href="https://www.pinterest.com/Performly/" target="_blank" rel="nofollow noreferrer">
                      <img src={pt} alt="Logo Pinterest" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Services */}
            <ul className="flex flex-col mr-24 items-end">
              <p className="text-sm text-gray-200">{t("navigation.services")}</p>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[8].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[8].id);
                  closeModal();
                }}>
                <Link to={MenuLinks[8].to}>{t("menu-mobile.item2.sub-link1")}</Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[9].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[9].id);
                  closeModal();
                }}>
                <Link to={MenuLinks[9].to}>{t("menu-mobile.item2.sub-link2")}</Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[10].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[10].id);
                  closeModal();
                }}>
                <Link to={MenuLinks[10].to}>{t("menu-mobile.item2.sub-link3")}</Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[11].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[11].id);
                  closeModal();
                }}>
                <Link to={MenuLinks[11].to}>{t("menu-mobile.item2.sub-link4")}</Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[12].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[12].id);
                  closeModal();
                }}>
                <Link to={MenuLinks[12].to}>{t("menu-mobile.item2.sub-link5")}</Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[13].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[12].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[13].to)}>{t("menu-mobile.item2.sub-link6")}</Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[14].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[12].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[14].to)}>{t("menu-mobile.item2.sub-link7")}</Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[15].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[12].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[15].to)}>{t("menu-mobile.item2.sub-link8")}</Link>
              </li>
            </ul>
            {/* Menu */}
            <ul className="menu-pop flex flex-col items-end mr-16">
              <p className="text-sm text-white text-opacity-50">{t("navigation.menu")}</p>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[0].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[0].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[0].to)}>
                  <Trans i18nKey="menu-mobile.item1.nav-link">
                    primera parte<span>siguiente</span>
                  </Trans>
                </Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[2].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[2].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[2].to)}>
                  <Trans i18nKey="menu-mobile.item3.nav-link">
                    primera parte<span>siguiente</span>
                  </Trans>
                </Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[3].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[3].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[3].to)}>
                  <Trans i18nKey="menu-mobile.item4.nav-link">
                    primera parte<span>siguiente</span>
                  </Trans>
                </Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses}  ${MenuLinks[4].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[4].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[4].to)}>
                  <Trans i18nKey="menu-mobile.item5.nav-link">
                    primera parte<span>siguiente</span>
                  </Trans>
                </Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses} ${MenuLinks[5].id}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[5].id);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[5].to)}>
                  <Trans i18nKey="menu-mobile.item6.nav-link">
                    primera parte<span>siguiente</span>
                  </Trans>
                </Link>
              </li>
              <li
                className={`${linkDefaultCSSClasses}  `}
                onClick={() => {
                  closeModal();
                }}>
                <a href={t("menu-mobile.item7.url")} target="_blank" rel="nofollow noreferrer">
                  <Trans i18nKey="menu-mobile.item7.nav-link">
                    primera parte<span>siguiente</span>
                  </Trans>
                </a>
              </li>
              
                <li
              className={`${linkDefaultCSSClasses}  ${MenuLinks[6].to}-link`}
              onClick={() => {
                closeModal();
              }}
            >
              <a href={BLOG_URL} target="_blank" rel="nofollow noreferrer">
                <Trans i18nKey="menu-mobile.item8.nav-link">
                  primera parte<span>siguiente</span>
                </Trans>
              </a>
            </li>
            </ul>
          </div>
        </div>
        {/* Location */}
        <div className="flex justify-between items-center mr-16">
          <div>
            <p className="text-sm text-white text-opacity-50 mb-4">{t("navigation.location")}</p>
            <div className="flex space-x-8 xl:text-lg">
              {_sortCountries(country, LOCATIONS).map((location, index) => (
                <div className="flex flex-col">
                  <div className={`text-white ${index !== 0 && "opacity-70"}`}>{t(location.country)}</div>
                  <div className="text-xs xl:text-sm text-white text-opacity-50 mt-2">{t(location.city)}</div>
                </div>
              ))}
            </div>
          </div>
          <ContactUsButton className="button-main-menu max-h-10" translation="navigation.lets talk" onClick={openContactModal} />
        </div>
      </div>
      {/* Mobile menu */}
      <div className="mobile-menu relative w-full text-right pr-5 pt-20">
        <ul className="menu-pop flex flex-col items-end">
          <li
            className={`${linkDefaultCSSClasses} ${MenuLinks[0].to}-link`}
            onClick={() => {
              //scrollIntoView(MenuLinks[0].to);
              closeModal();
            }}>
            <Link to={t(MenuLinks[0].to)}>
              <Trans i18nKey="menu-mobile.item1.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </Link>
          </li>
          <li className="text-gray-300 lg:text-light text-lg sub-items">
            <div
              className={`${MenuLinks[1].to}-link`}
              onClick={() => {
                //scrollIntoView(MenuLinks[1].to);
                closeModal();
              }}>
              <Link to={t(MenuLinks[1].to)}>
                <Trans i18nKey="menu-mobile.item2.nav-link">
                  primera parte<span>siguiente</span>
                </Trans>
              </Link>
            </div>
            <ul>
              <li
                className={`text-md cursor-pointer ${MenuLinks[1].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[1].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[1].to)}>{t("menu-mobile.item2.sub-link1")}</Link>
              </li>
              <li
                className={`text-md cursor-pointer ${MenuLinks[8].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[8].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[8].to)}>{t("menu-mobile.item2.sub-link2")}</Link>
              </li>
              <li
                className={`text-md cursor-pointer ${MenuLinks[9].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[9].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[9].to)}>{t("menu-mobile.item2.sub-link3")}</Link>
              </li>
              <li
                className={`text-md cursor-pointer ${MenuLinks[10].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[10].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[10].to)}>{t("menu-mobile.item2.sub-link4")}</Link>
              </li>
              <li
                className={`text-md cursor-pointer ${MenuLinks[11].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[11].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[11].to)}>{t("menu-mobile.item2.sub-link5")}</Link>
              </li>
              <li
                className={`text-md cursor-pointer ${MenuLinks[13].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[11].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[13].to)}>{t("menu-mobile.item2.sub-link6")}</Link>
              </li>
              <li
                className={`text-md cursor-pointer ${MenuLinks[14].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[11].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[14].to)}>{t("menu-mobile.item2.sub-link7")}</Link>
              </li>
              <li
                className={`text-md cursor-pointer ${MenuLinks[15].to}-link`}
                onClick={() => {
                  //scrollIntoView(MenuLinks[11].to);
                  closeModal();
                }}>
                <Link to={t(MenuLinks[15].to)}>{t("menu-mobile.item2.sub-link8")}</Link>
              </li>
            </ul>
          </li>
          <li
            className={`${linkDefaultCSSClasses}  ${MenuLinks[2].to}-link`}
            onClick={() => {
              //scrollIntoView(MenuLinks[2].to);
              closeModal();
            }}>
            <Link to={t(MenuLinks[2].to)}>
              <Trans i18nKey="menu-mobile.item3.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </Link>
          </li>
          <li
            className={`${linkDefaultCSSClasses}  ${MenuLinks[3].to}-link`}
            onClick={() => {
              //scrollIntoView(MenuLinks[3].to);
              closeModal();
            }}>
            <Link to={t(MenuLinks[3].to)}>
              <Trans i18nKey="menu-mobile.item4.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </Link>
          </li>
          <li
            className={`${linkDefaultCSSClasses}  ${MenuLinks[4].to}-link`}
            onClick={() => {
              //scrollIntoView(MenuLinks[4].to);
              closeModal();
            }}>
            <Link to={t(MenuLinks[4].to)}>
              <Trans i18nKey="menu-mobile.item5.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </Link>
          </li>
          <li
            className={`${linkDefaultCSSClasses}  ${MenuLinks[5].to}-link`}
            onClick={() => {
              //scrollIntoView(MenuLinks[5].to);
              closeModal();
            }}>
            <Link to={t(MenuLinks[5].to)}>
              <Trans i18nKey="menu-mobile.item6.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </Link>
          </li>
          <li
            className={`${linkDefaultCSSClasses} ${MenuLinks[6].to}-link`}
            onClick={() => {
              //scrollIntoView(MenuLinks[6].to);
              closeModal();
            }}>
            <Link to={t("menu-mobile.item7.url")}>
              <Trans i18nKey="menu-mobile.item7.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </Link>
          </li>
          <li
            className={`${linkDefaultCSSClasses} ${MenuLinks[6].to}-link`}
            onClick={() => {
              //scrollIntoView(MenuLinks[6].to);
              closeModal();
            }}>
            <Link to={t("menu-mobile.item10.url")}>
              <Trans i18nKey="menu-mobile.item10.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </Link>
          </li>
          <li
            className={`${linkDefaultCSSClasses} ${MenuLinks[7].to}-link`}
            onClick={() => {
              closeModal();
            }}
          >
            <a href={BLOG_URL} target="_blank" rel="nofollow noreferrer">
              <Trans i18nKey="menu-mobile.item8.nav-link">
                primera parte<span>siguiente</span>
              </Trans>
            </a>
          </li>
        </ul>
        <div className="flex flex-row justify-end pt-5">
          <ContactUsButton className="button-main-menu max-h-10 flex justify-center" translation="navigation.lets talk" onClick={openContactModal} />
        </div>
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  @media screen and (min-width: 1024px) {
    .mobile-menu {
      display: none;
    }
    .button-background {
      background-color: #2e2e2e !important;
    }
  }
  @media screen and (max-width: 1023px) {
    .desktop-menu {
      display: none;
    }
    .mobile-menu {
      display: block;
    }
    .menu-items {
      background-image: unset !important;
    }
    .button-background {
      background-color: #2e2e2e !important;
    }
    .contact-us-bottom {
      max-height: 3rem;
      line-height: 1rem !important;
      height: 3rem !important;
      padding: 1rem !important;
    }
  }
  & {
    .top-level-link,
    .why-us-section-link {
      display: inline-flex;
      align-items: center;
      position: relative;
    }
    .top-level-link:after {
      content: "";
      position: absolute;
      width: 0;
      bottom: 0;
      right: 0;
      height: 1px;
      color: #fff;
      transition: 0.3s;
      opacity: 0;
      background-color: rgba(154, 154, 154);
    }
    .top-level-link:hover {
      transition: 0.3s;
      color: #fff;
      &:after {
        width: 1.5em;
        opacity: 1;
        background-color: #fff;
      }
    }
  }
  .menu-pop li {
    margin-bottom: 0.3rem;

    &.active,
    div.active {
      color: #ffffff;
      span {
        display: inline-block;
        padding-left: 80px;
        position: relative;

        &:before {
          content: "";
          width: 61px;
          height: 1px;
          background-color: #fff;
          position: absolute;
          bottom: 10px;
          right: 30px;
        }
      }
    }

    span {
      display: inline-block;
      padding-left: 15px;
    }
  }

  .sub-items ul li {
    padding-right: 40px;
    margin-bottom: 0.5rem;
  }

  .menu-pop {
    // width: 100%;
  }

  .sub-items ul li:first-child {
    margin-top: 15px;
  }
  .close-pop {
    position: relative;
    right: 0;

    &:before {
      content: "";
      width: 20px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      transform: rotate(45deg);
      top: -50px;
      right: 0px;
    }

    &:after {
      content: "";
      width: 20px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      transform: rotate(315deg);
      top: -50px;
      right: 0px;
    }
  }
`;
