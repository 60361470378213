import { forwardRef } from "react";

const defaultCSSClasses = "panel-background-wrapper max-w-none lg:min-h-unset w-full h-full lg:w-auto absolute inset-0";

const PanelBackgroundWrapper = forwardRef(({ children, className = null, id = null }, ref) => (
  <div ref={ref} id={id} className={`${defaultCSSClasses} ${className ? ` ${className}` : ""}`}>
    {children}
  </div>
));

export default PanelBackgroundWrapper;
