import {Link} from 'react-router-dom';
const defaultClasses = "footer-link inline-block text-dark-grey mb-1 lg:mb-3 text-base text-left"
export default function FooterLink(props) {
  if (props["href"]) {
    if (props["href"].startsWith("http")) {
      return (
        <a {...props} className={`${defaultClasses} ${props.className} `}>
          {props.label}
        </a>   
      )
    }
  }
  if (props["to"]) {
    return (
      <Link {...props} className={`${defaultClasses} ${props.className} `}>
        {props.label}
      </Link>   
    )
  }
  return (
      <button {...props} className={`${defaultClasses} ${props.className} `}>
        {props.label}
      </button>
    );
  
}
