import { responsiveSection3 } from "./digital-marketing-animation";
import { responsiveSection2Slider } from "./high-converting-ecommerce-animation";
//import { responsiveSection1Video, responsiveSection1VideoAnimationControl } from "./performance-consulting-animation";
import { responsiveSection1SpriteVideo, responsiveSection1VideoAnimationControlSprite } from "./performance-consulting-sprite-animation";
import { responsiveSection4 } from "./marketplace-animation";
import { responsiveSection5 } from "./b2b-marketing-animation";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);



export function ResponsiveServicesAnimationSequence(q) {
  responsiveSection1SpriteVideo(q)
  responsiveSection1VideoAnimationControlSprite(q)
  responsiveSection2Slider(q)
  responsiveSection3(q)
  responsiveSection4(q)
  responsiveSection5(q)
}