import React from "react"; //importamos react
import styled from "styled-components"; //permite crear estilos para este archivo
import ServicesImageMask from "assets/Performly_servicios-top-para-todos.webp";

import panel3Dashboard from "assets/digital-marketing/dashboard@1x.webp";
import panel3Circle1 from "assets/digital-marketing/circle-1.svg";
import panel3Circle2 from "assets/digital-marketing/circle-2.svg";
import panel3Circle3 from "assets/digital-marketing/circle-3.svg";
import panel3Woman from "assets/digital-marketing/woman@1x.webp";
import panel3GooglePointer from "assets/digital-marketing/pointer google ads@1x.webp";
import panel3FacebookPointer from "assets/digital-marketing/pointer facebook@1x.webp";
import panel3Facebooklogo from "assets/digital-marketing/facebook@1x.webp";
import panel3Googlelogo from "assets/digital-marketing/Google Ads Icon@1x.webp";
import panel3TourismPill from "assets/digital-marketing/pildora turismo@1x.webp";
import panel3_25_35Pill from "assets/digital-marketing/pildora-25-35@1x.webp";
import panel3RemarketingPill from "assets/digital-marketing/pildora-remarketing@1x.webp";

import panel4MarketPlace from "assets/marketplace-performance/Market Place@1x.webp";
import panel4Flecha from "assets/flecha servicios.webp";

import panel5Database from "assets/b2b marketing/Base de datos@1x.webp";
import panel5Profile from "assets/b2b marketing/LinkedIn@1x.webp";
import panel5Landing from "assets/b2b marketing/landing@1x.webp";
import Panel1Background from "../components/panel-performance-consulting/PanelBackground";
import PerformanceConsultingContent from "../components/panel-performance-consulting/PanelContent";
import HighPerformanceEcommerceContent from "../components/high-performance-ecommerce/PanelContent";
import Panel2Background from "../components/high-performance-ecommerce/PanelBackground";
import Panel2Images from "../components/high-performance-ecommerce/PanelImages";
import Panel3Background from "../components/digital-marketing-campaign/PanelBackground";
import DigitalMarketingContent from "../components/digital-marketing-campaign/PanelContent";
import MarketPlacePerformanceContent from "../components/marketplace performance/PanelContent";
import B2BMarketingContent from "../components/b2b-marketing/PanelContent";
import PanelImagesWrapper from "../components/PanelImagesWrapper";
import Panel4Background from "../components/marketplace performance/PanelBackground";
import Panel5Background from "../components/b2b-marketing/PanelBackground";
import panel5Person from "assets/b2b marketing/persona-b2b-marketing.webp";
import Panel6Background from "../components/seo-web/PanelBackground";
import panel6person from "assets/seo web/persona.png";
import panel6over from "assets/seo web/over.png";
import panel6search from "assets/seo web/search.png";
import SEOWebContent from "../components/seo-web/PanelContent";


import isotipoBetweenSections from "assets/corazón entre secciones@1x.webp";
import { PointerDot } from "../components/digital-marketing-campaign/PointerDot";
import { ProductStripe } from "../components/marketplace performance/ProductStripe";
import { useTranslation } from "react-i18next";

export default function ServicesDesktop({ id = null }) {
  const { t } = useTranslation("common");
  return (
    <StyledSectionDesktop id={id} className="hidden md:block relative">
      <div className="absolute z-0 h-[50vh] w-screen bottom-[50%] left-0 bg-secondary" />
      <div className="absolute z-0 inset-0 bg-extra-1" />
      <div id="isotipo-container" className="hidden lg:block isotipo-container xl:w-screen lg:right-0 top-0 bottom-0 lg:left-5/12 3xl:left-6/12 lg:absolute">
        <div className="isotipo-wrapper absolute">
          <img src={isotipoBetweenSections} className="h-full w-auto w-max-none" alt="Isotype" />
        </div>
      </div>
      <div id="panel-images-container" className="service-background">
        <div id="panel-images-wrapper" className="">
          <div id="panel-backgrounds" className="">
            <Panel1Background id="panel-1-background" />
            <Panel2Background id="panel-2-background" />
            <Panel3Background id="panel-3-background" />
            <Panel4Background id="panel-4-background" />
            <Panel5Background id="panel-5-background" />
            <Panel6Background id="panel-6-background" />
          </div>
          <div id="panel-mask" className="">
            <img src={ServicesImageMask} className="" alt="Services Mask" />
          </div>
        </div>

        <Panel2Images id="panel-2-over-the-mask" className="invisible" />

        <PanelImagesWrapper id="panel-3-over-the-mask" className="invisible pointer-events-none">
          <div className="panel-3-woman-wrapper">
            <div className="panel-3-woman ">
              <div className="panel-3-circles-wrapper flex justify-center items-center absolute">
                <img src={panel3Circle1} className="rotating-1 absolute" alt="Rotating circle 1" />
                <img src={panel3Circle2} className="rotating-2 absolute" alt="Rotating circle 2" />
                <img src={panel3Circle3} className="rotating-3 absolute" alt="Rotating circle 3" />
              </div>
              <img src={panel3Woman} className="relative panel-3-woman-image" alt={t("img-alts.lead-generation-campaigns")} />
            </div>
          </div>
          <div className="panel-3-dashboard-wrapper">
            <div className="panel-3-dashboard w-4/12">
              <img src={panel3Dashboard} id="panel-3-dashboard-frame" alt={t("img-alts.digital-marketing-campaigns")} className="object-contain w-full h-auto" />
              <div className="inset-0 absolute">
                <div className="panel-3-google-pointer-wrapper">
                  <div className="animated-green-pointer-dot animated-dot">
                    <PointerDot id="green-pointer-dot" className="" color="green" />
                  </div>
                  <img src={panel3GooglePointer} className="panel-3-google-pointer" alt="img-alts.google-campaigns" />
                  <img src={panel3Googlelogo} className="panel-3-google-logo" alt="img-alts.google-ads-campaigns" />
                  <img src={panel3TourismPill} className="panel-3-pildora-turismo" alt="img-alts.we-improve-your-roas" />
                  <img src={panel3_25_35Pill} className="panel-3-pildora-25-35" alt="img-alts.we-improve-your-roi" />
                </div>
                <div className="panel-3-facebook-pointer-wrapper">
                  <div className="animated-blue-pointer-dot animated-dot">
                    <PointerDot id="blue-pointer-dot" className="" color="blue" />
                  </div>
                  <img src={panel3FacebookPointer} className="panel-3-facebook-pointer" alt="img-alts.facebook-campaigns" />
                  <img src={panel3Facebooklogo} className="panel-3-facebook-logo" alt="img-alts.facebook-ads-campaigns" />
                  <img src={panel3RemarketingPill} className="panel-3-pildora-remarketing" alt="img-alts.we-increment-your-roas" />
                </div>
              </div>
            </div>
          </div>
        </PanelImagesWrapper>

        {/**
         * panel 4 over the Mask
         */}
        <PanelImagesWrapper id="panel-4-over-the-mask" className="invisible pointer-events-none">
          <div className="panel-4-images-wrapper flex flex-row items-center justify-start space-x-2">
            <div className="hidden lg:block panel-4-marketplace">
              <img src={panel4MarketPlace} alt="Marketplace Performance" />
            </div>
            <div className="hidden lg:block panel-4-flecha text-white">
              <img src={panel4Flecha} alt="Arrow" />
            </div>
            <div className="hidden lg:block panel-4-tira1">
              <ProductStripe />
            </div>
            <div className="hidden lg:block panel-4-tira-con-carrito">
              <ProductStripe direction="up" withCart />
            </div>
            <div className="lg:hidden panel-4-tira-responsive">
              <ProductStripe responsive />
            </div>
          </div>
        </PanelImagesWrapper>
        <PanelImagesWrapper id="panel-5-over-the-mask" className="invisible pointer-events-none">
          <div className="panel-5-images-wrapper block lg:flex lg:flex-row items-center justify-start">
            <div className="hidden lg:block panel-5-database">
              <img src={panel5Database} alt="img-alts.b2b-campaigns" />
            </div>
            <div className="hidden lg:block panel-5-flecha">
              <img src={panel4Flecha} alt="img-alts.business-to-business-campaigns" />
            </div>
            <div className="hidden lg:block panel-5-profile">
              <img src={panel5Profile} alt="img-alts.b2b-digital-campaigns" />
            </div>
            <div className="hidden lg:block panel-5-flecha">
              <img src={panel4Flecha} alt="img-alts.business-to-business-marketing" />
            </div>
            <div className="panel-5-landing absolute lg:relative ">
              <img src={panel5Landing} alt="img-alts.business-to-business-digital-marketing" />
            </div>
            <div className="panel-5-persona lg:hidden absolute left-35/100 bottom-n-15/100 right-n-10/100">
              <img src={panel5Person} alt="img-alts.b2b-digital-marketing" />
            </div>
          </div>
        </PanelImagesWrapper>
        <PanelImagesWrapper id="panel-6-over-the-mask" className="invisible pointer-events-none">
          <div className="panel-6-images-wrapper block lg:flex lg:flex-row items-center justify-start">
            <div className="hidden lg:block panel-6-over">
              <img src={panel6over} alt="img-alts.business-to-business-campaigns" />
            </div>
            <div className="hidden lg:block panel-6-person">
              <img src={panel6person} alt="img-alts.b2b-campaigns" />
            </div>
            <div className="hidden lg:block panel-6-search">
              <img src={panel6search} alt="img-alts.b2b-campaigns" />
            </div>
          </div>
        </PanelImagesWrapper>
      </div>
      <div id="services-content-container" className="container ">
        <div className="pagination hidden absolute lg:flex top-0 lg:flex-col">
          <div id="services-paginator" className="pagination-wrapper">
            {[1, 2, 3, 4, 5, 6].map((item, index) => (
              <div key={item} className={`pagination-item flex flex-col items-center `}>
                {/* <div key={item} className={`pagination-item flex flex-col items-center ${activeSection === index ? "active" : "inactive"}`}> */}
                <div className="pagination-item-number ">{item}</div>
                <div className="pagination-spacer" />
              </div>
            ))}
          </div>
        </div>
        <div id="services-content-wrapper" className="service-content-container-inner ">
          {/**
           * Panel 1 Content
           */}
          <PerformanceConsultingContent />
          {/**
           * Panel 2 Content
           */}
          <HighPerformanceEcommerceContent id="panel-2-content-container" />
          {/**
             * Panel 3 Content

            */}
          <DigitalMarketingContent />

          {/**
           * Panel 4 Content
           */}
          <MarketPlacePerformanceContent />

          {/**
           * Panel 5 Content
           
          */}
          <B2BMarketingContent />

          {/**
           * Panel 6 Content
           
          */}
          <SEOWebContent />
        </div>
      </div>
    </StyledSectionDesktop>
  );
}

const StyledSectionDesktop = styled.div`
  #success-cases-section {
    @media screen and (min-width: 2000px) {
      margin-top: 12rem !important;
    }
  }
  //Section container
  & {
    --section-height: 670px;
    --section-background: rgba(191, 191, 191, 1);
    --section-background-transparent: rgba(191, 191, 191, 0);
    @media screen and (max-width: 1023px) {
      --panel-images-wrapper-left: 0;
    }
    @media screen and (min-width: 1024px) {
      --panel-images-wrapper-left: calc(100% / 12 * 5);
    }
    @media screen and (min-width: 1600px) {
      --panel-images-wrapper-left: calc(100% / 12 * 6);
    }

    //min-h-screen max-h-screen lg:min-h-670  xl:bg-extra-1 relative"
    background: var(--section-background);
    @media screen and (max-width: 1023px) {
      /* height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      max-height: 100vh;
      max-height: calc(var(--vh, 1vh) * 100); */
      height: 100vh;
    }
    @media screen and (min-width: 1023px) {
      min-height: var(--section-height);
    }
  }
  #services-content-container {
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    z-index: 51;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      top: 0;
    }
    @media screen and (max-width: 1023px) {
      min-width: 100vw;
      left: 50%;
      transform: translatex(-50%);
      bottom: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      top: 40%;
      background: linear-gradient(180deg, var(--section-background-transparent) 0%, var(--section-background) 16%);
    }
    //overflow-hidden container relative md:-mt-16 lg:mt-0 mx-0 lg:mx-auto min-h-screen   lg:min-h-670 pointer-events-none md:flex md:flex-row justify-center lg:justify-start items-stretch z-50
    @media screen and (min-width: 1024px) {
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: stretch;
      justify-content: start;
      left: 50%;
      transform: translateX(-50%);
    }
    #services-content-wrapper {
      //service-content-container-inner md:mx-0 w-full min-h-screen md:min-h-unset md:h-auto md:w-3/4 lg:w-5/12 flex justify-end items-stretch relative z-50
      position: relative;
      height: 100%;
      display: flex;
      @media screen and (min-width: 1024px) {
        width: calc(100% / 12 * 5);
      }
    }
    .panel-content-wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      height: 100%;
      width: 100%;
      bottom: 0;
      @media screen and (max-width: 767px) {
        padding: 0 1.5rem;
        background: #171717;
        border-radius: 1.5rem 1.5rem 0 0;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding: 8rem 0 0;
        & .panel-content-title * {
          display: inline !important;
          white-space: nowrap;
        }
      }
      @media screen and (min-width: 1024px) {
        padding: 2.5rem 0;
      }
    }
    .panel-content-wrapper:first-child {
      display: flex;
    }
  }
  #isotipo-container {
    //Isotipo that bleeds between sections
    //hidden lg:block isotipo-container xl:w-screen lg:right-0 top-0 bottom-0 lg:left-5/12 3xl:left-6/12 lg:absolute
    position: absolute;
    left: var(--panel-images-wrapper-left);
    & img {
      object-fit: cover;
      max-width: none;
    }
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  #panel-images-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      height: 60%;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      height: 50%;
    }
    @media screen and (min-width: 1024px) {
      height: 100%;
    }
    //md:w-full top-0 left-0 right-0 min-h-60vh lg:min-h-unset lg:inset-0 xl:overflow-hidden
    #panel-images-wrapper {
      position: absolute;
      top: 0;
      left: var(--panel-images-wrapper-left);
      width: 100%;
      height: 100%;
      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        overflow: hidden;
      }
      //xl:w-screen inset-0 lg:left-5/12 3xl:left-6/12 lg:absolute
    }
    #panel-backgrounds {
      //panel background
      //panel-images min-h-60vh lg:min-h-full w-full absolute
      position: absolute;
      height: 100%;
      width: 100%;
      & img,
      & video {
        object-fit: cover;
        max-width: none;
        width: 100%;
        height: 100%;
        object-position: left;
        @media screen and (min-width: 1440px) {
          width: auto;
        }
      }
      & > *:not(:first-child) {
        opacity: 0;
        visibility: hidden;
      }
    }

    #panel-mask {
      //Overlay mask over panel background
      //object:cover lg:object-contained h-full max-w-none z-50
      position: absolute;
      inset: 0;
      @media screen and (max-width: 767px) {
        display: none;
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
          max-width: none;
          width: auto;
        }
      }
      @media screen and (min-width: 1024px) {
        img {
          object-fit: contain;
          z-index: 10;
          max-width: none;
          width: auto;
          height: 100%;
        }
      }
    }

    .panel-images-wrapper-inner {
      //top-0 right-0 bottom-0 left-0  lg:h-min-unset lg:bottom-0  lg:left-5/12 3xl:left-6/12 absolute
      inset: 0;
      position: absolute;
      left: var(--panel-images-wrapper-left);
    }
    #panel-2-over-the-mask {
      .panel-2-desktop {
        position: relative;
      }
      //revisar
      .panel-2-app-wrapper {
        pointer-events: none;
        position: absolute;
        @media screen and (max-width: 767px) {
          width: 73%;
          left: 18%;
          top: 30%;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          width: 70%;
          left: 21%;
          top: 14%;
        }
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          width: 44%;
          left: 29%;
          top: 52%;
        }
        @media screen and (min-width: 1280px) {
          width: 48%;
          left: 50%;
          top: 50%;
        }
        @media screen and (min-width: 1440px) {
          transform: translate(-15%, -22%);
          width: 48%;
        }
        @media screen and (min-width: 2000px) {
          transform: translate(2%, -21%);
          width: 36%;
          left: 36%;
        }
      }
      //Terminar Revisar
      @media screen and (min-width: 768px) and (max-width: 1279px) {
        width: 60%;
        right: unset;
        left: 23%;
        top: 25%;
      }
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        margin-left: 5%;
        width: 70%;
        left: 40%;
        top: -7%;
      }
      @media screen and (min-width: 1280px) and (max-width: 1439px) {
        margin-left: 12%;
        width: 64%;
        & .panel-2-app-wrapper {
          width: 44%;
          left: 29%;
          top: 40%;
        }
      }

      #panel-2-desktop-frame {
        box-shadow: 0px 2.8px 7.3px rgba(0, 0, 0, 0.048), 0px 6.7px 17.4px rgba(0, 0, 0, 0.069), 0px 12.5px 32.8px rgba(0, 0, 0, 0.085), 0px 22.3px 58.5px rgba(0, 0, 0, 0.101), 0px 41.8px 109.5px rgba(0, 0, 0, 0.122), 0px 100px 262px rgba(0, 0, 0, 0.17);
      }
      #panel-2-paginator {
        position: absolute;
        @media screen and (max-width: 767px) {
          left: 10%;
          width: 67%;
          top: -26%;
          height: 0;
          overflow: visible;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          left: 105%;
          width: 67%;
          top: 20%;
          height: 0;
          overflow: visible;
        }
        @media screen and (min-width: 1024px) {
          top: 0;
          left: 66%;
          width: 67%;
          top: -30%;
          height: 0;
          overflow: visible;
        }
        @media screen and (min-width: 1280px) {
          top: -27%;
        }
        @media screen and (min-width: 1440px) {
          position: absolute;
          top: 0;
          left: 100%;
          width: 67%;
          top: 4.9%;
          height: 0;
          overflow: visible;
          border-top: 1px solid rgba(191, 221, 228, 1);
        }
        @media screen and (min-width: 1920px) {
          left: 100%;
          width: 78%;
          top: 4.9%;
          height: 0;
        }
      }
      .paginator-slide-name {
        position: absolute;
        white-space: nowrap;
        @media screen and (max-width: 767px) {
          position: absolute;
          left: calc(100% + 2em);
          top: 50%;
          transform: translateY(-50%);
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          top: calc(100% + 1rem);
          transform: translatex(-50%);
          left: 50%;
        }
        @media screen and (min-width: 1024px) and (max-width: 1439px) {
          top: 50%;
          transform: translateY(-50%);
          right: 125%;
        }
        @media screen and (min-width: 1440px) {
          position: absolute;
          top: calc(100% + 1em);
        }
      }
      .panel-2-paginator-content {
        width: 40%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        .panel-2-paginator-controls {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          .panel-paginator-display {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(191, 221, 228, 1);
            border-radius: 500rem;
          }
          .panel-paginator-arrow {
          }
          .panel-paginator-arrow.right {
            transform: rotate(180deg);
          }
        }
      }
    }
    #panel-3-over-the-mask {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        left: 46%;
        top: 0;
        bottom: 0;
        right: 8%;
        padding-left: 0;
        & .panel-3-dashboard-wrapper {
          transform: translateX(-20%);
        }
      }
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        width: 80%;
        margin-left: 5%;
        & .panel-3-dashboard-wrapper {
        }
      }

      & .panel-3-woman-wrapper {
        position: absolute;
        bottom: 0;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          right: -8%;
          width: 67%;
        }
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          right: 12%;
          width: 48%;
        }
        @media screen and (min-width: 1280px) and (max-width: 1439px) {
          right: -5%;
          width: 48%;
        }
        @media screen and (min-width: 1440px) and (max-width: 1920px) {
          right: -12%;
          width: 48%;
        }
        @media screen and (min-width: 1920px) {
          right: -12%;
          width: 48%;
        }
      }
      & .panel-3-woman {
        position: absolute;
        bottom: 0;
        //padding-top: 200%;
        @media screen and (max-width: 1023px) {
        }
        @media screen and (min-width: 1280px) {
        }
        @media screen and (min-width: 1440px) {
        }
        @media screen and (min-width: 1920px) {
        }
      }
      & .panel-3-woman-image {
        object-fit: contain;
        object-position: bottom;
        height: 100%;
      }

      & .panel-3-circles-wrapper {
        @media screen and (max-width: 767px) {
          bottom: 89%;
          left: 29%;
          width: 54%;
          height: 0;
          transform: translateX(-50%);
        }
        @media screen and (min-width: 768px) {
          bottom: 89%;
          left: 29%;
          width: 54%;
          height: 0;
          transform: translateX(-50%);
        }
      }
      .panel-3-dashboard {
        border-radius: 0.25rem;
        box-shadow: 0px 2.8px 7.3px rgba(0, 0, 0, 0.048), 0px 6.7px 17.4px rgba(0, 0, 0, 0.069), 0px 12.5px 32.8px rgba(0, 0, 0, 0.085), 0px 22.3px 58.5px rgba(0, 0, 0, 0.101), 0px 41.8px 109.5px rgba(0, 0, 0, 0.122), 0px 100px 262px rgba(0, 0, 0, 0.17);
        .panel-3-google-pointer-wrapper,
        .panel-3-facebook-pointer-wrapper {
          //box-shadow: 0px 2.8px 7.3px rgba(0, 0, 0, 0.048), 0px 6.7px 17.4px rgba(0, 0, 0, 0.069), 0px 12.5px 32.8px rgba(0, 0, 0, 0.085), 0px 22.3px 58.5px rgba(0, 0, 0, 0.101), 0px 41.8px 109.5px rgba(0, 0, 0, 0.122), 0px 100px 262px rgba(0, 0, 0, 0.17);
        }
      }
    }
    #panel-4-background {
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        transform: translateX(-25%);
      }
      @media screen and (min-width: 1280px) and (max-width: 1439px) {
        transform: translateX(-12%);
      }
    }
    #panel-4-over-the-mask {
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        & .panel-4-marketplace,
        .panel-4-tira-con-carrito {
          width: 40%;
          padding-right: 0.5rem;
        }
        & .panel-4-tira1 {
          display: none;
        }
      }
    }
    #panel-5-background {
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        transform: translateX(-31%);
      }
      @media screen and (min-width: 1280px) and (max-width: 1439px) {
        transform: translateX(-21%);
      }
    }
    #panel-5-over-the-mask {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 50%;
        left: 45%;
        transform: translatex(-50%);
        .panel-5-persona {
          display: none;
        }
      }
      @media screen and (min-width: 1024px) and (max-width: 1439px) {
        width: 35%;
        left: 44%;
        transform: translateY(-10%);
        .panel-5-persona {
          display: none;
        }
      }
    }
  }

  .service-background {
    z-index: 50;
  }
  .pagination {
    height: 7rem;
    overflow: hidden;
    top: 3rem;
    @media screen and (min-width: 1024px) {
      left: 3%;
      top: 3rem;
    }
    @media screen and (min-width: 1440px) {
      left: 3%;
      top: 3rem;
    }

    & .pagination-item-number {
      color: #757575;
      &.active {
        color: #2e2e2e;
      }
    }
    & .pagination-spacer {
      margin: 0.5rem 0;
      height: 3rem;
      width: 1px;
      background: #757575;
    }
    .pagination-item:last-child .pagination-spacer {
      background: linear-gradient(180deg, rgba(117, 117, 117, 1) 0%, rgba(117, 117, 117, 0) 100%);
    }
  }
  .isotipo-container {
    z-index: 5;
  }
  .isotipo-wrapper {
    height: 196.5%;
    transform: translate(0.25%, -12.3%);
  }
  .background-before-section,
  .background-after-section {
    height: 50vh;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .background-before-section {
    bottom: 100%;
  }
  .background-after-section {
    top: 100%;
  }
  &:before {
    content: "";
  }
  &:after {
    content: "";
  }
  .panel-3-dashboard-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;
  }
  .panel-3-dashboard {
    position: absolute;
    @media screen and (max-width: 1023px) {
      width: 80%;
      transform: translatex(-60%);
    }
    @media screen and (min-width: 1280px) {
      position: absolute;
      width: 40%;
      left: calc(100% / 12);
    }
    @media screen and (min-width: 1440px) {
    }
  }
  .panel-3-google-logo,
  .panel-3-facebook-logo {
    position: absolute;
  }
  .panel-3-google-logo {
    width: 64%;
    top: -16%;
    right: -19px;
  }
  .panel-3-facebook-logo {
    width: 58%;
    right: -9%;
    top: -10%;
  }
  .panel-3-google-pointer-wrapper,
  .panel-3-facebook-pointer-wrapper {
    position: absolute;
    left: 100%;
    width: 30%;
  }
  .panel-3-pildora-turismo {
    position: absolute;
    top: 47%;
    width: 42%;
    right: -18%;
  }
  .panel-3-pildora-25-35 {
    position: absolute;
    top: 64%;
    width: 34%;
    right: -39%;
  }
  .panel-3-pildora-remarketing {
    position: absolute;
    top: 38%;
    width: 41%;
    right: -17%;
  }
  .panel-3-google-pointer-wrapper {
    bottom: 52%;
  }
  .panel-3-facebook-pointer-wrapper {
    top: 52%;
  }
  .panel-3-google-pointer {
    top: -12%;
  }

  .panel-4-marketplace,
  .panel-4-tira1 {
    width: calc(100% / 4);
    padding-right: 1em;
  }
  .panel-4-tira-con-carrito {
    @media screen and (max-width: 1023px) {
      width: 75%;
    }
    @media screen and (min-width: 1024px) {
      width: calc(100% / 4 + 1.4%);
      padding-right: 1em;
    }
  }
  .panel-4-flecha {
    width: 1rem;
    height: auto;
    padding-right: 1rem;
  }
  .panel-4-flecha {
    width: 2rem;
  }
  .panel-4-images-wrapper {
    position: absolute;
    top: 50%;
    left: calc(100% / 12);
    transform: translatey(-50%);
    overflow: visible;
    height: 100%;
  }
  .panel-5-images-wrapper {
    position: absolute;
    @media screen and (max-width: 1023px) {
      inset: 0;
    }
    @media screen and (min-width: 1024px) {
      top: 50%;
      left: calc(100% / 12);
      transform: translatey(-50%);
      overflow: visible;
      height: 100%;
    }
  }
  .panel-5-database {
    width: calc(100% / 3.5);
    padding-right: 1em;
  }
  .panel-5-profile {
    width: calc(100% / 4);
    padding-right: 1em;
  }
  .panel-5-landing {
    @media screen and (max-width: 1023px) {
      left: 35%;
      transform: translatex(-50%);
      bottom: 50%;
      width: 20%;
    }
    @media screen and (min-width: 1024px) {
      width: calc(100% / 3.5);
      padding-right: 1em;
    }
  }
  .panel-5-flecha {
    width: 2rem;
  }

  .animated-green-pointer-dot {
    position: absolute;
    width: 31%;
    left: -17px;
    top: 73%;
    transform: translate(-50%, -50%);
  }
  .animated-blue-pointer-dot {
    position: absolute;
    width: 31%;
    left: -17px;
    top: 0%;
    transform: translate(-50%, -50%);
  }
  .panel-6-over {
    position: absolute;
    right: calc(70% - 250px);
    top: calc(50% - 225px);

    @media (max-width: 1500px) {
      right: calc(67% - 250px);
    }

    @media (max-width: 1366px) {
      img {
        max-width: 440px;
      }
    }
  }
  .panel-6-person {
    position: absolute;
    bottom: 0;
    right: calc(35% - 250px);

    @media (max-width: 1500px) {
      right: calc(30% - 250px);
    }

    @media (max-width: 1366px) {
      img {
        max-width: 450px;
      }
    }
  }
  .panel-6-search {
    position: absolute;
    top: calc(50% - 50px);
    right: calc(40% - 91px);
    
    @media (max-width: 1500px) {
      right: calc(35% - 91px);
    }

    @media (max-width: 1366px) {
      img {
        max-width: 150px;
      }
    }

    @media (max-width: 1100px) {
      top: 50%;
    }
  }

`;
