import React from "react"; //importamos react
import { useTranslation } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import google from "assets/partners/Google-Partner-website-2022.svg";
import facebook from "assets/fb-marketing.svg";
//import marketo from "assets/marketo.svg";
import amazonAds from "assets/partners/amazon-ads-1.webp";
import mediamath from "assets/media-math.svg";
import shopify from "assets/shopify.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import SwiperCore, { Pagination } from "swiper";
import FadeInPlace from "components/animations/FadeInPlace";
import FadeFromLeft from "components/animations/FadeFromLeft";

SwiperCore.use([Pagination]);

export default function OurPartners({ id = null }) {
  const { t } = useTranslation("common");

  const PartnersContent = [
    {
      logo: google,
      name: t("our-partners.google.title"),
      caption: t("our-partners.google.caption"),
      imageAlt: "img-alts.google-partner-agency",
    },
    {
      logo: facebook,
      name: t("our-partners.facebook.title"),
      caption: t("our-partners.facebook.caption"),
      imageAlt: "img-alts.facebook-partner-agency",
    },
    {
      logo: amazonAds,
      name: t("our-partners.amazonAds.title"),
      caption: t("our-partners.amazonAds.caption"),
      imageAlt: "img-alts.amazon-partner-agency",
    },
    {
      logo: mediamath,
      name: t("our-partners.mediamath.title"),
      caption: t("our-partners.mediamath.caption"),
      imageAlt: "img-alts.media-math-partner-agency",
    },
    /*     {
      logo: marketo,
      name: t("our-partners.marketo.title"),
      caption: t("our-partners.marketo.caption"),
      imageAlt: ""
    }, */
    {
      logo: shopify,
      name: t("our-partners.shopify.title"),
      caption: t("our-partners.shopify.caption"),
      imageAlt: "img-alts.shopify-partner-agency",
    },
  ];

  const Partner = ({ partnerInfo, className }) => {
    return (
      <div className={`${className} mb-10 partner-wrapper relative`}>
        <div data-aos="fade-right" data-aos-delay="300" className="h-24 flex justify-start items-center lg:h-24 mb-4">
          <FadeFromLeft>
            <img src={partnerInfo.logo} alt={partnerInfo.imageAlt} width={105} />
          </FadeFromLeft>
        </div>
        <FadeInPlace>
          <h4 className="text-dark-medium text-md font-semibold mt-3">{partnerInfo.name}</h4>
          <p className="text-dark-grey text-base w-p mt-5 h-fijo font-light">{partnerInfo.caption}</p>
        </FadeInPlace>
      </div>
    );
  };

  return (
    <StyledDiv id={id} className="bg-secondary lg:pt-52 pt-24 lg:pb-40 pb-24 relative">
      {/* <img className="absolute size-ultra right-0 block 2xl:block 2xl:-right-16 2xl:top-32" src={LogoPartners} width="770" height="669" alt="Logo our-partners" /> */}
      <div className="container">
        <div className="relative">
          {/* <img className="absolute lg:-right-56 lg:t-88 mobile-logo md:w-72 hidden md:-right-8 md:-top-8 2xl:hidden size-normal" src={LogoPartners} width="532" height="436" alt="Logo our-partners" /> */}
          <div data-aos="fade-down" className="lg:absolute top-0 right-0 lg:w-4/12 w-full min-w-full lg:min-w-min order-1 lg:order-last md:w-6/12 md:mb-20 md:px-0">
            <h3 className="text-dark-head text-3xl font-light leading-none whitespace-pre-wrap hidden lg:block">
              <div className="reveal-animation-title" dangerouslySetInnerHTML={{ __html: t("our-partners.title") }}></div>
            </h3>
            <h3 className="text-grey-6 text-xl font-light leading-none whitespace-pre-wrap block lg:hidden">
              <div className="reveal-animation-title">{t("our-partners.title-mobile")}</div>
            </h3>
            <p className="reveal-animation-subtitle lg:text-white text-grey-5 uppercase lg:text-base mt-5 text-sm">{t("our-partners.content")}</p>
          </div>
          <div className="hidden md:flex flex-row  w-full relative partners-wrapper tiny-line flex-wrap">
            {PartnersContent.map((partner, index) => (
              <React.Fragment key={"partner" + index}>
                {index === 2 && <div className="w-4/12 hidden lg:block" />}
                <Partner partnerInfo={partner} className="mt-14 md:mt-0 lg:mt-2 md:mb-16 w-6/12 lg:w-4/12" />
              </React.Fragment>
            ))}
          </div>
          <Swiper
            className="md:hidden"
            modules={[EffectFade]}
            effect="fade"
            pagination={{
              clickable: true,
            }}>
            {PartnersContent.map((partner, index) => (
              <SwiperSlide key={index}>
                <Partner partnerInfo={partner} className="lg:w-4/12 w-full lg:w-auto min-w-min min-w-full order-2 mt-14 md:mt-0 lg:mt-2 md:w-6/12 " />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .swiper-container {
    overflow: visible;
  }
  .swiper-pagination {
    position: absolute;
    z-index: 5;
    bottom: 0px;
  }

  .partner-wrapper:nth-of-type(4n - 2):before {
    @media screen and (min-width: 1024px) {
      content: "";
      width: 100vw;
      height: 1px;
      right: 25%;
      position: absolute;
      background-color: #464646;
      top: 6rem;
    }
  }
  .swiper-pagination-bullet {
    display: inline-block;
    background-color: rgba(196, 196, 196, 1);
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    transition: width 0.4s;
    border-radius: 100rem;
    position: relative;
    &.swiper-pagination-bullet-active {
      background-color: white;
      width: 1.25rem;
    }
  }
  .size-ultra {
    width: 277px;
    height: 251px;
    right: -90px;
    top: 40px;
    @media only screen and (min-width: 640px) {
      top: 90px;
      right: 0;
    }
    @media only screen and (min-width: 1280px) {
      width: 532px;
      height: 436px;
      right: -2%;
    }
    @media only screen and (min-width: 1536px) {
      width: 770px;
      height: 669px;
      right: -220px;
      top: 190px;
    }
    @media only screen and (min-width: 2000px) {
      right: -10px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .w-1050 {
      width: 1050px;
      max-width: 1050px;
      margin: 0 auto;
    }
  }

  .w-p {
    width: 270px;
    max-width: 270px;
  }
  .t-88 {
    top: -88px;
  }

  .filter-grey {
    filter: grayscale(100%);
  }

  @media only screen and (max-width: 765px) {
    .mobile-logo {
      right: -90px;
      top: -90px;
      z-index: 19;
      max-width: 260px;
    }
    .w-1050 {
      width: 100%;
      max-width: 100%;
    }
    .h-fijo {
      height: unset;
    }
  }
  @media only screen and (max-width: 1200px) {
    .underline-our {
      &:before {
        content: unset;
      }
    }
    .h-fijo {
      height: 112px;
    }
  }
  @media only screen and (min-width: 1280px) {
    .size-normal {
      width: 532px;
      height: 436px;
    }
  }
  @media only screen and (min-width: 1536px) {
    .w-1050 {
      width: 100%;
      max-width: 100%;
    }
  }
  @media only screen and (min-width: 2000px) {
    .w-1050 {
      width: 100%;
      max-width: 1590px;
    }
    .container {
      max-width: 1736px;
    }
  }
`;
