import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentWrapper";
import { useTranslation } from "react-i18next";
import { useAppContext } from "utilities/context";

const Content = ({ id = null }) => {
  const { t } = useTranslation(["common"]);
  const { openDigitalCampaignModal } = useAppContext();
  return (
    <PanelContentWrapper id={id} openModal={openDigitalCampaignModal}>
      <PanelContentTitleWrapper number={3}>{t("services.digital marketing campaign.title-preview")}</PanelContentTitleWrapper>
      <PanelContentSubtitleWrapper>{t("services.digital marketing campaign.subtitle responsive")}</PanelContentSubtitleWrapper>
    </PanelContentWrapper>
  );
};

export default Content;
