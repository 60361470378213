import React, { forwardRef } from "react";

const defaultCSSClasses = "cta flex flex-row w-[500px] md:flex-grow text-light-dark justify-between pt-6 md:pt-0 items-center text-base";

const PanelContentFooterWrapper = forwardRef(({ children, id = null, className = null }, ref) => (
  <div id={id} ref={ref} className={`${defaultCSSClasses} ${className ? ` ${className}` : ""}`}>
    {children}
  </div>
));

export default PanelContentFooterWrapper;
