import React, { useReducer, useState } from "react";
import ContactUs from "components/contact-us/ContactUs";
import MobileMenu from "components/Navigation/components/mobile-menu";
import OscarInfo from "components/about-us/OscarInfoModal";
import AlbertoInfo from "components/about-us/AlbertoInfoModal";
import B2BModal from "components/services/ResponsiveServiceSection/sections/b2b-marketing/ModalContent";
import SEOModal from "components/services/ResponsiveServiceSection/sections/seo-web/ModalContent";
import DigitalCampaignModal from "components/services/ResponsiveServiceSection/sections/digital-campaign/ModalContent";
import HighConvertingModal from "components/services/ResponsiveServiceSection/sections/high-converting-ecommerce/ModalContent";
import MarketplacePerformanceModal from "components/services/ResponsiveServiceSection/sections/marketplace-performance/ModalContent";
import PerformanceConsultingModal from "components/services/ResponsiveServiceSection/sections/performance-consulting/ModalContent";
import TermsAndConditions from "components/legal/es/TermsAndConditions";
import Sitemap from "components/sitemap/Sitemap";
import ThankYouModal from "components/contact-us/components/ThankYouModal";
// import TagManager from "react-gtm-module";

var parser = require("ua-parser-js");

const AppContext = React.createContext();

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("AppContext must be used within a AppContextProvider");
  }

  return context;
}

function languageInURL(languages = ["en"]) {
  let languageString = "";
  languages.forEach((language) => {
    if (window.location.href.includes(window.location.origin + "/" + language)) {
      languageString = language;
    } else if (language === "es") {
      languageString = "";
      console.log("languageString", languageString);
    }
  });
  return languageString;
}

function countryInURL(language = "", countries = ["mexico", "panama", "costa-rica", "guatemala", "estados-unidos", "venezuela"]) {
  let countryString = "";
  let languagePath = Boolean(language) ? "/" + language : "";
  countries.forEach((country) => {
    if (window.location.href.includes(window.location.origin + languagePath + "/" + country)) {
      countryString = country;
    }
  });
  return countryString;
}

function buildBaseURLParams(languages = ["en"], countries = ["mexico", "panama", "costa-rica", "guatemala", "estados-unidos", "venezuela"]) {
  let baseURLParams = {};
  baseURLParams["language"] = languageInURL(languages);
  baseURLParams["country"] = countryInURL(baseURLParams["language"], countries);
  return baseURLParams;
}

export const AppContextProvider = ({ children, keys, googleLocationData = {} }) => {
  const [appLoading, setAppLoading] = React.useState(false);
  const [domRendered, setDomRendered] = React.useState(true);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = React.useState(false);
  const [currentSection, setCurrentSection] = React.useState("home-section");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [modalBackground, setModalBackground] = React.useState(null);
  const [contactFormData, setContactFormData] = React.useState({});
  const [hasUserFilledForm, setHasUserFilledForm] = useState(false);
  const [baseURLParams, setBaseURLParams] = useState(buildBaseURLParams());
  const [baseURL, setBaseURL] = useState(buildBaseURL(baseURLParams));

  function buildBaseURL(baseURLParams) {
    let baseURL = "";
    baseURL += baseURLParams["language"] ? "/" + baseURLParams["language"] : "";
    baseURL += baseURLParams["country"] ? "/" + baseURLParams["country"] : "";
    return Boolean(baseURL) ? baseURL : "/";
  }

  function setBaseURLLanguage(language) {
    let updatedParams = { ...baseURLParams, language: language };
    setBaseURLParams(updatedParams);
    setBaseURL(buildBaseURL(updatedParams));
  }
  function setBaseURLCountry(country) {
    let updatedParams = { ...baseURLParams, country: country };
    setBaseURLParams(updatedParams);
    setBaseURL(buildBaseURL(updatedParams));
  }

  function userFilledForm() {
    setHasUserFilledForm(true);
  }

  /*   const [isModalOpen, setIsModalOpen] = React.useState(true);
  const [modalContent, setModalContent] = React.useState(<ThankYouModal/>);
  const [modalBackground, setModalBackground] = React.useState("bg-secondary");
 */

  function updateContactFormData(data) {
    setContactFormData({ ...contactFormData, ...data });
  }

  let zsiq_float = document.getElementById("zsiq_float");
  function getChatBot() {
    if (zsiq_float !== null) {
      return zsiq_float;
    }
    zsiq_float = document.getElementById("zsiq_float");
    return zsiq_float;
  }

  // Google Tag Manager
  // function gtmDataLayer(event = "", section = "", name = "") {
  //   const tagManagerArgs = {
  //     dataLayer: {
  //       event: event,
  //       section: section,
  //     },
  //     dataLayerName: name,
  //   };

  //   //console.log("tagManagerArgs: ", tagManagerArgs);
  //   TagManager.dataLayer(tagManagerArgs);
  // }

  // const linkedInDL = () => gtmDataLayer("linkedIn", "about-us", "LinkedInCTA");
  // console.log("linkedInDL: ", linkedInDL);

  function openModal(content, background) {
    //console.log("openModal");
    document.body.classList.add("modal-open");
    getChatBot()?.classList.add("transition-transform");
    getChatBot()?.classList.add("translate-y-44");
    setModalContent(content);
    setModalBackground(background);
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
  }
  function closeModal() {
    getChatBot()?.classList.remove("translate-y-44");
    setIsModalOpen("close");
  }
  function dismissModal() {
    document.body.classList.remove("modal-open");
    setIsModalOpen(false);
  }

  function openContactForm() {
    // gtmDataLayer("openContactForm", "contact", "ContactPage");
    openModal(<ContactUs />, "bg-primary");
  }

  function openTermsConditions() {
    // gtmDataLayer("openTermsConditions", "tos", "TermsPage");
    openModal(<TermsAndConditions />, "bg-primary");
  }

  function openSitemap() {
    openModal(<Sitemap />, "bg-primary");
  }

  function openThankYouModal() {
    openModal(<ThankYouModal />, "bg-secondary");
  }
  function openMenu() {
    openModal(<MobileMenu />, "menu-drawer bg-secondary");
  }
  function openOscarModal() {
    // gtmDataLayer("openOscarModal", "about-us", "OscarModal");
    openModal(<OscarInfo />, "bg-primary");
  }
  function openAlbertoModal() {
    // gtmDataLayer("openAlbertoModal", "about-us", "AlbertoModal");
    openModal(<AlbertoInfo />, "bg-primary");
  }
  function openB2BModal() {
    // gtmDataLayer("openB2BModal", "services", "MobileB2BModal");
    openModal(<B2BModal />);
  }
  function openSEOModal() {
    // gtmDataLayer("openSEOModal", "services", "MobileB2BModal");
    openModal(<SEOModal />);
  }
  function openDigitalCampaignModal() {
    // gtmDataLayer("openDigitalCampaignModal", "services", "MobileDigitalCampaignModal");
    openModal(<DigitalCampaignModal />);
  }
  function openHighConvertingModal() {
    // gtmDataLayer("openHighConvertingModal", "services", "MobileHighConvertingModal");
    openModal(<HighConvertingModal />);
  }
  function openMarketplacePerformanceModal() {
    // gtmDataLayer("openMarketplacePerformanceModal", "services", "MobileMarketplacePerformanceModal");
    openModal(<MarketplacePerformanceModal />);
  }
  function openPerformanceConsultingModal() {
    // gtmDataLayer("openPerformanceConsultingModal", "services", "MobilePerformanceConsultingModal");
    openModal(<PerformanceConsultingModal />);
  }

  let uaparser = new parser();

  function userOnMobile() {
    //console.log("device-type", uaparser.getResult()["device"]["type"]);
    return uaparser.getResult()["device"]["type"] === "mobile";
  }

  function modalReducer(state, action) {
    switch (action.type) {
      case "open":
        return { isOpen: true, component: action.payload, customClass: action.customClass ? action.customClass : "" };
      case "close":
        //console.log("inside close", state);
        getChatBot()?.classList.remove("translate-x-44");
        return { ...state, isOpen: "close" };
      case "dismount":
        return { isOpen: false };
      default:
        throw new Error();
    }
  }

  const [modal, modalDispatch] = useReducer(modalReducer, { isOpen: false, component: null, customClass: "" });

  return (
    <AppContext.Provider
      value={{
        appLoading,
        closeModal,
        currentSection,
        dismissModal,
        domRendered,
        // gtmDataLayer,
        isContactFormOpen,
        isMenuOpen,
        isModalOpen,
        // linkedInDL,
        setAppLoading,
        setDomRendered,
        setCurrentSection,
        setIsMenuOpen,
        setIsContactFormOpen,
        modal,
        modalDispatch,
        modalContent,
        modalBackground,
        openModal,
        openContactForm,
        openTermsConditions,
        openMenu,
        openOscarModal,
        openAlbertoModal,
        openB2BModal,
        openSEOModal,
        openDigitalCampaignModal,
        openHighConvertingModal,
        openMarketplacePerformanceModal,
        openPerformanceConsultingModal,
        userOnMobile,
        openThankYouModal,
        openSitemap,
        contactFormData,
        updateContactFormData,
        hasUserFilledForm,
        userFilledForm,
        baseURL,
        setBaseURL,
        setBaseURLLanguage,
        setBaseURLCountry,
        baseURLParams,
      }}>
      {children}
    </AppContext.Provider>
  );
};
