import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import spritesheet from "../../../../assets/performance-consulting/spritesheet.webp";
gsap.registerPlugin(ScrollTrigger);

/*
 * Services Section Performance consulting animation control
 * Params q gsap selector utility for id and class scoping
 */

export function responsiveSection1VideoAnimationControlSprite(q) {
  const canvas = document.getElementById("responsive-performance-consulting-video-frames");
  const context = canvas.getContext("2d");

  let video = {
    frame: 0,
  };

  let ratio = 1.159;
  let width = window.innerWidth;
  // let height = width * ratio;

  let spritesheetWidth = 3825;
  let spritesheetHeight = 5000;
  let spritesPerRow = 9;
  let totalSprites = 88;
  let spriteWidth = spritesheetWidth / spritesPerRow;
  let spriteHeight = spritesheetHeight / 10;

  let frames = [];
  for (let i = 0; i < totalSprites; i++) {
    let x = ((i % spritesPerRow) * spritesheetWidth) / spritesPerRow;
    let y = Math.floor(i / spritesPerRow) * spriteHeight;
    frames.push({ x: x, y: y });
  }

  const img = new Image();
  img.src = spritesheet;
  img.onload = render;

  canvas.width = width;
  canvas.height = width * ratio;

  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(img, frames[video.frame]["x"], frames[video.frame]["y"], spriteWidth, spriteHeight, 0, 0, canvas.width, canvas.height);
  }

  let animationObj = gsap.to(video, {
    frame: totalSprites - 1,
    snap: "frame",
    duration: totalSprites / 24,
    scrollTrigger: {
      trigger: "#responsive-services-section-1-animation",
      start: "center 80%",
      //end: "1000px 45%",
      //pin: "#responsive-services-section-1-animation",
      //pinSpacing: true,
      toggleActions: "restart none none reverse",
      //snap: "labelsDirectional"
    },
    onUpdate: function (self) {
      render();
    },
  });

  return animationObj;
}

export function responsiveSection1SpriteVideo(q) {
  let animationObj = gsap
    .timeline({
      scrollTrigger: {
        trigger: "#responsive-services-section-1-animation",
        start: "top 90%",
        end: "500px 45%",
        scrub: true,
      },
    })
    .fromTo(q(".responsive-services-images-wrapper-inner"), { scale: 0.5 }, { scale: 1, duration: 10 })
    .fromTo(q(".responsive-services-images-wrapper-inner"), { borderRadius: "16px" }, { borderRadius: 0, duration: 4 }, "6");

  return animationObj;
}
