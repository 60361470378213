import React from "react";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import Container from "../Container";
import elements from "assets/Alberto-Oscar-bg.svg";
import lk from "assets/lk.svg";
import arrow from "assets/arrow-gray.svg";
import { ArrowLink } from "../common/ArrowLink";
import { useAppContext } from "utilities/context";
export default function AboutModal({ data, data2 }) {
  const { t } = useTranslation();
  const { openContactForm, linkedInDL } = useAppContext();

  return (
    <StyledDiv className="bg-gray-2 relative z-50 w-screen min-h-full max-h-screen overflow-y-scroll pb-16">
      <Container className="">
        <div className="flex flex-col lg:flex-row flex-wrap pt-12 lg:pt-28">
          <div className="lg:w-4/12 relative w-full md:w-4/12">
            <img className="relative -left-4 z-10 hidden lg:block" src={data.photo} alt="Oscar + info" width={982} height={1109} />
            <img className="relative left-0 z-10 block lg:hidden mobile-oscar md:mx-auto" src={data.photoResponsive} alt="Oscar + info" width={982} height={1109} />
            <img className="absolute hidden lg:block element-size" src={elements} alt="elementos" width={815} height={746} />
          </div>
          <div className="w-1/12 hidden lg:block"></div>
          <div class="lg:w-7/12 z-10 w-full md:w-8/12 ">
            <h3 className="font-light text-lg lg:text-3xl text-grey-6">
              <Trans i18nKey={data.title}>
                nombre<span className="text-lg lg:text-2xl">title</span>
              </Trans>
            </h3>
            <em className="text-md uppercase text-grey-5 pt-4 pb-16 md:pb-20">{t(data.category)}</em>
            <div className="md:hidden lg:block block pt-4">
              <p className="content text-grey-5 text-md whitespace-pre-wrap pb-16 md:hidden lg:block">{t(data.content)}</p>
              <div className="line h-1 relative mt-auto pb-5 block"></div>
              <div className="flex flex-row w-full space-x-4 pb-4">
                <div className="flex justify-start">
                  <div className="social-link">
                    <a target="_blank" rel="nofollow noreferrer" href={data.linkedIn} className="h-8 social-link">
                      <img src={lk} alt="LinkedIn" title="LinkedIn" className="w-auto h-full" />
                    </a>
                  </div>
                </div>
                <div className="w-full text-white">
                  <ArrowLink onClick={openContactForm} className="text-white bg-white rounded-full text-black py-2 px-6">
                    {t(data.cta)}
                  </ArrowLink>
                </div>
              </div>
            </div>
          </div>
          <div className="w-2/12 hidden md:block lg:hidden"></div>
          <div className="w-8/12 hidden md:block lg:hidden ">
            <p className="content text-grey-5 text-md whitespace-pre-wrap mb-16">{t(data.content)}</p>
          </div>
          <div className="w-2/12 hidden md:block lg:hidden"></div>
          <div className="w-full md:block hidden  lg:hidden">
            <div className="line  h-1 relative mt-auto mb-5 block"></div>
            <div className="flex">
              <div className="w-2/12 "></div>
              <div className="flex w-8/12 justify-between ">
                <div onClick={linkedInDL} className="flex space-x-2">
                  <a target="_blank" rel="nofollow noreferrer" href={data.linkedIn} className="w-12 social-link">
                    <img src={lk} alt="LinkedIn" title="LinkedIn" />
                  </a>
                </div>
                <div className="flex-grow">
                  <a className="text-grey-5" href={data.linkedIn} target="_blank" rel="nofollow noreferrer">
                    Ver articulos que escribe <img className="inline-block ml-2" src={arrow} alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="w-2/12 "></div>
            </div>
          </div>
          <img className="absolute hidden md:block lg:hidden element-size" src={elements} alt="elementos" width={815} height={746} />
        </div>
      </Container>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .social-link {
    min-width: 2.6rem;
    height: 2.6rem;
  }
  min-height: 100vh;
  height: 100%;
  .element-size {
    @media only screen and (min-width: 1040px) {
      width: 815px;
      height: 746px;
      min-width: 815px;
      left: -5rem;
      bottom: -240px;
    }
    @media only screen and (min-width: 640px) {
      left: -390px;
      bottom: -120px;
    }
  }
  .mobile-oscar {
    max-width: 100px;
    @media only screen and (min-width: 640px) {
      max-width: 125px;
    }
  }
  .content {
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-right: calc(100% / 8);
    }
  }
  .line:before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #575757;
    position: absolute;
  }
  .close-pop {
    position: relative;
    right: 50px;
    @media only screen and (max-width: 640px) {
      right: 10px;
    }

    &:before {
      content: "";
      width: 20px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      transform: rotate(45deg);
      top: 0px;
      right: 0px;
      @media only screen and (min-width: 640px) {
        top: -20px;
      }
    }

    &:after {
      content: "";
      width: 20px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      transform: rotate(315deg);
      top: 0px;
      right: 0px;
      @media only screen and (min-width: 640px) {
        top: -20px;
      }
    }
  }
`;
