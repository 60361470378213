import React, { forwardRef } from "react";

const defaultCSSClasses = "panel-content whitespace-pre-line text-md text-white";

const PanelContentBodyWrapper = forwardRef(({ children, id = null, className = null }, ref) => (
  <div id={id} ref={ref} className={`${defaultCSSClasses}${className ? ` ${className}` : ""}`}>
    {children}
  </div>
));

export default PanelContentBodyWrapper;
