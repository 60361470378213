import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AvatarPresidente from "./AvatarPresident";

export const LeftColumn = () => {
  const { t } = useTranslation();

  /* <StyledDiv className="increment-roi-title lg:w-6/6 lg:mx-auto max-h-left-column flex flex-col mt-5 lg:mt-0"> */
  return (
    <StyledDiv className="increment-roi-title lg:w-6/6 flex flex-col mt-5 lg:mt-0">
      <div className="mb-4 md:mb-0 md:items-start">
        <div className="h1 text-md-larger md:text-xl lg:hidden lg:text-xl md:mb-4 lg:mb-0 xl:w-full xl:text-2xl xxl:text-4xl font-light text-white whitespace-pre-line">
          <span className="block ">{t("contact-us:title")}</span>
          <span className="hidden">{t("contact-us:title responsive")}</span>
        </div>
      </div>
      <div className="hidden lg:block h1 text-md-larger md:text-xl lg:text-xl md:mb-6 lg:mb-0 xl:w-full xl:text-2xl xxl:text-4xl font-light text-white whitespace-pre-line">
        <span className="block ">{t("contact-us:title")}</span>
        <span className="hidden">{t("contact-us:title responsive")}</span>
      </div>
      <div className="hidden lg:block">
        <AvatarPresidente />
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
/*   .max-h-left-column {
    height: 480px;
  } */
  .certificaciones-title {
    height: 100%;
  }
  .certificaciones-box {
    display: flex;
    justify-content: between;
    align-items: center;
    padding: 0.75rem 0;
    position: relative;
    height: 100%;
    & > * {
      width: 50%;
    }
    &:first-child {
      width: 4rem;
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      & > *:first-child {
        width: 45%;
      }
      & > *:last-child {
        width: 55%;
      }
    }
  }
`;
