import React from "react";
import styled from "styled-components"; //permite crear estilos para este archivo
import photoSlide1 from "assets/awards/gptw@1x.webp";
import photoSlide2 from "assets/awards/latin-american-design-awards@1x.webp";
import photoSlide3 from "assets/awards/iab-image@1x.jpg";
import photoSlide4 from "assets/awards/imagen google 1st success case latam.jpg";
import photoSlide5 from "assets/awards/effie-image@1x.webp";
import awardLogo1 from "assets/awards/GPTW-logo@1x.webp";
import awardLogo2 from "assets/awards/latin-american-design-awards-logo@1x.webp";
import awardLogo3 from "assets/awards/iab-icon@1x.webp";
import awardLogo4 from "assets/awards/google-partner-logo@1x.webp";
import awardLogo5 from "assets/awards/effie-icon@1x.webp";
import AwardsdResponsive from "./AwardsdResponsive";

const awardSlides = [
  {
    photo: photoSlide1,
    logo: awardLogo1,
    contentId: "great-place-to-work", //this has to match with locale file
  },
  {
    photo: photoSlide2,
    logo: awardLogo2,
    contentId: "latin-american-design-awards", //this has to match with locale file
  },
  {
    photo: photoSlide3,
    logo: awardLogo3,
    contentId: "innovacion-digital", //this has to match with locale file
  },
  {
    photo: photoSlide4,
    logo: awardLogo4,
    contentId: "google-premier-awards", //this has to match with locale file
  },
  {
    photo: photoSlide5,
    logo: awardLogo5,
    contentId: "effie-awards", //this has to match with locale file
  },
];

export const AwardsOnlyMobile = ({ id = null }) => {
  return (
    <StyledSection id={id} className="bg-extra-1 relative">
      <AwardsdResponsive awardSlides={awardSlides} />
    </StyledSection>
  );
};

//Awards.whyDidYouRender = true;

const StyledSection = styled.section`
  #award-paginator {
    position: absolute;
    @media screen and (max-width: 1023px) {
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (max-width: 767px) {
      left: 25%;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      left: 33%;
    }
    .award-paginator-bullet {
      position: relative;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 1000rem;
      background-color: #e5e5e5;
      transition: height 0.5s cubic-bezier(0, 1, 0, 1);
      cursor: pointer;
      @media screen and (max-width: 1023px) {
        margin: 0 0.25rem;
        display: inline-block;
      }
    }
    & .award-paginator-bullet.active {
      background-color: white;
      @media screen and (max-width: 1023px) {
        width: 1.25rem;
      }
    }
  }
  #mobile-awards-paginator {
    position: absolute;
    z-index: 200;
    opacity: 1;
    color: white;
    width: 6rem;
    @media screen and (max-width: 767px) {
      right: 0;
      bottom: 0;
    }
    @media screen and (min-width: 768px) {
      top: calc(100% + 3rem);
      left: 0;
    }
    & .award-paginator-bullet {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 1000rem;
      background-color: #e5e5e5;
      transition: height 0.5s cubic-bezier(0, 1, 0, 1);
      cursor: pointer;
      @media screen and (max-width: 1023px) {
        margin: 0 0.25rem;
        display: inline-block;
      }
    }
    & .award-paginator-bullet.active {
      background-color: white;
      @media screen and (max-width: 1023px) {
        width: 1.25rem;
      }
    }
  }

  .award-name {
    @media screen and (max-width: 1023px) {
      height: 2.5em;
    }
  }
  .award-photo {
    transition: 0.3s opacity;
    opacity: 0;
    &.active {
      opacity: 1;
    }
    & img {
      top: 50%;
      transform: translatey(-50%);
    }
  }
  .active-award {
    & h3 {
      @media screen and (max-width: 1023px) {
        min-height: 2.5em;
      }
    }
    transition: 0.3s transform;
  }
  .active-award-logo {
    transition: 0.3s transform;
  }
  .active-award-content {
    transition: 0.3s transform;
  }
  .award-content {
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .underline-awards {
    position: absolute;
    width: 100vw;
    display: none;
    @media screen and (max-width: 1023px) {
      &.mobile {
        display: block;
        top: 50%;
        left: calc(100% + 1rem);
      }
    }
  }

  .t-custom {
    top: 200px;
  }
  @media only screen and (max-width: 1200px) {
    .w-tablet {
      width: 76px;
    }
    .line-control:before {
      left: 134px;
      top: 33px;
    }
  }
  @media only screen and (max-width: 765px) {
    .line-control:before {
      left: 103px;
      top: 30px;
    }
    .t-custom {
      top: 0;
    }
  }
  @media only screen and (min-width: 768px) {
    .t-custom {
      top: 254px;
    }
  }
  .photo-container {
    width: 100%;
    bottom: 0;
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 55%;
      margin-left: auto;
    }
    .photo-container-inner-wrapper {
      max-height: 0;
      padding-bottom: 120.5263%;
      overflow: hidden;
      transform: translatey(13.43%);
    }
  }
`;
export default AwardsOnlyMobile;