import React from "react"; //importamos react

//import AfterSectionSpacer from "./components/AfterSectionSpacer";
import ServicesDesktop from "./DesktopServicesSection/ServicesDesktop";
import ServicesResponsive from "./ResponsiveServiceSection/ServicesResponsive";


export default function Services({ id = null }) {

  return (
    <div id={id} className="relative">
      <div id="desktop-services-section-1" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)-1px)] z-[99999999] w-full h-1 " />
      <div id="performance-consulting" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)-1px)] z-[99999999] w-full h-1 " />
      <div id="desktop-services-section-2" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+725px)] z-[99999999] w-full h-1 " />
      <div id="high-converting-e-commerce" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+725px)] z-[99999999] w-full h-1 " />
      <div id="desktop-services-section-3" className="hidden lg:block absolute top-[calc(((100vh-670px)/-2)+1775px)] z-[99999999] w-full h-1 " />
      <div id="digital-Marketing-campaign" className="hidden lg:block absolute top-[calc(((100vh-670px)/-2)+1775px)] z-[99999999] w-full h-1 " />
      <div id="desktop-services-section-4" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+2675px)] z-[99999999] w-full h-1 " />
      <div id="marketplace-performance" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+2675px)] z-[99999999] w-full h-1 " />
      <div id="desktop-services-section-5" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+3415px)] z-[99999999] w-full h-1 " />
      <div id="b2b-marketing" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+3415px)] z-[99999999] w-full h-1 " />
      <div id="desktop-services-section-6" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+4145px)] z-[99999999] w-full h-1 " />
      <div id="agencia-seo" className="hidden lg:block  absolute top-[calc(((100vh-670px)/-2)+4145px)] z-[99999999] w-full h-1 " />
      <div id="services-animation-container">
        <ServicesDesktop className="hidden md:block" />
        <ServicesResponsive id="services-responsive-version" className=" md:hidden" />
        {/* <AfterSectionSpacer height={"30vh"} /> */}
      </div>
    </div>
  );
}
