import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ContactForm } from "./components/Form";
import { LeftColumn } from "./components/LeftColumn";
import fondoContactUs from "assets/contact-us/contact-us-bg.webp";
import errorIcon from "assets/contact-us/error-icon.svg";
import iabLogo from "assets/contact-us/logo aib.svg";
import googleLogo from "assets/contact-us/google premium partner.svg";
import effieLogo from "assets/contact-us/effie awards.svg";
import LogoGPTW from "assets/awards/GPTW-logo@1x.webp";
import LogoLatinDesignAward from "assets/contact-us/latin-american-design-awards 1.webp";
/**
 *
 * TODO ajustar alts de los Logos
 */

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <StyledDiv className="bg-primary">
      <div className="container contact-us-container h-full lg:flex lg:items-center">
        <div className="flex flex-col lg:flex-row lg:items-stretch lg:w-full">
          <div className="lg:w-1/2 xl:w-1/2 lg:flex lg:flex-col lg:justify-between lg:items-stretch 3xl:items-center">
            <LeftColumn />
            {/* Awards */}
            <div className="hidden lg:block awards-wrapper">
              <div className="w-full text-white whitespace-pre-line py-4">{t("contact-us:certificaciones")}</div>
              <div className="flex space-x-4">
                <div className="flex flex-col items-center text-white">
                  <div className="mt-2">
                    <img src={iabLogo} alt="IAB logo" className="h-8 xl:h-8" />
                  </div>
                  <div className="w-40 mt-20 fixed transform -rotate-90">{t("contact-us:innovacion")}</div>
                </div>
                <div className="vertical-line transform -rotate-180"></div>
                <div className="flex flex-col items-center text-white">
                  <div className="mt-2">
                    <img src={LogoGPTW} alt="Great Places to Work logo" className="h-8 xl:h-8" />
                  </div>
                  <div className="w-40 mt-20 fixed transform -rotate-90">{t("contact-us:el mejor equipo")}</div>
                </div>
                <div className="vertical-line transform -rotate-180"></div>
                <div className="flex flex-col items-center text-white">
                  <div className="mt-2">
                    <img src={effieLogo} alt="Effie Awards logo" className="h-8 xl:h-8" />
                  </div>
                  <div className="w-40 mt-20 fixed transform -rotate-90">{t("contact-us:publicidad digital")}</div>
                </div>
                <div className="vertical-line transform -rotate-180"></div>
                <div className="flex flex-col items-center text-white">
                  <div className="mt-2">
                    <img src={LogoLatinDesignAward} alt="Latin American Design Awards Logo" className="h-10 xl:h-12" />
                  </div>
                  <div className="w-40 mt-20 fixed transform -rotate-90">{t("contact-us:diseno web")}</div>
                </div>
                <div className="vertical-line transform -rotate-180"></div>
                <div className="flex flex-col items-center text-white">
                  <div className="mt-2">
                    <img src={googleLogo} alt="Google Premier Partner Award logo" className="h-8 xl:h-8" />
                  </div>
                  <div className="w-40 mt-20 fixed transform -rotate-90">{t("contact-us:campana digital")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w1/2 lg:flex-grow flex justify-center 3xl:justify-strech">
            <ContactForm className="max-h-contact" setShowThankYou/>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default ContactUs;

const StyledDiv = styled.div`
  .max-h-left-column {
    @media screen and (min-width: 1024px) {
      height: 480px;
    }
  }
  .vertical-line {
    width: 2px;
    height: 180px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    background-position: left top;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .max-h-contact {
    @media screen and (min-width: 1024px) {
      height: 620px;
    }
  }
  .contact-us: {
    background-color: #171717 !important;
  }
  .awards-wrapper {
    @media (min-height: 1200px) {
      margin-bottom: 0px;
    }
    @media (min-height: 1024px) {
      margin-bottom: 0px;
    }
    @media (max-height: 1024px) {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    .increment-roi-title {
      padding-left: 4rem;
    }
    .increment-roi-icon {
      margin-top: -3.4rem;
    }
  }
  & {
    width: 100vw;
    height: 100vh;
    @media screen and (min-height: 768px) {
      background-image: url(${fondoContactUs});
      background-size: 550px auto;
      background-position: 8% 46%;
      background-repeat: no-repeat;
    }

    @media screen and (min-height: 996px) {
      background-image: url(${fondoContactUs});
      background-size: 550px auto;
      background-position: 8% 48%;
      background-repeat: no-repeat;
    }

    @media screen and (min-height: 1080px) {
      background-size: 34% auto;
      background-position: 20% 48%;
    }

    & .Mui-error {
      background-image: url(${errorIcon});
      background-size: 1.3em 1.3em;
      padding-left: 1.5em;
      background-repeat: no-repeat;
      color: #ff5e5e;
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #ff5e5e;
      }
    }
    p.MuiFormHelperText-root.Mui-error {
      color: #ff5e5e !important;
    }
    select {
      font-weight: 300;
    }
    & .MuiTextField-root label {
      color: white;
      font-weight: 300;
    }
    & *:focus,
    & *:focus-visible,
    & .Mui-focused {
      outline: none !important;
    }
    & .MuiInputBase-root.MuiOutlinedInput-root {
      background: #282828;
      color: white;
      &:focus {
        border: 1px solid rgba(255, 255, 255, 0.2);
      }

      & input,
      textarea,
      select {
        color: white;
        &::placeholder {
          color: white;
          opacity: 1;
          font-weight: 300;
        }
        &:focus {
          outline: none;
        }
      }
      & legend {
        color: white;
      }
    }
  }
`;
