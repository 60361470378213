import PanelBackground from "../PanelBackground";
import styled from "styled-components";
import React, { forwardRef } from "react";
import panel4Background from "assets/marketplace-performance/fondo@1x.webp";
import panel4BackgroundResponsive from "assets/marketplace-performance/marketplace-background-responsive.webp";

const Panel4Background = forwardRef(({ className = null, id = null }, ref) => {
  return (
    <StyledPanelBackground id={id} className={`${className}`}>
      <div ref={ref} className={`hidden md:block  h-full`}>
        <img src={panel4Background} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
      <div ref={ref} className="md:hidden  h-full">
        <img src={panel4BackgroundResponsive} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
    </StyledPanelBackground>
  );
});

const StyledPanelBackground = styled(PanelBackground)``;

export default Panel4Background;
