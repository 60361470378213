import React from "react"; //importamos react
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components"; //permite crear estilos para este archivo
import { useAppContext } from "utilities/context";
export default function ContactUsButton({ translation = false, className = "" }) {
  const { t } = useTranslation("common");
  const { openContactForm } = useAppContext();

  return (
  <StyledButton onClick={openContactForm} className={`relative contact-us-bottom block lg:inline-flex flex-row items-center text-dark-medium justify-center lg:ml-2 rounded-full ${className}`}>
      <div className="button-background absolute rounded-full" />
      <span className="relative transition px-5 text-md lg:text-sm xl:text-base rounded-full border border-transparent bg-clip-padding font-light uppercase tracking-widest ">{t(`${translation ? translation : "navigation.contact us"}`)}</span>
    </StyledButton>
  );
}

const glitchAnimText = keyframes`
    0% {
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    2% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    4% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    5% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    6% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    7% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    8% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    9% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
      clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    9.9% {
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
    }
    10%,
    100% {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
`;
const StyledButton = styled.button`
  & {
    --time-anim: 3s;
    --delay-anim: 10s;
    //margin-top: 20px !important;
    font-smooth: always;
    display: block;
    //float: left;
    border-radius: 60px;
    transition: 0.4s all ease;
    cursor: pointer;
    position: relative;
    overflow: hidden !important;
    background: none;
    animation-name: ${glitchAnimText};
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.1);
    @media screen and (max-width: 1023px) {
      padding: 0.5rem 1.25rem;
    }
    @media screen and (min-width: 1024px) {
      padding: 0.4rem 0.5rem;
    }
    &:before {
      content: "";
      position: absolute;
      background: linear-gradient(90deg, rgba(54, 54, 54, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(54, 54, 54, 1) 100%);
      //background: linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
      transition: 0.5s transform ease;
      pointer-events: none;
    }
  }
  .button-background {
    inset: 1px;
    background: #171717;
  }
  &:hover {
    animation-name: none;
  }
  &:hover:before {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  /*   .button-background-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 0px;
    height: 0px;
    background: #ffffff;
    transition: all 0.4s;
  }

  &:hover {
    span {
      color: #171717;
    }
    .button-background-hover {
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      opacity: 1;
    }
  } */
`;
