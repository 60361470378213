import React from "react";
import styled from "styled-components";

const defaultCSSClasses = `
panel-content-wrapper`;

const PanelContentWrapper = ({ children, id = null, className = null }) => {

/*   const pendingCollapse = useRef(false);

  function toggleExpand() {
    setExpandedPanel(!expandedPanel ? id : false);
  } */

  /* useEffect(() => {
    function expandContentPanelAnimation() {
      let animationObj = gsap
        .timeline()
        .to(ref.current, {
          height: "100%",
          borderRadius: 0,
          duration: 1,
        })
        .to(
          q(".panel-content"),
          {
            color: "#ffffff",
            autoAlpha: 1,
            duration: 1,
          },
          "-=1"
        )
        .to(
          q(".cta"),
          {
            color: "#ffffff",
            autoAlpha: 1,
            duration: 1,
          },
          "-=1"
        )
        .to(
          q(".expand-toggle"),
          {
            top: "2rem",
            rotate: 405,
            duration: 1,
          },
          "-=1"
        )
        .set(
          ref.current,
          {
            zIndex: 6000,
          },
          "-=0.2"
        );
      return animationObj;
    }

    function collapseContentPanelAnimation() {
      let animationObj = gsap
        .timeline()
        .to(ref.current, {
          height: "45%",
          borderRadius: "1.5rem 1.5rem 0 0",
          duration: 1,
        })

        .to(
          q(".panel-content"),
          {
            autoAlpha: 0,
            duration: "1",
          },
          "<"
        )
        .to(
          q(".cta"),
          {
            autoAlpha: 0,
            duration: "1",
          },
          "<"
        )
        .to(
          q(".expand-toggle"),
          {
            top: "10rem",
            rotate: 0,
            duration: 1,
          },
          "<"
        )
        .set(
          ref.current,
          {
            zIndex: "initial",
          },
          "<+=0.2"
        )
        .pause();
      return animationObj;
    }

    if (expandedPanel === id) {
      document.body.classList.add("modal-open");
      pendingCollapse.current = true;
      expandContentPanelAnimation();
    }
    if (pendingCollapse.current && !expandedPanel) {
      document.body.classList.remove("modal-open");
      pendingCollapse.current = false;
      collapseContentPanelAnimation().play();
    }
  }, [expandedPanel, q, ref]);
 */
  return (
    <StyledDiv id={id} className={`${defaultCSSClasses} ${className ? ` ${className}` : ""}`}>
      {children}
{/*       <div onClick={toggleExpand} className="expand-toggle text-white pointer-events-auto cursor-pointer font-light text-3xl lg:hidden absolute">
        +
      </div> */}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  @media screen and (max-width: 767px) {
    //transform: translatey(55%);
  }
  .expand-toggle {
    @media screen and (min-width: 768px) {
      display: none;
    }
    top: 10rem;
    right: 2rem;
  }
`;

export default PanelContentWrapper;
