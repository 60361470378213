import React, { forwardRef } from "react";
import styled from "styled-components"; //permite crear estilos para este archivo

const defaultCSSClasses = "panel-content-subtitle whitespace-pre-line text-md font-light text-white pr-8 pb-6";

const PanelContentSubtitleWrapper = forwardRef(({ children, id = null, className = null }, ref) => (
  <StyledSection>
    <div id={id} ref={ref} className={`${defaultCSSClasses}${className ? ` ${className}` : ""}`}>
      {children}
    </div>
  </StyledSection>
));

const StyledSection = styled.div`
  .panel-content-subtitle {
    @media screen and (max-width: 765px) {
      font-size: 1.4rem;
    }
  }
`;

export default PanelContentSubtitleWrapper;
