import PanelContentBodyWrapper from "../PanelContentBodyWrapper";
import PanelContentFooterWrapper from "../PanelContentFooterWrapper";
import PanelContentSubtitleWrapper from "../PanelContentSubTitleWrapper";
import PanelContentTitleWrapper from "../PanelContentTitleWrapper";
import PanelContentWrapper from "../PanelContentWrapper";
import seoTecnico from "assets/seo web/seo-tecnico.png";
import seoOffPage from "assets/seo web/seo-offpage.png";
import seoOnSite from "assets/seo web/seo-on-site.png";
import { ReactComponent as FlechaPequena } from "assets/flecha-pequena-v2.svg";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";

const SEOWebContent = ({ id = "panel-6-content", setExpandedPanel, expandedPanel }) => {
    const { t } = useTranslation(["common"]);
    return (
        <StyledSection>
            <PanelContentWrapper id={id} setExpandedPanel={setExpandedPanel} expandedPanel={expandedPanel}>
                <PanelContentTitleWrapper number={6} titlePreview={t("services.seo web.title-preview")}>
                    {t("services.seo web.title")}
                </PanelContentTitleWrapper>
                <PanelContentSubtitleWrapper>{t("services.seo web.subtitle")}</PanelContentSubtitleWrapper>
                <PanelContentBodyWrapper>
                    <Trans i18nKey="services.seo web.content">
                        <p className="block mb-6">parrafo 1</p>
                        <p className="block">parrafo 2</p>
                    </Trans>
                    <br />
                    <div className="flex">
                        <div className="relative overflow-visible flex items-center">
                            <div className="icon rounded-full aspect-square w-1/2 h-12 flex justify-center items-center">
                                <img src={seoTecnico} alt={t("services.seo web.service1")} className="" />
                            </div>
                            <p className="text-left uppercase text-md w-1/2 text-white md:text-light-dark">
                                {t("services.seo web.service1")}
                            </p>
                        </div>
                        <div className="relative overflow-visible flex items-center">
                            <div className="icon rounded-full aspect-square w-1/2 h-12 flex justify-center items-center">
                                <img src={seoOffPage} alt={t("services.seo web.service2")} className="" />
                            </div>
                            <p className="text-left uppercase text-md w-1/2 text-white md:text-light-dark">
                                {t("services.seo web.service2")}
                            </p>
                        </div>
                        <div className="relative overflow-visible flex items-center">
                            <div className="icon rounded-full aspect-square w-1/2 h-12 flex justify-center items-center">
                                <img src={seoOnSite} alt={t("services.seo web.service3")} className="" />
                            </div>
                            <p className="text-left uppercase text-md w-1/2 text-white md:text-light-dark">
                                {t("services.seo web.service3")}
                            </p>
                        </div>
                    </div>
                </PanelContentBodyWrapper>
                <PanelContentFooterWrapper>
                    <div className="pointer-events-auto">
                        <a href={t("services.seo web.url")} className="flex">
                            <h4 className="whitespace-pre-line text-lg">{t("services.seo web.action button")}</h4>
                            <FlechaPequena className="text-light-medium inline-block relative fill-current w-4 h-auto ml-3" />
                        </a>
                    </div>
                </PanelContentFooterWrapper>
            </PanelContentWrapper>
        </StyledSection>
    );
};

const StyledSection = styled.section`
    #panel-6-content .panel-content-subtitle {
        font-weight: 600;
    }
`;

export default SEOWebContent;
