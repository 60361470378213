import { Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as Chevron } from "assets/awards/chevron collapse panel.svg";
import AwardsResponsivePhoto from "./AwardsResponsivePhoto";

const AwardsResponsivePanel = ({ award, open = true, expand = () => {}, close = () => {} }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="awards-responsive-panel relative border-b border-solid border-neutral-30">
      <div className="awards-responsive flex flex-row justify-start items-center pl-2 pb-2" onClick={open ? close : expand}>
        <div className="awards-logo-responsive w-16 h-16 mt-n2 flex-shrink-0">
          <img className="block h-full w-full object-contain object-center" src={award.logo} alt={`${t(`awards.${award.contentId}.award`)} logo`} />
        </div>
        <div className="awards-name-responsive flex-grow px-2 overflow-hidden">
          <h3 key={award.contentId} className={`text-light-medium max-w-full font-normal uppercase text-md tracking-tight whitespace-nowrap overflow-hidden overflow-ellipsis ${open ? "expanded" : "closed"}`}>
            {t(`awards.${award.contentId}.award-mobile`)}
          </h3>
        </div>
        <div className="awards-name-action-spacer w-8 flex-shrink-0"></div>
      </div>
      <Collapse in={open}>
        <div className="awards-responsive-panel-photo mt-8">
          <AwardsResponsivePhoto photo={award.photo} alt="alt message" />
        </div>
        <div className="awards-responsive-panel-content my-4">
          <p className="font-light text-light-medium">{t(`awards.${award.contentId}.content`)}</p>
        </div>
        <div className="flex flex-row justify-between mt-4 mb-12">
          <div className="award-responsive-know-more" onClick={close}>
            {/*             <a href="" target="blank" className="hidden">
              {t("awards.link")}
            </a> */}
          </div>
        </div>
      </Collapse>
      <div className={`absolute w-full h-20 award-responsive-close flex justify-end items-center bottom-0`} onClick={open ? close : expand}>
        <Chevron className={`w-5 h-4 transform transition-transform ${open ? "rotate-0" : "rotate-180"}`} />
      </div>
    </div>
  );
};

export default AwardsResponsivePanel;
