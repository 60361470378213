import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentWrapper";
import { useTranslation } from "react-i18next";
import { useAppContext } from "utilities/context";

const Content = ({ id = null }) => {
  const { t } = useTranslation(["common"]);
  const { openSEOModal } = useAppContext();
  return (
    <PanelContentWrapper id={id} payload="SEOMarketing" openModal={openSEOModal}>
      <PanelContentTitleWrapper number={6}>{t("services.seo web.title")}</PanelContentTitleWrapper>
      <PanelContentSubtitleWrapper>{t("services.seo web.subtitle responsive")}</PanelContentSubtitleWrapper>
    </PanelContentWrapper>
  );
};

export default Content;
