import React from "react"; //importamos react
import { useTranslation } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import AboutUsBackground from "./AboutUsBackground";
import AboutUsCTA from "components/about-us/AboutUsButton";
import Metodology from "./Metodology";
import FadeFromLeft from "components/animations/FadeFromLeft";

export default function WhyUs({ id = null }) {
  const { t } = useTranslation("common");

  return (
    <StyledDiv id={id} className="bg-extra-2 pt-8 md:pt-16 lg:pt-12 pb-0 md:h-auto lg:h-[1100px]">
      <div className="container md:h-auto lg:h-[550px]">
        <div className="pb-0 lg:pb-0 mb-[-28%] md:mb-[-32%] lg:mb-8 lg:w-[calc(100%+3rem)] relative lg:left-[-3rem]">
          <h2 className="pb-2 lg:hidden block about-us-title-mobile text-gray-2 text-xl font-light w-full lg:pl-6 lg:text-2xl xl:text-3xl">{t("about-us.title")}</h2>
          <span className="text-md font-light mb-4 lg:hidden block">{t("about-us.sub-title")}</span>
          <div className="flex flex-col-reverse lg:block">
            <div className="fade-in-left lg:inline-block block m-height lg:w-[calc(50%+3rem)] xl:w-7/12 xl:mb-[-22%] relative overflow-x-visible">
              <AboutUsBackground />
            </div>
            <div className="lg:inline-block block order-1 lg:w-[calc(50%-3rem)] xl:w-5/12 w-full align-top relative lg:pl-6 xl:mt-5 xl:mb-8">
              <div className="hidden lg:block about-us-title block text-gray-2 text-3xl font-light mb-8 lg:mb-4">
                <h2 className="reveal-animation-subtitle h3">{t("about-us.title")}</h2>
                <h3 className="reveal-animation-subtitle h4 text-lg mb-8">{t("about-us.sub-title")}</h3>
              </div>
              <div className="lg:mb-32  lg:mb-0 text-base">
                <p className="fade-in-bottom text-gray-2 text-md lg:leading-tight font-normal lg:text-md text-base leading-6 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: t("about-us.content") }}></p>
                <p className="fade-in-bottom text-gray-2 text-md lg:leading-tight font-normal lg:text-md text-base leading-6 whitespace-pre-wrap font-semibold">{t("about-us.cta intro")}</p>
              </div>
              <FadeFromLeft>
                <AboutUsCTA className="my-8" />
              </FadeFromLeft>
            </div>
          </div>
        </div>
      </div>
      <Metodology />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .divider {
    border-top: solid 1px #000;
  }
  .about-us-background-wrapper {
    display: block;
    position: relative;
    top: 0;
    @media screen and (max-width: 1023px) {
      width: 120%;
      left: 50%;
      transform: translatex(-50%);
    }
    @media screen and (min-width: 1024px) {
      width: 120%;
      transform: translatex(-20%);
      left: 0;
    }
    @media screen and (min-width: 1920px) {
      width: 90%;
      transform: translatex(-5%);
      left: unset;
      right: 0;
    }
  }
  .about-us-background-wrapper-inner {
    width: 100%;
    height: 0px;
    padding-bottom: 73.83%;
    position: relative;
    top: 0;
    right: 0;
  }
  .about-us-background:before {
    @media screen and ((max-width: 767px) or ((min-width:1024px) and (max-width:1535px))) {
      content: "";
      display: block;
      background-color: #000;
      position: absolute;
      width: 100%;
      height: 42%;
      border-radius: 1000rem;
      transform: rotate(315deg);
      top: 50%;
      left: -35.5%;
    }
  }

  .h-alberto {
    height: 584px;
  }
  .link-about-us-oscar {
    position: absolute;
    transform: translateX(-50%);
    @media screen and (max-width: 1023px) {
      top: 80%;
      left: 68%;
    }
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      top: 84%;
      left: 64%;
    }
    @media screen and (min-width: 1280px) {
      left: 66%;
      top: 79%;
    }

    transform: translate(-50%, -50%);
  }

  .link-about-us-alberto {
    position: absolute;
    transform: translateX(-50%);
    @media screen and (max-width: 1023px) {
      left: 20%;
      top: 70%;
    }
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      left: 26%;
      top: 60%;
    }
    @media screen and (min-width: 1280px) {
      left: 56%;
      top: 15%;
    }
    @media screen and (min-width: 1680px) {
      left: 56%;
      top: 19%;
    }
    @media screen and (min-width: 1960px) {
      left: 56%;
      top: 19%;
    }

    transform: translate(-50%, -50%);
  }
  .h-custom {
    @media only screen and (min-width: 1280px) {
      width: 50;
    }
    @media only screen and (min-width: 1536px) {
      height: 584px;
    }
  }
  .fix-box {
    max-width: 768px;
    margin: 0 0 0 auto;
    @media only screen and (min-width: 640px) and (max-width: 1040px) {
      max-width: 510px;
      margin: 0;
      left: -20px;
    }
    @media only screen and (min-width: 1280px) {
      max-width: 640px;
    }
  }

  .alberto-oscar {
    min-width: 791px;
    @media only screen and (min-width: 640px) and (max-width: 1040px) {
      min-width: 510px;
      height: 376px;
    }
  }

  .text-alb-tablet {
    @media only screen and (min-width: 640px) {
      top: 300px;
      left: -165px;
    }
    @media only screen and (min-width: 1280px) {
      top: 150px;
      left: -25px;
    }
    @media only screen and (min-width: 1536px) {
      left: 65px;
    }
  }

  .oscar-custom {
    top: 400px;
    left: 295px;
    @media only screen and (min-width: 640px) {
      top: -130px;
      left: 270px;
    }
    @media only screen and (min-width: 1280px) {
      top: 400px;
      left: 295px;
    }
    @media only screen and (min-width: 1536px) {
      left: 425px;
      top: -180px;
    }
    @media only screen and (min-width: 2000px) {
      left: 425px;
      top: -200px;
    }
  }

  .position-custom {
    top: 205px;
    right: -30px;
    @media only screen and (min-width: 640px) {
      top: 300px;
      right: -43px;
    }
    @media only screen and (min-width: 1280px) {
      top: 150px;
      right: -305px;
    }
    @media only screen and (min-width: 1536px) {
      right: -430px;
    }
    @media only screen and (min-width: 2000px) {
      right: -425px;
      display: block;
      top: 150px;
    }
  }

  .btn-custom {
    top: 410px;
    left: 295px;
    @media only screen and (min-width: 640px) {
      top: -130px;
      left: 270px;
    }
    @media only screen and (min-width: 1280px) {
      left: 340px;
      top: 410px;
    }
    @media only screen and (min-width: 1536px) {
      left: 470px;
      top: -180px;
    }
    @media only screen and (min-width: 2000px) {
      left: 425px;
      top: -200px;
    }
  }

  .bg-alberto {
    ::before {
      content: "";
      background-color: #000;
      position: absolute;
      width: 840px;
      height: 248px;
      border-radius: 140px;
      -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: rotate(315deg);
      top: 304px;
      left: -404px;
      @media only screen and (min-width: 640px) and (max-width: 1040px) {
        content: none;
      }
      @media only screen and (min-width: 1536px) {
        content: unset;
      }
    }
  }

  /*.bg-oscar::before {
    content: "";
    background-color: #000;
    width: 480px;
    height: 245px;
    position: absolute;
    border-radius: 140px;
    transform: rotate(315deg);
    top: 159px;
    left: -146px;
  }*/
  .features {
    > div {
      > p {
        max-width: 200px;
        width: 100%;
        @media only screen and (max-width: 1200px) {
          max-width: 100%;
        }
      }
    }
    max-width: 830px;
    width: 100%;
  }

  .w-452 {
    /*width: 452px;*/
  }

  .diagonal-line::before {
    @media screen and (min-width: 1023px) {
      content: "";
      position: absolute;
      width: 120px;
      height: 1px;
      border-radius: 35px;
      background-color: #d3d3d3;
      top: 70px;
      right: -90px;
      transform: rotate(315deg);
    }
  }

  @media only screen and (max-width: 765px) {
    .btn-custom-m {
      top: -100px;
      left: 170px;
    }
    .oscar-mobile {
      top: -105px;
      left: 170px;
      max-width: 100px;
    }
    .alberto-mobile {
      left: 0;
      margin: 0;
      margin-left: 28px;
      top: 200px;
    }
    .alberto-oscar {
      min-width: unset;
      max-width: unset;
      width: auto;
      left: 0px;
      min-height: 332px;
      max-height: 332px;
    }
    .oscar-mobile {
      width: 190px;
    }
    .bg-alberto:before {
      width: 401px;
      height: 150px;
      top: 158px;
      left: -156px;
    }
    .bg-oscar:before {
      width: 270px;
      height: 150px;
      top: 122px;
      left: -57px;
    }
    .w-452 {
      width: 100%;
    }
    /*.m-height{
            max-height: 490px;
        }*/
    .diagonal-line:before {
      content: unset;
    }
    .features {
      > div {
        > p {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  @media only screen and (min-width: 1536px) {
    .bg-alberto::before {
      content: unset;
    }

    .features {
      max-width: 1050px;
      width: 100%;
    }
    .fix-box {
      max-width: 768px;
      margin: 0 0 0 auto;
    }
  }
`;
