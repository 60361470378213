import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import panel2DesktopFrame from "assets/high-converting-ecommerce/desktop-frame@1x.webp";
import panel2AppFrame from "assets/high-converting-ecommerce/app frame@1x.webp";
import panel2Step1Desktop from "assets/high-converting-ecommerce/step 1 desktop ecommerce@1x.webp";
import panel2Step1App from "assets/high-converting-ecommerce/step 1 mobile ecommerce@1x.webp";
import panel2Step2Desktop from "assets/high-converting-ecommerce/step 2 desktop ecommerce@1x.webp";
import panel2Step2App from "assets/high-converting-ecommerce/step 2 mobile ecommerce@1x.webp";
import panel2Step3Desktop from "assets/high-converting-ecommerce/step 3 desktop e-commerce@1x.webp";
import panel2Step3App from "assets/high-converting-ecommerce/mobile con elementos fuera@1x.webp";
import panel2Step4Desktop from "assets/high-converting-ecommerce/step 4 desktop ecommerce@1x.webp";
import panel2Step4App from "assets/high-converting-ecommerce/4 step mobile e-commerce@1x.webp";
import panel2Step5Desktop from "assets/high-converting-ecommerce/step 5 desktop ecommerce@1x.webp";
import panel2Step5App from "assets/high-converting-ecommerce/step 5 mobile ecommerce@1x.webp";
import PanelImagesWrapper from "../PanelImagesWrapper";
import chevronServicios from "assets/high-converting-ecommerce/chevron-servicios.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";

const Panel2Images = ({ className = null, id = null }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const controlsRef = useRef(null);
  const leftControlAnimationRef = useRef(null);
  const rightControlAnimationRef = useRef(null);

  const { t } = useTranslation("common");

  const [reachedEnd, setReachedEnd] = useState(false);

  const slides = [
    {
      title: t("services.high converting ecommerce.step1.title"),
      desktopImage: panel2Step1Desktop,
      appImage: panel2Step1App,
      altImage: "img-alts.e-commerce-creation",
    },
    {
      title: t("services.high converting ecommerce.step2.title"),
      desktopImage: panel2Step2Desktop,
      appImage: panel2Step2App,
      altImage: "img-alts.e-commerce-seo",
    },
    {
      title: t("services.high converting ecommerce.step3.title"),
      desktopImage: panel2Step3Desktop,
      appImage: panel2Step3App,
      altImage: "img-alts.e-commerce-shopify",
    },
    {
      title: t("services.high converting ecommerce.step4.title"),
      desktopImage: panel2Step4Desktop,
      appImage: panel2Step4App,
      altImage: "img-alts.e-commerce-design",
    },
    {
      title: t("services.high converting ecommerce.step5.title"),
      desktopImage: panel2Step5Desktop,
      appImage: panel2Step5App,
      altImage: "img-alts.e-commerce-optimization",
    },
  ];

  function nextSlide() {
    //console.log(currentSlide);
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
    if (currentSlide === slides.length - 1) {
      setReachedEnd(true);
      rightControlAnimationRef.current.pause().seek(0);
    }
  }
  function prevSlide() {
    //console.log(currentSlide);
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  }

  useEffect(() => {
    const controlsSelector = gsap.utils.selector(controlsRef);

    function controlAnimation(el) {
      //console.log(controlsSelector(`${el} .chevron-2`));
      return gsap
        .timeline()
        .fromTo(
          controlsSelector(`${el} .chevron-2`),
          {
            opacity: 1,
            x: "-0%",
          },
          {
            opacity: 0,
            x: "-250%",
            duration: 0.8,
          }
        )
        .fromTo(
          controlsSelector(`${el} .chevron-3`),
          {
            opacity: 1,
            x: "-0%",
          },
          {
            opacity: 0,
            x: "-160%",
            duration: 0.8,
          },
          "-=0.4"
        )
        .delay(1.5)
        .repeat(-1)
        .paused(true);
    }

    leftControlAnimationRef.current = controlAnimation(".panel-paginator-arrow.left");
    rightControlAnimationRef.current = controlAnimation(".panel-paginator-arrow.right");
    let onceRightControlAnimation = controlAnimation(".panel-paginator-arrow.right");
    onceRightControlAnimation.repeat(-1).play();
  }, []);

  return (
    <StyledPanelImages id={id} className={className}>
      <div className="absolute inset-0">
        <div className="panel-2-desktop-container absolute flex flex-row items-center inset-0">
          <div className="panel-2-desktop">
            <div id="panel-2-desktop-frame" className="panel-2-desktop-wrapper">
              <img src={panel2DesktopFrame} alt="" />
              <div className="panel-2-desktop-steps-wrapper">
                {slides.map((slide, index) => (
                  <img key={index} src={slide.desktopImage} className={`transition-opacity panel-2-step${index + 1}-desktop ${currentSlide === index ? "opacity-1 visible" : "opacity-0 invisible"}`} alt={t(slide.altImage)} />
                ))}
              </div>
            </div>
            <div id="panel-2-paginator">
              <div className="panel-2-paginator-content">
                <div className="panel-2-paginator-controls" ref={controlsRef}>
                  <div onMouseOver={() => leftControlAnimationRef.current.restart()} onMouseOut={() => leftControlAnimationRef.current.pause().seek(0)} className={`panel-paginator-arrow left transition-opacity relative ${currentSlide === 0 ? "pointer-events-none opacity-0" : "cursor-pointer"}`} onClick={currentSlide === 0 ? null : prevSlide}>
                    <span className="relative chevron-1 top-0">
                      <img src={chevronServicios} alt="previous slide" />
                    </span>
                    <span className="absolute chevron-2 top-0">
                      <img src={chevronServicios} alt="previous slide" />
                    </span>
                    <span className="absolute chevron-3 top-0">
                      <img src={chevronServicios} alt="previous slide" />
                    </span>
                  </div>
                  <div>
                    <div className="panel-paginator-display">
                      <div className="text-base text-light-medium font-light">{currentSlide + 1}</div>
                    </div>
                  </div>
                  <div onMouseOver={reachedEnd ? () => rightControlAnimationRef.current.restart() : null} onMouseOut={reachedEnd ? () => rightControlAnimationRef.current.pause().seek(0) : null} className={`panel-paginator-arrow transition-opacity right relative ${currentSlide + 1 === slides.length ? "pointer-events-none opacity-0" : "cursor-pointer"}`} onClick={currentSlide + 1 === slides.length ? null : nextSlide}>
                    <span className="relative chevron-1 top-0">
                      <img src={chevronServicios} alt="previous slide" />
                    </span>
                    <span className="absolute chevron-2 top-0">
                      <img src={chevronServicios} alt="previous slide" />
                    </span>
                    <span className="absolute chevron-3 top-0">
                      <img src={chevronServicios} alt="previous slide" />
                    </span>
                  </div>
                </div>
                <div className="paginator-slide-name text-light-medium text-base">{slides[currentSlide].title}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel-2-app-wrapper pointer-events-none">
          <div className="panel-2-app">
            <div id="panel-2-app-frame">
              <img src={panel2AppFrame} alt="decoration" />
              <div className="panel-2-app-steps-wrapper">
                <div className="panel-2-app-inner-steps-wrapper absolute">
                  {slides.map((slide, index) => (
                    <div className={`panel-2-step${index + 1}-wrapper-app`}>
                      <img key={index} src={slide.appImage} className={`transition-opacity panel-2-step${index + 1}-app ${currentSlide === index ? "opacity-1 visible" : "opacity-0 invisible"}`} alt="" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledPanelImages>
  );
};

const StyledPanelImages = styled(PanelImagesWrapper)`
  & {
    --desktop-width: 50%;
    --right-margin: calc((100% - var(--desktop-width)) / 3 * 2);
  }

  .panel-2-desktop {
    @media screen and (max-width: 1023px) {
      margin-left: 0;
      width: 80%;
      position: relative;
      transform: translate(-22%, -12%);
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-left: calc((100% - var(--desktop-width)) / 6);
      width: var(--desktop-width);
      margin-right: calc((100% - var(--desktop-width)) / 3 * 2);
      position: relative;
      transform: translatey(40%);
    }
    @media screen and (min-width: 1440px) {
      margin-left: calc((100% - var(--desktop-width)) / 3);
      width: var(--desktop-width);
      margin-right: calc((100% - var(--desktop-width)) / 3 * 2);
      position: relative;
      transform: translatey(-10%);
    }
    @media screen and (min-width: 1920px) {
      margin-left: calc((100% - var(--desktop-width)) / 3);
      width: var(--desktop-width);
      max-width: 490px;
      margin-right: calc((100% - var(--desktop-width)) / 3 * 2);
      position: relative;
      transform: translatey(-10%);
    }
  }
  .panel-2-desktop-wrapper {
  }

  .panel-2-step1-desktop,
  .panel-2-step2-desktop,
  .panel-2-step3-desktop,
  .panel-2-step4-desktop,
  .panel-2-step5-desktop,
  .panel-2-step1-app,
  .panel-2-step2-app,
  .panel-2-step3-app,
  .panel-2-step4-app,
  .panel-2-step5-app {
    position: absolute;
  }
  .panel-2-desktop-steps-wrapper {
    position: absolute;
    inset: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: 3.2%;
  }
  .panel-2-app-steps-wrapper {
    position: absolute;
    inset: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .panel-2-app-inner-steps-wrapper {
    left: 32%;
    top: 18%;
    right: 20%;
    bottom: 20%;
  }
  .panel-2-step3-wrapper-app {
    transform: translatex(-48%);
  }
  .panel-2-step3-app {
    width: 182.5%;
    left: 0;
    max-width: none;
  }
  .panel-2-step4-wrapper-app {
    transform: translate(1%, 2%);
  }
  .panel-2-step4-app {
    width: 136%;
    max-width: none;
  }
  .panel-2-step2-app,
  .panel-2-step5-app {
    max-width: none;
    width: 100%;
  }
  .panel-2-step1-app {
    max-width: none;
    width: 160%;
  }
`;

export default Panel2Images;
