import React from "react"; //importamos react
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "utilities/context";

export default function LocaleBar({ className = "inline-flex flex-row items-center text-dark-medium divide-x divide-gray-11", localeClassName = "px-2" }) {
  const { t, i18n } = useTranslation("common");
  const { baseURL, baseURLParams } = useAppContext();
  let navigate = useNavigate();

  const MenuLinks = [
    { name: "es", to: "" },
    { name: "en", to: "/en" },
  ];

  function changeLanguage(language) {
    let pathname = window.location.pathname.replace(baseURL, "");
    let country = Boolean(baseURLParams.country) ? "/" + baseURLParams.country : "";
    window.location = window.location.origin + language + country; //+ pathname;
    // pathname will be added later by checking which div has the active class
  }

  return (
    <div className={className}>
      {MenuLinks.map((item) => (
        <span className={`leading-tight text-sm uppercase tracking-widest ${i18n.language.includes(item.name) ? " font-bold" : ""} ${localeClassName}`} key={item.name}>
          <div className="cursor-pointer appearance-none" type="button" reloadDocument to={item.to} key={item.name} onClick={() => changeLanguage(item.to)}>
            {t(`navigation.${item.name}`)}
          </div>
        </span>
      ))}
    </div>
  );
}
