/* import oscar_grande from "assets/images/Oscar-grande.webp";
import oscar_mobile from "assets/images/Oscar-mobile.webp";
import pildoraAlberto from "assets/about-us/pildora-alberto-alvarez.webp";
import alberto_mobile from "assets/images/Alberto-Mobile.webp"; */
import { useAppContext } from "utilities/context";
import oscar_y_alberto from "assets/images/oscar-y-albert.webp";
//import FadeIn from "components/animations/FadeIn";
import { useTranslation } from "react-i18next";
/* 
import infinityIcon from "assets/about-us/infinity-icon.svg";
import { BLOG_URL } from "constants/constants"; */

const AboutUsBackground = () => {
  const { t } = useTranslation([]);
  const { openOscarModal, openAlbertoModal } = useAppContext();
  /*   const data = {
    infinityIcon: infinityIcon,
    oscar: {
      photo: oscar_grande,
      photoResponsive: oscar_mobile,
      titleI18nKey: "about-us.Oscar.title",
      contentI18nkey: "about-us.Oscar.category",
      blogLink: BLOG_URL,
      linkedIn: "https://www.linkedin.com/in/oscaralvarezvzla/",
    },
    alberto: {
      photo: pildoraAlberto,
      photoResponsive: alberto_mobile,
      titleI18nKey: "about-us.Alberto.title",
      contentI18nkey: "about-us.Alberto.category",
      blogLink: BLOG_URL,
      linkedIn: "https://www.linkedin.com/in/albertoalvarezm/",
    },
  }; */
  return (
    <div className="about-us-background-wrapper">
      <div className="about-us-background-inner-wrapper">
        <div className="about-us-background relative lg:block">
          <div className="relative">
            <img className="relative right-0 lg:block lg:min-w-full" src={oscar_y_alberto} width={791} height={584} alt={t("img-alts.alberto-oscar")} />
            <div className="link-about-us-oscar flex flex-col w-max absolute">
              <h3 className="font-bold text-sm text-white relative mb-2">
                {t("about-us.president")}{" "}
                <span className="block font-light">{t("about-us.president-position")}</span>
              </h3>
              <button onClick={openOscarModal} className="link font-bold text-sm relative text-white mr-auto  border-solid border border-gray-500 rounded-full px-2">
                + info
              </button>
            </div>
            <div className="link-about-us-alberto flex flex-col w-max absolute">
              <h3 className="font-bold text-left text-sm text-white relative z-10 mb-2">
                {t("about-us.ceo")}{" "}
                <span className="block font-light">{t("about-us.ceo-position")}</span>
              </h3>
              <button onClick={openAlbertoModal} className="link font-bold self-end text-sm relative text-white z-10 mr-auto  border-solid border border-gray-500 rounded-full px-2">
                + info
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsBackground;
