import { useRef, useLayoutEffect, useMemo } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);

function useSelector() {
  const ref = useRef();
  const q = useMemo(() => gsap.utils.selector(ref), [ref]);
  return [q, ref];
}

export default function FadeIn({ id, children, direction = "top", start = "75%", end = "65%", markers = false, className = null }) {
  const [q,ref] =useSelector()

  useLayoutEffect(() => {
    let directionObj = {
      top: { y: "-50px" },
      bottom: { y: "50px" },
      right: { x: "100px" },
      left: { x: "-100px" },
      none: {},
    };

    function fadeIn(el, trigger = null) {
      let animationObj = gsap.timeline().from(el, {
        ...directionObj[direction],
        autoAlpha: 0,
        scrollTrigger: {
          trigger: trigger ? trigger : el,
          start: `center ${start}`,
          end: `center ${end}`,
          markers: markers,
          scrub: true,
          id: id,
        },
      });

      return animationObj;
    }

    fadeIn(ref.current);
    
  }, [q,ref, id, direction, start, end, markers]);

  return (
    <div id={`fade-${direction}-${id ? id : Math.random() * 999999}`} ref={ref} className={className}>
      {children}
    </div>
  );
}
