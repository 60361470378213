import PanelContentBodyWrapper from "../PanelContentBodyWrapper";
import PanelContentFooterWrapper from "../PanelContentFooterWrapper";
import PanelContentSubtitleWrapper from "../PanelContentSubTitleWrapper";
import PanelContentTitleWrapper from "../PanelContentTitleWrapper";
import PanelContentWrapper from "../PanelContentWrapper";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import panel2CanalPanamaLogo from "assets/high-converting-ecommerce/canal-de-panama-logo@1x.webp";
import panel2CamperoLogo from "assets/high-converting-ecommerce/pollo-campero-logo@1x.webp";
import panel2SamsungLogo from "assets/high-converting-ecommerce/samsung-logo@1x.webp";
import panel2DigicelLogo from "assets/high-converting-ecommerce/digicel-logo@1x.webp";

const HighPerformanceEcommerceContent = ({ id = null, setExpandedPanel, expandedPanel }) => {
  const { t } = useTranslation(["common"]);

  return (
    <StyledDiv>
      <PanelContentWrapper id="panel-2-content" setExpandedPanel={setExpandedPanel} expandedPanel={expandedPanel}>
        <PanelContentTitleWrapper titlePreview={t("services.high converting ecommerce.title-preview")} number={2}>
          {" "}
          <Trans i18nKey="services.high converting ecommerce.title">
            <span className="block 2xl:whitespace-pre-wrap">
              <span className="block 2xl:inline-block">high performance</span>
              <span className="block 2xl:inline-block whitespace-nowrap">e-commerce</span>
            </span>
          </Trans>
        </PanelContentTitleWrapper>
        <PanelContentSubtitleWrapper className="whitespace-pre-line 2xl:whitespace-pre-line">{t("services.high converting ecommerce.subtitle")}</PanelContentSubtitleWrapper>
        <PanelContentBodyWrapper>
          <Trans i18nKey="services.high converting ecommerce.content">
            <span className="block mb-6">Los servicios de </span>
            <span className="block">optimización de la tasa de conversión</span>
          </Trans>
        </PanelContentBodyWrapper>
        <PanelContentFooterWrapper>
          <h4 className="uppercase whitespace-pre-line text-sm pr-6 underline-decoration">{t("services.high converting ecommerce.successful-cases")}</h4>
          <div>
            <img src={panel2CanalPanamaLogo} alt="Canal de Panama logo" title="Canal de Panamá" className="w-auto h-auto object-contain pr-2" />
          </div>
          <div>
            <img src={panel2CamperoLogo} alt="Pollo Campero logo" title="Pollo Campero" className="w-auto h-9 object-contain pr-2" />
          </div>
          <div>
            <img src={panel2SamsungLogo} alt="Samsung logo" title="Samsung" className="w-auto h-auto object-contain pr-2" />
          </div>
          <div>
            <img src={panel2DigicelLogo} alt="Digicel logo" title="Digicel" className="w-auto h-auto object-contain" />
          </div>
        </PanelContentFooterWrapper>
      </PanelContentWrapper>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  #panel-2-content span {
    font-size: 1.7rem;
    font-weight: 600;
  }
`;
export default HighPerformanceEcommerceContent;
