import styled from "styled-components";
import { ReactComponent as Arrow } from "assets/flecha larga.svg";
import { useAppContext } from "utilities/context";

export const ArrowLink = ({ url = null, target = "_blank", children, className = "", onClick = null }) => {
  const { openContactForm } = useAppContext()
  if (Boolean(url)) {
    return (
      <StyledLink className={`${className} text-base font-normal inline-flex flex-nowrap flex-row justify-start items-center cursor-pointer`} href={url} target={target}>
        <span className="link-text">{children}</span>&nbsp;
        <span className="link-arrow relative w-4 h-3">
          <Arrow className="w-4 h-3 fill-current" />
          <span className="link-arrow-animation absolute inset-0">
            <Arrow className="w-4 h-3 fill-current" />
          </span>
        </span>
      </StyledLink>
    );
  }
  if (!Boolean(url))
    return (
      <StyledButton
        className={`${className} text-base font-normal inline-flex flex-nowrap flex-row justify-start items-center cursor-pointer`}
        onClick={onClick ? onClick : openContactForm}>
        <span className="link-text">{children}</span>&nbsp;
        <span className="link-arrow relative w-4 h-3">
          <Arrow className="w-4 h-3 fill-current" />
          <span className="link-arrow-animation absolute inset-0">
            <Arrow className="w-4 h-3 fill-current" />
          </span>
        </span>
      </StyledButton>
    );
};

const StyledButton = styled.span`
  & {
    transition: opacity 0.3s ease;
  }
  @keyframes link-arrow {
    0% {
      transform: translatex(0);
      opacity: 1;
    }
    50% {
      transform: translatex(100%);
      opacity: 0;
    }
  }
  &:hover {
    opacity: 0.9;
    .link-arrow-animation {
      transition: transform 0.5s ease, opacity 0.5s ease;
      animation: link-arrow 1s infinite;
      opacity: 0;
    }
  }

  .link-text {
    padding-right: 0.25rem;
  }
`;

const StyledLink = styled.a`
  & {
    transition: opacity 0.3s ease;
  }
  @keyframes link-arrow {
    0% {
      transform: translatex(0);
      opacity: 1;
    }
    50% {
      transform: translatex(100%);
      opacity: 0;
    }
  }
  &:hover {
    opacity: 0.9;
    .link-arrow-animation {
      transition: transform 0.5s ease, opacity 0.5s ease;
      animation: link-arrow 1s infinite;
      opacity: 0;
    }
  }

  .link-text {
    padding-right: 0.25rem;
  }
`;
