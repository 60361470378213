import PanelBackground from "../PanelBackground";
import styled from "styled-components";
import React, { useEffect, forwardRef, Suspense, useRef } from "react";
// import video from "assets/performance-consulting/Performly_servicios-consulting-ref-video_2.mp4";
import performanceConsultingBackgroundResponsive from "assets/performance-consulting/performance-consulting-background-responsive.webp";
import { useTranslation } from "react-i18next";

const Panel1Background = forwardRef(({ children, className = null, id = null }, ref) => {
  const { t } = useTranslation("common");
  const attemptPlay = () => {
    videoRef &&
      videoRef.current &&
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const videoRef = useRef(null);

  return (
    <StyledPanelBackground id={id}>
      <Suspense fallback={<div>loading</div>}>
        <div ref={ref} className="hidden md:block h-full performance-consulting-video-wrapper">
          <video id="performance-consulting-video" width={1204} height={670} ref={videoRef} muted loop playsInline={true} className="relative" title={t("img-alts.digital-marketing-performance-consulting")}>
            <source src="performance-consulting/performance-consulting-background-video-compressed.mp4" type="video/mp4" />
          </video>
        </div>
      </Suspense>
      <div className="md:hidden h-full">
        <img src={performanceConsultingBackgroundResponsive} ref={ref} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
    </StyledPanelBackground>
  );
});

const StyledPanelBackground = styled(PanelBackground)`
  & {
    .performance-consulting-video-wrapper {
      overflow: hidden;
    }
    #performance-consulting-video {
      @media screen and (min-width: 1024px) and (max-width: 1439px) {
        transform: translateX(-20%);
      }
      @media screen and (min-width: 1440px) and (max-width: 1600px) {
        margin-left: -13%;
      }
      @media screen and (min-width: 1600px) and (max-width: 1800px) {
        margin-left: -6%;
      }
      @media screen and (min-width: 1800px) and (max-width: 200px) {
        margin-left: -3%;
      }
    }
  }
`;

export default Panel1Background;
