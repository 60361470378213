import styled from "styled-components";
import ModalContentElementBackground from "./ElementsBackground";

const ModalContentWrapper = ({ children }) => (
  <StyledDiv className="bg-gray-2 relative z-50 w-screen min-h-full max-h-screen overflow-y-scroll">
    <ModalContentElementBackground />
    <div className="container  ">{children}</div>
  </StyledDiv>
);

const StyledDiv = styled.div``;

export default ModalContentWrapper;
