import React from "react";
import styled from "styled-components";
//import { gsap } from "gsap";
//import { useSelector } from "utilities/functions";
//import { useAppContext } from "utilities/context";

const defaultCSSClasses = `
container`;

const ResponsiveModalContentWrapper = ({ children, id = null, className = null, payload = null }) => {
  //const { modalDispatch } = useAppContext();

  return (
    <StyledDiv id={id} className={`${defaultCSSClasses} ${className ? ` ${className}` : ""} container relative`}>
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  & {
    @media screen and (max-width: 767px) {
      padding-bottom: 5rem;
      position: relative;
    }
  }
  .expand-toggle {
    @media screen and (min-width: 768px) {
      display: none;
    }
    bottom: 5rem;
    right: 0rem;
  }
`;

export default ResponsiveModalContentWrapper;
