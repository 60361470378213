import React, { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Select, Input } from "@material-ui/core";
import candado from "assets/contact-us/candado.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { SubmitButton } from "./SubmitButton";
import LogoLight from "assets/logo-light.svg";
import AvatarPresidente from "./AvatarPresident";
import Axios from "axios";
import { useAppContext } from "utilities/context";

export const ContactForm = () => {
  const { t } = useTranslation();
  const nameFieldRef = useRef(null);
  const lastnameFieldRef = useRef(null);
  const { openThankYouModal, contactFormData, updateContactFormData } = useAppContext();
  // const [showRequestError, setShowRequestError] = useState(false);

  const postURL = "https://hooks.zapier.com/hooks/catch/638494/bdlphe1";

  let country = "";
  let language = "";
  let hreflang = document.querySelector('link[rel="alternate"]').getAttribute("hreflang");
  if (hreflang) {
    country = hreflang.split("-")[1]?.toLowerCase();
    language = hreflang.split("-")[0]?.toLowerCase();
  }

  const initialValues = {
    nombre: contactFormData.nombre || "",
    apellido: contactFormData.apellido || "",
    compania: contactFormData.compania || "",
    correo: contactFormData.correo || "",
    presupuesto: contactFormData.presupuesto || "",
    contexto: contactFormData.contexto || "",
    servicios: contactFormData.servicios || "",
    country: Boolean(country) ? country : "pa",
    language: Boolean(language) ? language : "es",
  };

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required(t("contact-us:error campo requerido nombre")),
    apellido: Yup.string().required(t("contact-us:error campo requerido apellido")),
    compania: Yup.string().required(t("contact-us:error campo requerido compania")),
    correo: Yup.string().email(t("contact-us:error email invalido")).required(t("contact-us:error campo requerido email")),
    presupuesto: Yup.string(),
    contexto: Yup.string(),
    servicios: Yup.string(),
  });

  function _handleSubmit(values, actions) {
    // console.log("submit", values);
    var queryString = Object.keys(values)
      .map((key) => key + "=" + values[key])
      .join("&");
    Axios.get(postURL + "?" + queryString)
      .then((response) => {
        //console.log("response", response);
        updateContactFormData(values);
        if (response.status === 200) {
          //console.log("success");
          actions.setSubmitting(false);
          // event = "", section = "", name = ""
          // gtmDataLayer("Inició trámite", "contact-form", "Inició trámite_LandingPage");
        } else {
          //console.log("error submiting form");
          actions.setSubmitting(false);
        }
      })
      .catch((error) => {
        //console.log("error submiting form");
      })
      .finally(() => {
        actions.setSubmitting(false);
        openThankYouModal();
        // Sending GTM event
        try {
        window.dataLayer.push({ event: "Lead", section: "contact-form", name: "Analytics_Web_Lead" });
        } catch (error) {
          console.log("Error sending GTM event on form submit");
        }
      });
  }

  const rangosDeInversion = [
    { label: t(`contact-us:opciones rango de inversion.option-0`), value: t(`contact-us:opciones rango de inversion.option-0`) },
    { label: t(`contact-us:opciones rango de inversion.option-1`), value: t(`contact-us:opciones rango de inversion.option-1`) },
    { label: t(`contact-us:opciones rango de inversion.option-2`), value: t(`contact-us:opciones rango de inversion.option-2`) },
    { label: t(`contact-us:opciones rango de inversion.option-3`), value: t(`contact-us:opciones rango de inversion.option-3`) },
  ];
  const servicios = [
    { label: t(`contact-us:opciones servicios.option-0`), value: t(`contact-us:opciones servicios.option-0`) },
    { label: t(`contact-us:opciones servicios.option-1`), value: t(`contact-us:opciones servicios.option-1`) },
    { label: t(`contact-us:opciones servicios.option-2`), value: t(`contact-us:opciones servicios.option-2`) },
    { label: t(`contact-us:opciones servicios.option-3`), value: t(`contact-us:opciones servicios.option-3`) },
    { label: t(`contact-us:opciones servicios.option-4`), value: t(`contact-us:opciones servicios.option-4`) },
    { label: t(`contact-us:opciones servicios.option-5`), value: t(`contact-us:opciones servicios.option-5`) },
  ];

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      nameFieldRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <StyledDiv className="increment-roi-icon w-full lg:w-3/4 xl:w-2/3 max-h-contact ml-0 text-white self-center">
      <div className="logo mb-8 overflow-hidden w-14 md:w-full">
        <img src={LogoLight} alt="Performly logo" className="h-10 max-w-none" />
      </div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={_handleSubmit}>
        {({ values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue }) => (
          <Form id={"contact-us"} className="space-y-5 flex flex-col items-center">
            <div className="hidden md:block lg:white-space-pre-line xl:whitespace-normal text-white font-light self-start">{t("contact-us:llena el formulario y te contactaremos al instante")}</div>
            <TextField autoFocus className="name-field" inputRef={nameFieldRef} fullWidth value={values.nombre} placeholder={t("contact-us:nombre")} id="nombre" name="nombre" variant="outlined" size="small" error={touched.nombre && errors.nombre} helperText={touched.nombre && errors.nombre} onChange={handleChange} />
            <TextField autoFocus className="name-field" inputRef={lastnameFieldRef} fullWidth value={values.apellido} placeholder={t("contact-us:apellido")} id="apellido" name="apellido" variant="outlined" size="small" error={touched.apellido && errors.apellido} helperText={touched.apellido && errors.apellido} onChange={handleChange} />
            <TextField fullWidth value={values.correo} placeholder={t("contact-us:correo")} id="correo" name="correo" variant="outlined" size="small" error={touched.correo && errors.correo} helperText={touched.correo && errors.correo} onChange={handleChange} />
            <TextField fullWidth value={values.compania} placeholder={t("contact-us:compania")} id="compania" name="compania" variant="outlined" size="small" error={touched.compania && errors.compania} helperText={touched.compania && errors.compania} onChange={handleChange} />
            <Select
              native
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: "servicios",
                id: "servicios",
              }}>
              {servicios.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.value}
                </option>
              ))}
            </Select>
            <Select
              native
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: "presupuesto",
                id: "presupuesto",
              }}>
              {rangosDeInversion.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.value}
                </option>
              ))}
            </Select>

            <TextField fullWidth value={values.contexto} placeholder={t("contact-us:descripcion de contexto")} id="contexto" name="contexto" multiline rows={3} variant="outlined" error={touched.contexto && errors.contexto} helperText={touched.contexto && errors.contexto} onChange={handleChange} />

            <SubmitButton type="submit" className="relative rounded-full bg-white w-4/5 mx-auto lg:ml-0 lg:w-auto px-6 py-2 font-light">
              {isSubmitting ? t("contact-us:Enviando") : t("contact-us:hablemos ahora")}
            </SubmitButton>
            <div className="hidden lg:flex flex-row items-center lg:self-start font-light">
              <img src={candado} alt="decoracion" className="h-4 w-4 object-contain" />
              <span className="pl-2">{t("contact-us:su informacion es 100% confidencial")}</span>
            </div>
          </Form>
        )}
      </Formik>
      <div className="lg:hidden">
        <AvatarPresidente />
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  select,
  input {
    padding: 0.5rem 0.75rem;
  }
`;
