import React, { forwardRef } from "react";
import styled from "styled-components"; //permite crear estilos para este archivo

const defaultCSSClasses = "panel-content-subtitle xl:text-lg font-light text-light-dark mb-10 overflow-visible whitespace-nowrap";

const PanelContentSubtitleWrapper = forwardRef(({ children, id = null, className = null }, ref) => (
  <StyledSection>
    <div id={id} ref={ref} className={`${defaultCSSClasses}${className ? ` ${className}` : ""}`}>
      {children}
    </div>
  </StyledSection>
));

const StyledSection = styled.div`
  .panel-content-subtitle {
    @media screen and (min-width: 1024px) and (max-width: 1359px) {
      font-size: 1.2rem;
      width: 30rem;
    }
    @media screen and (min-width: 1360px) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: 765px) {
      font-size: 1.4rem;
    }
  }
`;

export default PanelContentSubtitleWrapper;
