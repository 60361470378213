import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import numeral from "numeral";
import App from "./App";
import "./i18n";
import { AppContextProvider } from "./utilities/context";
import Loader from "./components/loader/Loader";
import "./styles/global.scss";
import { AlertContextProvider } from "hooks/useAlert";

// Google Recatpcha v3
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Google Tag Manager
// import TagManager from "react-gtm-module";
// const tagManagerArgs = {
//   // gtmId: process.env.GTM_ID, // not working
//   gtmId: "GTM-K5SKSRL",
// };
// TagManager.initialize(tagManagerArgs);

numeral.register("locale", "es", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "USD" : "USD";
  },
  currency: {
    symbol: "$",
  },
});

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("load", setVh);
window.addEventListener("resize", setVh);

ReactDOM.render(
  <React.StrictMode>
    <AlertContextProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LfZ-T8eAAAAAPEi0uVkeeEaXjKaX7DkQS0Txe_p"
            language="en"
            useRecaptchaNet="true"
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: "head", // optional, default to "head", can be "head" or "body",
              nonce: undefined, // optional, default undefined
            }}>
            <App />
          </GoogleReCaptchaProvider>
        </Suspense>
      </AppContextProvider>
    </AlertContextProvider>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
