import PanelBackground from "../PanelBackground";
import styled from "styled-components";
import panel2Background from "assets/high-converting-ecommerce/background@1x.webp";
import panel2BackgroundResponsive from "assets/high-converting-ecommerce/ecommerce-background-responsive.webp";

const Panel2Background = ({ className = null, id = null }) => {
  return (
    <StyledPanelBackground id={id} className={className}>
      <div className="hidden md:block h-full">
        <img src={panel2Background} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
      <div className="md:hidden h-full">
        <img src={panel2BackgroundResponsive} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
    </StyledPanelBackground>
  );
};

const StyledPanelBackground = styled(PanelBackground)``;

export default Panel2Background;
