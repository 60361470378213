import PanelBackground from "../PanelBackground";
import styled from "styled-components";
import React, { forwardRef } from "react";
import panel5Background from "assets/b2b marketing/fondo@1x.webp";
import panel5BackgroundResponsive from "assets/b2b marketing/b2b-marketing-background-responsive.webp";
const Panel5Background = forwardRef(({ children, className = null, id = null }, ref) => {
  return (
    <StyledPanelBackground id={id}>
      <div className="hidden md:block h-full">
        <img src={panel5Background} ref={ref} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
      <div className="md:hidden md:block h-full">
        <img src={panel5BackgroundResponsive} alt="decoration" className="lg:hidden object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
    </StyledPanelBackground>
  );
});

const StyledPanelBackground = styled(PanelBackground)``;

export default Panel5Background;
