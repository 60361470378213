import React, { useContext, useState, createContext } from 'react'

const AlertContext = createContext({})

export const AlertContextProvider = ({children}) => {
  const Alert = useProviderAlert()
  return (
    <AlertContext.Provider value={Alert}>
      {children}
    </AlertContext.Provider>
  )
}

export const useAlert = () => {
  return useContext(AlertContext)
}

const useProviderAlert = () => {
  const [open, setOpen] = useState(false)
  const [alertType, setAlertType] = useState("error")
  const [alertText, setAlertText] = useState("Error")
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  
  return {
    open,
    handleClose,
    handleOpen,
    setAlertType,
    alertType,
    alertText, 
    setAlertText
  }
}