import React from "react";
import styled from "styled-components";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
//import { useSelector } from "utilities/functions";
//import videoSprites from "assets/performance-consulting/video-frames-set-2/performly sprites.webp";
gsap.registerPlugin(ScrollTrigger);

const VideoFrames = ({ count = 10 }) => {
  return (
    <StyledDiv id="responsive-services-section-1-animation">
      <div className="responsive-service-images-wrapper-outter">
        <div className="responsive-services-images-wrapper-inner">
          <canvas id="responsive-performance-consulting-video-frames"></canvas>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  &#responsive-services-section-1-animation {
    width: 100%;
    position: relative;
    //height: 0;
    .responsive-service-images-wrapper-outter {
      position: relative;
      padding-bottom: 115.9%;
    }
    .responsive-services-images-wrapper-inner {
      position: absolute;
      inset: 0;
      overflow: hidden;
    }
    #responsive-performance-consulting-video-frames {
      width: 100%;
      height: 100%;
    }
  }
`;

export default VideoFrames;
