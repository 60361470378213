import React from "react";

const defaultCSSClasses = "panel-images-wrapper-inner";

const PanelImagesWrapper = ({ children, id = null, className = null }) => (
  <div id={id} className={`${defaultCSSClasses} ${className ? ` ${className}` : ""}`}>
    {children}
  </div>
);

export default PanelImagesWrapper;
