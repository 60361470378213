import React, { useLayoutEffect } from "react"; //importamos react
import { useTranslation } from "react-i18next";
import styled from "styled-components"; //permite crear estilos para este archivo
import LogoLight from "assets/logo-light.svg";
import WhyUsHov1 from "assets/why-us/why-us-hov-1@1x.webp";
import WhyUsHov2 from "assets/why-us/why-us-hov-2@1x.webp";
import WhyUsHov3 from "assets/why-us/why-us-hov-3@1x.webp";
import WhyUsHov4 from "assets/why-us/why-us-hov-4@1x.webp";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import { addToRefArray } from "utilities/functions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import cardFrame from "assets/images/border-WhyUs2.webp";
import FadeIn from "components/animations/FadeIn";
import { RevealBox } from "components/common/RevealBox";
import { useSelector } from "utilities/functions";
const cards = [
  { title: "title", subtitle: "subtitle", hoverImage: WhyUsHov4, imgAlt: "alt" },
  { title: "title", subtitle: "subtitle", hoverImage: WhyUsHov3, imgAlt: "alt" },
  { title: "title", subtitle: "subtitle", hoverImage: WhyUsHov1, imgAlt: "alt" },
  { title: "title", subtitle: "subtitle", hoverImage: WhyUsHov2, imgAlt: "alt" },
];

gsap.registerPlugin(ScrollTrigger);

export default function WhyUs({ id = null }) {
  const { t } = useTranslation("common");
  const [q, ref] = useSelector();
  useLayoutEffect(() => {
    function cardsAnimationMinWidthTablet() {
      let animationObj = gsap.timeline().from(q(".why-us-card"), {
        opacity: 0,
        x: "-100%",
        duration: 0.4,
        //ease: EASE_OUT_DEFAULT,
        stagger: {
          each: 0.4,
        },
      });
      let STObj = ScrollTrigger.create({
        id: "why us cards",
        animation: animationObj,
        trigger: q(".why-us-cards-container"),
        toggleActions: "play none none reverse",
        start: `top 75%`,
        //end: `top 65%`,
        //scrub: true,
      });
      return STObj;
    }

    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        cardsAnimationMinWidthTablet();
      },
    });
  }, [q]);

  return (
    <React.Fragment>
      <StyledDiv id={id} ref={ref} className="bg-secondary py-24 lg:pt-24 pb-44 lg:pb-52">
        <div className="container">
          <div direction="top">
            <span className=" text-dark-head h2 text-xl sm:text-xl md:text-4xl text-left leading-none md:whitespace-pre-line mb-6 xl:w-[75%]">
              <h3 className="reveal-animation-title tracking-[-0.01em] md:tracking-[0] md:tracking-normal max-w-[100%] md:w-[13em] h2 text-xl sm:text-xl md:text-4xl text-left md:whitespace-pre-line">{t("why us.title")}</h3>
            </span>

            <p data-aos="fade-up" data-aos-offset="120" className="reveal-animation-subtitle text-md text-left subtitle-text md:text-lg text-dark-head font-light">
              {t("why us.subtitle")}
            </p>
          </div>

          <div className="hidden md:flex why-us-cards-container flex-row md:flex-wrap lg:flex-nowrap md:justify-center justify-start items-center w-full my-20">
            {cards.map((item, index) => (
              <div key={item.title} className="why-us-card lg:min-w-0">
                <div className="relative why-us-card-inner-wrapper">
                  <div className="image-wrapper relative">
                    <img src={cardFrame} width={500} height={500} alt="card frame" />
                    <div className="hover-box" />
                    <img src={item.hoverImage} width={500} height={500} alt={t("why us.item-" + index + "." + item.imgAlt)} className="absolute hover-image" />
                    <div className="card-content absolute absolute-center text-left lg:max-w-8/12 ">
                      <p className="text-white pill-title text-lg font-semibold md:text-lg leading-none whitespace-nowrap ">{t("why us.item-" + index + "." + item.title)}</p>
                      <p className="text-dark-medium font-light leading-tight text-base 2xl:text-md min-h-em-10 mt-4">{t("why us.item-" + index + "." + item.subtitle)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <FadeIn direction="left" id="why-us-card-swipper" className="md:hidden w-4/5 mx-auto overflow-x-visible my-20">
            {/* <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} loop={true} spaceBetween={0} slidesPerView={1} onSwiper={(swiper) => console.log(swiper)} onSlideChange={() => console.log("slide change")}> */}
            <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} loop={true} spaceBetween={0} slidesPerView={1}>
              {cards.map((item, index) => (
                <SwiperSlide key={item.title} className="why-us-card lg:min-w-0">
                  <div className="relative why-us-card-inner-wrapper">
                    <div className="image-wrapper relative">
                      <img src={cardFrame} width={500} height={500} alt="border-card" />
                      <div className="hover-box" />
                      <img src={item.hoverImage} width={500} height={500} alt={t("why us.item-" + index + "." + item.imgAlt)} className="absolute hover-image" />
                      <div className="card-content absolute absolute-center text-left lg:max-w-8/12 ">
                        <p className="text-white font-light text-xl leading-none whitespace-nowrap ">{t("why us.item-" + index + "." + item.title)}</p>
                        <p className="text-dark-medium font-light leading-tight text-md mt-2 whitespace-pre-line">{t("why us.item-" + index + "." + item.subtitle)}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </FadeIn>
          <div className="flex flex-row md:flex-col lg:flex-row items-center md:items-stretch lg:items-center justify-center md:justify-between w-full mt-12 ">
            <RevealBox wrapperClassName="flex flex-row justify-start md:mb-6 lg:mb-0 " className="reveal-from-bottom">
              <img className="hidden lg:block md:block" src={LogoLight} width="219" height="49" alt="logo" />
            </RevealBox>
            <RevealBox className="reveal-from-bottom left-line-grey text-dark-medium text-center md:text-left md:text-lg tracking-custom whitespace-nowrap font-light lg:text-lg letter-3 leading-none relative tracking-wider uppercase">{t("why us.the human side of data")}</RevealBox>
          </div>
        </div>
      </StyledDiv>

      {/*       <StyledAfterSection className="relative hidden md:block -mt-4 h-0 w-screen">
        <div className="background-after-section absolute bg-secondary pointer-events-none" />
      </StyledAfterSection> */}
    </React.Fragment>
  );
}

//WhyUs.whyDidYouRender = true;

/* const StyledAfterSection = styled.div`
  .background-after-section {
    height: 3500px;
    left: 0;
    right: 0;
    z-index: 0;
  }
`; */

const StyledDiv = styled.div`
  .custom-title {
    font-size: 1.9rem;
  }
  .pill-title {
    font-size: 1.7rem;
  }
  .background-after-section {
    height: 50vh;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .background-after-section {
    top: 100%;
  }
  .swiper-container {
    overflow: visible;
  }
  .ml-22 {
    margin-left: 85px;
  }
  .max-w-190 {
    max-width: 190px;
  }
  #why-us-card-swipper * {
    overflow: visible;
  }

  .left-line-grey {
    line-height: 0.9 !important;
  }
  .left-line-grey:after {
    @media screen and (max-width: 765px) {
      content: unset;
      display: none;
    }
  }
  .left-line-grey:after {
    position: absolute;
    display: block;
    width: 6rem;
    height: 1px;
    right: 0;
    bottom: 0;
    background: currentColor;
    @media only screen and (min-width: 1024px) {
      content: "";
      left: unset;
      right: calc(100% + 0.25rem);
    }
  }
  .letter-3 {
    @media only screen and (max-width: 1200px) {
      letter-spacing: 3px;
    }
  }
  .why-us-cards-container {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin: 20% 0 15%;
      width: 100%;
    }
    @media screen and (min-width: 1024px) {
      width: calc(100% / 24 * 22);
    }
    @media screen and (min-width: 2000px) {
      margin-left: 16.67%;
      width: calc(100% / 12 * 7.5);
    }
  }
  @media screen and (max-width: 768px) {
    .subtitle-text {
      width: 260px;
    }
    .why-us-card .card-content {
      width: 60% !important;
    }
  }
  .why-us-card {
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 45%;
    }

    & .why-us-card-inner-wrapper {
      margin: -15% -15%;
      @media screen and (min-width: 1024px) {
        margin-left: -25%;
        position: relative;
        left: 25%;
      }
    }
    & .hover-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 68%;
      height: 104%;
      border-radius: 100rem;
      z-index: 1;
    }
    & .card-content {
      transition: 0.3s opacity ease-out;
      width: 55%;
      margin-left: 4%;
    }
    & .hover-box:hover ~ .hover-image {
      @media screen and (min-width: 768px) {
        opacity: 1;
      }
    }
    & .hover-box:hover ~ .card-content {
      @media screen and (min-width: 768px) {
        opacity: 0;
      }
    }
  }
  .hover-image {
    @media screen and (max-width: 767px) {
      display: none;
    }
    @media screen and (min-width: 768px) {
      opacity: 0;
      top: 50.4%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 121.1%;
      max-width: none;
      transition: 0.3s opacity ease-out;
      pointer-event: none;
    }
    @media screen and (min-width: 1920px) {
      left: 45.6%;
      width: 112.4%;
    }
    @media screen and (min-width: 2560px) {
      left: 50%;
      width: 121.4%;
    }
  }
  @media only screen and (min-width: 1024px) {
    .max-w-190 {
      max-width: calc(100% / 12 * 8);
    }
  }
`;
