import PanelBackground from "../PanelBackground";
import styled from "styled-components";
import React, { forwardRef } from "react";
import panel6Background from "assets/seo web/background.webp";
import panel6BackgroundResponsive from "assets/seo web/digital marketing background responsive.webp";
const Panel6Background = forwardRef(({ children, className = null, id = null }, ref) => {
  return (
    <StyledPanelBackground id={id}>
      <div className="hidden md:block h-full">
        <img src={panel6Background} ref={ref} alt="decoration" className="object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
      <div className="md:hidden md:block h-full">
        <img src={panel6BackgroundResponsive} alt="decoration" className="lg:hidden object-cover object-left transform origin-top-left h-full md:max-w-none" />
      </div>
    </StyledPanelBackground>
  );
});

const StyledPanelBackground = styled(PanelBackground)``;

export default Panel6Background;
