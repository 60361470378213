import styled from "styled-components";
import { ReactComponent as Arrow } from "assets/flecha larga.svg";

export function SubmitButton(props) {
  return (
    <StyledButton {...props} className={`${props.className} submit-button`}>
      <div className="background-on-hover" />
      <div className="submit-button-label">{props.children}</div>
      <div className="arrow-container">
        <Arrow className="arrow h-1em w-auto ml-2" alt="decoracion" />
      </div>
    </StyledButton>
  );
}

const StyledButton = styled.button`
  & {
    color: #171717;
    overflow: hidden;
    transition: background 0.3s ease color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    @media screen and (max-width: 767px) {
      width: 60%;
      max-width: 500px;
    }
    @media screen and (max-width: 1023px) {
      width: 60%;
      max-width: 500px;
    }
    @media screen and (min-width: 1024px) {
      width: 60%;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .arrow-container {
      width: 0;
      transition: width 0.3s ease;
      overflow: hidden;
    }
    .background-on-hover {
      transition: all 0.4s;
      position: absolute;
      inset: 0;
      opacity: 0;
    }
    .submit-button-label {
      transition: color 0.4s;
      color: inherit;
      position: relative;
    }
  }
  &&&:hover {
    background: #cdcdcd;
    .arrow-container {
      width: 2.25rem;
      transition: width 0.3s ease;
      overflow: hidden;
    }
  }
`;
