import PanelContentBodyWrapper from "../../components/ResponsiveContentBodyWrapper";
import PanelContentFooterWrapper from "../../components/ResponsiveContentFooterWrapper";
import PanelContentSubtitleWrapper from "../../components/ResponsiveContentSubTitleWrapper";
import PanelContentTitleWrapper from "../../components/ResponsiveContentTitleWrapper";
import PanelContentWrapper from "../../components/ResponsiveContentModal";
import { useTranslation, Trans } from "react-i18next";
import panel3GoogleLogo from "assets/digital-marketing/google-logo@1x.svg";
import panel3InstagramLogo from "assets/digital-marketing/instagram-logo@1x.svg";
import panel3LinkedInLogo from "assets/digital-marketing/linkedin-logo@1x.svg";
import panel3AmazonAdsLogo from "assets/digital-marketing/amazon-ads-logo@1x.svg";
import panel3DV360Logo from "assets/digital-marketing/dv360-logo@1x.svg";

const DigitalMarketingContent = () => {
  const { t } = useTranslation(["common"]);
  return (
    <PanelContentWrapper>
      <PanelContentTitleWrapper number={3}>
        {" "}
        <Trans i18nKey="common:services.digital marketing campaign.title">
          <span className="block">
            <span className="block my-6 font-lg">digital marketing</span>
            <span className="block whitespace-nowrap">campaign</span>
          </span>
        </Trans>
      </PanelContentTitleWrapper>
      <PanelContentSubtitleWrapper>{t("services.digital marketing campaign.subtitle responsive")}</PanelContentSubtitleWrapper>
      <PanelContentBodyWrapper>
        <Trans i18nKey="common:services.digital marketing campaign.content">
          <span className="block my-6">Los servicios de optimización de la tasa de conversión</span>
          <span className="block text-lg mb-6 font-semibold md:font-normal"> examinan cada paso de sus esfuerzos de ventas y conocen las debilidades de su embudo de ventas. Una vez que convierta esas debilidades en fortalezas, encontrará más clientes realizando compras que nunca.</span>
        </Trans>
      </PanelContentBodyWrapper>
      <div className="uppercase whitespace-nowrap text-sm pt-12 text-white underline-decoration">{t("services.digital marketing campaign.our-partners")}</div>
      <PanelContentFooterWrapper>
        <div>
          <img src={panel3GoogleLogo} alt="Google logo" className="w-auto h-9 object-contain brightness-150" />
        </div>
        <div>
          <img src={panel3InstagramLogo} alt="Instagram logo" className="w-auto h-9 object-contain brightness-150" />
        </div>
        <div>
          <img src={panel3LinkedInLogo} alt="LinkedIn logo" className="w-auto h-9 object-contain brightness-150" />
        </div>
        <div>
          <img src={panel3AmazonAdsLogo} alt="Amazon Ads logo" className="w-auto h-12 object-contain brightness-150" />
        </div>
        <div>
          <img src={panel3DV360Logo} alt="DV360 logo" className="w-auto h-9 object-contain brightness-150" />
        </div>
      </PanelContentFooterWrapper>
    </PanelContentWrapper>
  );
};

export default DigitalMarketingContent;
